import Component from '@glimmer/component';
import config from 'ember-get-config';
import type Owner from '@ember/owner';

interface IArgs {
  enabled: boolean;
}

export interface WidgetsChangelogSignature {
  Element: null;

  Args: IArgs;
}

export default class ChangelogWidgetComponent extends Component<WidgetsChangelogSignature> {
  constructor(owner: Owner, args: IArgs) {
    super(owner, args);

    if (config.changelog.enabled && window.Headway && this.args.enabled) {
      window.Headway.init({
        selector: 'a.updates-badge',
        account: config.changelog.account,
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Widgets::Changelog': typeof ChangelogWidgetComponent;
  }
}
