import type Transition from '@ember/routing/transition';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import ActionChangeoversHostController from 'uplisting-frontend/pods/action/changeovers/host/controller';

export default abstract class SidebarChangeoversHostRoute extends PermittedRoute {
  permission = 'changeovers.viewHostInfo';

  abstract parentRoute: string;

  model() {
    return this.modelFor(this.parentRoute);
  }

  setupController(
    controller: ActionChangeoversHostController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { changeover: model });
  }

  resetController(controller: ActionChangeoversHostController): void {
    controller.changeover.rollbackAttributes();
  }
}
