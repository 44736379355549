import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<tr class=\"action-table-row day-item\">\n  <td colspan=\"8\">{{date-format this.date \"EEEE - do MMMM yyyy\"}}</td>\n</tr>\n\n", {"contents":"<tr class=\"action-table-row day-item\">\n  <td colspan=\"8\">{{date-format this.date \"EEEE - do MMMM yyyy\"}}</td>\n</tr>\n\n","moduleName":"uplisting-frontend/components/ui/action/table/heading/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/action/table/heading/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

interface IArgs {
  date: string;
}

interface ActionTableHeadingSignature {
  Element: HTMLTableRowElement;

  Args: IArgs;
}

export default class UiActionTableHeadingComponent extends Component<ActionTableHeadingSignature> {
  @cached
  get date(): string {
    return this.args.date;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Action::Table::Heading': typeof UiActionTableHeadingComponent;
  }
}
