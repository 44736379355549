import type Transition from '@ember/routing/transition';
import BookingModel from 'uplisting-frontend/models/booking';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import ActionBookingsPriceEditController from 'uplisting-frontend/pods/action/bookings/price/edit/controller';

export default abstract class SidebarBookingsPriceEditRoute extends PermittedRoute {
  permission = 'calendar.bookings.price.edit';

  abstract parentRoute: string;

  model() {
    return this.modelFor(this.parentRoute) as BookingModel;
  }

  setupController(
    controller: ActionBookingsPriceEditController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { booking: model });
  }
}
