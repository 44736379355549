import Model, { attr, hasMany } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import type BookingModel from 'uplisting-frontend/models/booking';
import type AirbnbOfficialConnectionModel from 'uplisting-frontend/models/airbnb-official-connection';
import {
  AlertStatus,
  AlertType,
  type IAlertSchema,
} from 'uplisting-frontend/models/schemas';

export default class AlertModel extends Model implements IAlertSchema {
  @attr('string') title!: string;
  @attr('string') description!: string;
  @attr('string') status!: AlertStatus;
  @attr('string') type!: AlertType;
  @attr('date') createdAt!: Date;

  @hasMany('airbnb-official-connection', { async: false, inverse: null })
  airbnbOfficialConnections?: AirbnbOfficialConnectionModel[];

  @hasMany('booking', { async: false, inverse: null })
  bookings?: BookingModel[];

  @cached
  get isResolved(): boolean {
    return this.status === AlertStatus.resolved;
  }

  @cached
  get isSubmitting(): boolean {
    return !!this.isSaving;
  }

  @cached
  get isAirbnbUserSuspension(): boolean {
    return this.type === AlertType.airbnbUserSuspension;
  }

  @cached
  get isAirbnbAlterationAlert(): boolean {
    return [
      AlertType.airbnbOfficialAlterationRequestCreated,
      AlertType.airbnbOfficialAlterationRequestDeclined,
      AlertType.airbnbOfficialAlterationRequestAccepted,
    ].includes(this.type);
  }

  @cached
  get isActionable(): boolean {
    if (this.isAirbnbUserSuspension) {
      return true;
    } else if (this.isAirbnbAlterationAlert) {
      return !!this.bookings?.[0];
    }

    return false;
  }

  @cached
  get bookingForType(): BookingModel | undefined {
    if (this.isAirbnbAlterationAlert) {
      return this.bookings?.[0];
    }
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    alert: AlertModel;
  }
}
