import { type Registry as Services, service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import BaseController from 'uplisting-frontend/pods/base/controller';
import DirectBookingConfigurationModel from 'uplisting-frontend/models/direct-booking-configuration';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';

export default class DirectBaseController extends BaseController {
  @service('repositories/direct-booking-configuration')
  directBookingConfigurationRepository!: Services['repositories/direct-booking-configuration'];

  @cached @tracked configuration!: DirectBookingConfigurationModel;

  @cached
  get changeset(): GenericChangeset<DirectBookingConfigurationModel> {
    return this.directBookingConfigurationRepository.currentChangeset;
  }
}
