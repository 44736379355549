import Model, { attr, belongsTo } from '@ember-data/model';
import { type IBookingRefundActionSchema } from 'uplisting-frontend/models/schemas';
import PaymentModel from 'uplisting-frontend/models/payment';

export default class BookingRefundActionModel
  extends Model
  implements IBookingRefundActionSchema
{
  @attr('number') amount!: number;

  @belongsTo('payment', { inverse: null, async: false })
  payment!: PaymentModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'booking-refund-action': BookingRefundActionModel;
  }
}
