import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"card-section\" data-test-card-section>\n  <div local-class=\"card-section-title\" data-test-card-section-title>\n    {{@title}}\n  </div>\n\n  <div local-class=\"card-section-description\" data-test-card-section-description>\n    {{@description}}\n  </div>\n</div>\n", {"contents":"<div local-class=\"card-section\" data-test-card-section>\n  <div local-class=\"card-section-title\" data-test-card-section-title>\n    {{@title}}\n  </div>\n\n  <div local-class=\"card-section-description\" data-test-card-section-description>\n    {{@description}}\n  </div>\n</div>\n","moduleName":"uplisting-frontend/components/ui/card-section/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/card-section/index.hbs"}});
import templateOnly from '@ember/component/template-only';

interface IArgs {
  title: string;
  description: string | number;
}

interface CardSectionSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

const UiCardSectionComponent = templateOnly<CardSectionSignature>();

export default UiCardSectionComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::CardSection': typeof UiCardSectionComponent;
  }
}
