import type Transition from '@ember/routing/transition';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';
import OutboundMessagesIndexController from 'uplisting-frontend/pods/outbound/messages/index/controller';

export default class OutboundMessagesRoute extends ProtectedRoute {
  model() {
    return this.modelFor('outbound.messages');
  }

  setupController(
    controller: OutboundMessagesIndexController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, model);
  }

  resetController(controller: OutboundMessagesIndexController) {
    controller.propertyIds = [];
  }
}
