import Service, { type Registry as Services, service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import config from 'ember-get-config';
import uniq from 'lodash-es/uniq';
import { action } from '@ember/object';
import UserModel from 'uplisting-frontend/models/user';
import CustomerModel from 'uplisting-frontend/models/customer';
import PropertyModel from 'uplisting-frontend/models/property';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import {
  BillingQuoteCurrency,
  SecurityDepositPer,
} from 'uplisting-frontend/models/schemas';

export default class PricingService extends Service {
  @service intl!: Services['intl'];
  @service session!: Services['session'];
  @service features!: Services['features'];
  @service analytics!: Services['analytics'];
  @service notifications!: Services['notifications'];

  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/account-configuration')
  accountConfigurationRepository!: Services['repositories/account-configuration'];

  @service('repositories/guest-identity-verification-configuration')
  guestIdentityVerificationConfigurationRepository!: Services['repositories/guest-identity-verification-configuration'];

  @cached @tracked isUpgradingPlan = false;

  private ANNUAL_DISCOUNT = config.annualDiscount / 100;
  private NUMBER_OF_PROPERTIES_FOR_DISCOUNTED_PRICE = 10;

  private clientManagementPrices = {
    GBP: 4,
    EUR: 5,
    USD: 6,
    AUD: 8,
    CAD: 7.5,
    NZD: 8.5,
  };

  private clientStatementPrices = {
    GBP: 4,
    EUR: 5,
    USD: 6,
    AUD: 8,
    CAD: 7.5,
    NZD: 8.5,
  };

  private seamPrices = {
    GBP: 4,
    EUR: 5,
    USD: 6,
    AUD: 8,
    CAD: 7.5,
    NZD: 8.5,
  };

  private securityDepositPrices = {
    booking: {
      GBP: 1.5,
      EUR: 1.75,
      USD: 2,
      AUD: 3,
      CAD: 2.5,
      NZD: 3,
    },

    property: {
      GBP: 4,
      EUR: 5,
      USD: 6,
      AUD: 8,
      CAD: 7.5,
      NZD: 8.5,
    },
  };

  private guestIdentityPrices = {
    full: {
      GBP: 1.25,
      EUR: 1.5,
      USD: 1.65,
      AUD: 2.2,
      CAD: 2.1,
      NZD: 2.4,
    },

    discounted: {
      GBP: 1,
      EUR: 1.2,
      USD: 1.3,
      AUD: 1.75,
      CAD: 1.7,
      NZD: 1.9,
    },
  };

  @cached
  get supportedCurrencies(): string[] {
    return Object.values(BillingQuoteCurrency);
  }

  @cached
  get customer(): CustomerModel | undefined {
    return (this.session.currentUser as UserModel).customer;
  }

  @cached
  get currency(): string {
    if (this.customer?.currency) {
      return this.customer.currency.toUpperCase();
    }

    const { properties, supportedCurrencies } = this;

    const currencies = uniq(properties.map((property) => property.currency));

    const relevant = currencies.find((currency) =>
      supportedCurrencies.includes(currency),
    );

    if (relevant) {
      return relevant.toUpperCase();
    }

    return 'USD';
  }

  @cached
  get planVersion(): number | undefined {
    return this.customer?.planVersion;
  }

  @cached
  get properties(): PropertyModel[] {
    return this.propertyRepository.peekAll();
  }

  @cached
  get planDuration(): string {
    return this.intl.t(`duration.${this.customer?.annual ? 'year' : 'month'}`);
  }

  @cached
  get clientManagementPrice(): number {
    return this.clientManagementPrices[this.currency] as number;
  }

  @cached
  get clientStatementsPrice(): number {
    const price = this.clientStatementPrices[this.currency] as number;

    if (this.features.isClientManagementEnabledOnAccount) {
      return price;
    }

    return this.clientManagementPrice + price;
  }

  @cached
  get seamPrice(): number {
    return this.seamPrices[this.currency] as number;
  }

  @cached
  get guestIdentityPrice(): number {
    const { currentConfiguration } =
      this.guestIdentityVerificationConfigurationRepository;

    const key = currentConfiguration.discounted ? 'discounted' : 'full';

    return this.guestIdentityPrices[key][this.currency];
  }

  @cached
  get securityDepositPer(): SecurityDepositPer {
    return this.accountConfigurationRepository.currentAccountConfiguration
      .securityDepositPer;
  }

  @cached
  get securityDepositPerTranslationKey(): string {
    return `security_deposit_per.${this.securityDepositPer}`;
  }

  @cached
  get securityDepositPrice(): number {
    return this.securityDepositPrices[this.securityDepositPer][this.currency];
  }

  @action
  async handleUpgradePlan(): Promise<void> {
    const { customer } = this;

    if (!customer) {
      return;
    }

    const prevPlanVersion = customer.planVersion;

    try {
      this.isUpgradingPlan = true;
      customer.planVersion = 0;

      await customer.save();

      this.analytics.trackEvent(AnalyticsEvents.legacyPlanUpgrade);

      this.notifications.info('notifications.plan_upgraded');
    } catch {
      customer.planVersion = prevPlanVersion;
      this.notifications.error();
    } finally {
      this.isUpgradingPlan = false;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    pricing: PricingService;
  }
}
