import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import sum from 'lodash-es/sum';
import {
  type IGuestInvoiceSchema,
  GuestInvoiceStatus,
} from 'uplisting-frontend/models/schemas';
import PaymentModel from 'uplisting-frontend/models/payment';
import BookingModel from 'uplisting-frontend/models/booking';
import AdditionalBookingChargeModel from 'uplisting-frontend/models/additional-booking-charge';

const PAID_STATUSES = [
  GuestInvoiceStatus.paid,
  GuestInvoiceStatus.markedAsPaid,
  GuestInvoiceStatus.void,
];

export default class GuestInvoiceModel
  extends Model
  implements IGuestInvoiceSchema
{
  @attr('string', { defaultValue: null }) status!: GuestInvoiceStatus | null;
  @attr('string') externalId!: string;

  @belongsTo('payment', { async: false, inverse: 'guestInvoice' })
  payment!: PaymentModel;

  @belongsTo('booking', { async: false, inverse: null })
  booking!: BookingModel;

  @hasMany('additional-booking-charge', { async: false, inverse: null })
  additionalBookingCharges!: AdditionalBookingChargeModel[];

  @cached
  get displayStatusKey(): string {
    switch (this.status) {
      case GuestInvoiceStatus.open:
      case GuestInvoiceStatus.paymentError:
      case GuestInvoiceStatus.paymentMethodMissing:
        return 'action_bookings_price.guest_invoice_status.open';
      case GuestInvoiceStatus.paid:
      case GuestInvoiceStatus.markedAsPaid:
        return 'action_bookings_price.guest_invoice_status.paid';
      default:
        return 'action_bookings_price.guest_invoice_status.void';
    }
  }

  @cached
  get isPaid(): boolean {
    return this.status === GuestInvoiceStatus.paid;
  }

  @cached
  get isMarkedAsPaid(): boolean {
    return this.status === GuestInvoiceStatus.markedAsPaid;
  }

  @cached
  get isVoid(): boolean {
    return this.status === GuestInvoiceStatus.void;
  }

  @cached
  get isUnpaid(): boolean {
    return !PAID_STATUSES.includes(this.status as GuestInvoiceStatus);
  }

  @cached
  get totalAmountWithTax(): number {
    return sum(this.additionalBookingCharges.map((item) => item.amountWithTax));
  }

  @cached
  get totalAmountWithoutTax(): number {
    return sum(
      this.additionalBookingCharges.map((item) => item.amountWithoutTax),
    );
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'guest-invoice': GuestInvoiceModel;
  }
}
