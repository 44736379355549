import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isPermitted}}\n  {{#if @external}}\n    <a href={{compute-url @route}} local-class=\"connect-home-card\" target=\"_self\">\n      <div class=\"logo-brand {{this.logoClass}}\" local-class=\"card-logo\"></div>\n\n      {{#if @title}}\n        <h4 local-class=\"card-title\">{{@title}}</h4>\n      {{/if}}\n\n      {{#if @description}}\n        <div local-class=\"card-description\">{{@description}}</div>\n      {{/if}}\n    </a>\n  {{else}}\n    <LinkTo @route={{@route}} local-class=\"connect-home-card\" target=\"_self\">\n      <div class=\"logo-brand {{this.logoClass}}\" local-class=\"card-logo\"></div>\n\n      {{#if @title}}\n        <h4 local-class=\"card-title\">{{@title}}</h4>\n      {{/if}}\n\n      {{#if @description}}\n        <div local-class=\"card-description\">{{@description}}</div>\n      {{/if}}\n    </LinkTo>\n  {{/if}}\n{{/if}}\n", {"contents":"{{#if this.isPermitted}}\n  {{#if @external}}\n    <a href={{compute-url @route}} local-class=\"connect-home-card\" target=\"_self\">\n      <div class=\"logo-brand {{this.logoClass}}\" local-class=\"card-logo\"></div>\n\n      {{#if @title}}\n        <h4 local-class=\"card-title\">{{@title}}</h4>\n      {{/if}}\n\n      {{#if @description}}\n        <div local-class=\"card-description\">{{@description}}</div>\n      {{/if}}\n    </a>\n  {{else}}\n    <LinkTo @route={{@route}} local-class=\"connect-home-card\" target=\"_self\">\n      <div class=\"logo-brand {{this.logoClass}}\" local-class=\"card-logo\"></div>\n\n      {{#if @title}}\n        <h4 local-class=\"card-title\">{{@title}}</h4>\n      {{/if}}\n\n      {{#if @description}}\n        <div local-class=\"card-description\">{{@description}}</div>\n      {{/if}}\n    </LinkTo>\n  {{/if}}\n{{/if}}\n","moduleName":"uplisting-frontend/components/ui/connect/home-card/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/connect/home-card/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, service } from '@ember/service';
import UserModel from 'uplisting-frontend/models/user';

interface IArgs {
  logoClass: string;
  permission?: string;
  title?: string;
  description?: string;
  brandType?: 'icon';
  route: string;
  external?: boolean;
}

interface ConnectHomeCardSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiConnectHomeCardComponent extends Component<ConnectHomeCardSignature> {
  @service session!: Services['session'];

  @cached
  get brandType(): 'channel-brand' | 'icon-brand' {
    if (this.args.brandType === 'icon') {
      return 'icon-brand';
    }

    return 'channel-brand';
  }

  @cached
  get logoClass(): string {
    return `${this.brandType} ${this.args.logoClass}`;
  }

  @cached
  get isPermitted(): boolean {
    const { permission } = this.args;

    if (!permission) {
      return true;
    }

    const user = this.session.currentUser as UserModel;

    return user.hasAccessTo(permission);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Connect::HomeCard': typeof UiConnectHomeCardComponent;
  }
}
