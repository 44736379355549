import type Transition from '@ember/routing/transition';
import BaseRoute from 'uplisting-frontend/pods/base/route';
import ActionBookingsRepliesNewController from 'uplisting-frontend/pods/action/bookings/replies/new/controller';

export default abstract class SidebarBookingsRepliesNewRoute extends BaseRoute {
  abstract parentRoute: string;

  model() {
    return this.modelFor(this.parentRoute);
  }

  setupController(
    controller: ActionBookingsRepliesNewController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { booking: model });
  }
}
