import Model, { attr, belongsTo } from '@ember-data/model';
import { type Registry as Services, service } from '@ember/service';
import { cached } from '@glimmer/tracking';
import {
  type ITaxSchema,
  TaxRateType,
} from 'uplisting-frontend/models/schemas';
import ClientModel from 'uplisting-frontend/models/client';

export default class TaxModel extends Model implements ITaxSchema {
  @service intl!: Services['intl'];

  @attr('string') type!: string;
  @attr('number') rate!: number;
  @attr('string') rateType!: TaxRateType;
  @attr('string') description!: string;

  @belongsTo('client', { inverse: 'tax', async: false })
  client!: ClientModel;

  @cached
  get isInclusive(): boolean {
    return this.rateType === TaxRateType.inclusive;
  }

  @cached
  get rateReadable(): string {
    const base = `${this.rate}%`;

    if (this.isInclusive) {
      return `${base} ${this.intl.t('tax.inclusive')}`;
    }

    return base;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    tax: TaxModel;
  }
}
