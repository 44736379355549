import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"info-card {{if @small \"card-sm\"}}\" ...attributes>\n  <div local-class=\"main-info\">\n    <p local-class=\"card-title\">{{@title}}</p>\n\n    {{#if (or @description (has-block \"description\"))}}\n      <p local-class=\"card-description\">\n        {{#if (has-block \"description\")}}\n          {{yield to=\"description\"}}\n        {{else}}\n          {{html-safe @description}}\n        {{/if}}\n      </p>\n    {{/if}}\n  </div>\n\n  <div local-class=\"side-info\">\n    {{#if (has-block \"enabledBlock\")}}\n      {{yield to=\"enabledBlock\"}}\n    {{else}}\n      {{@enabledText}}\n\n      &nbsp;\n\n      <FaIcon @icon=\"circle\" class=\"primary-green\" />\n    {{/if}}\n  </div>\n</div>\n\n{{#if @explanation}}\n  <div class=\"alert alert-info\" local-class=\"connect-info\">\n    <FaIcon @icon=\"random\" />\n\n    &nbsp;\n\n    {{@explanation}}\n  </div>\n{{/if}}\n", {"contents":"<div local-class=\"info-card {{if @small \"card-sm\"}}\" ...attributes>\n  <div local-class=\"main-info\">\n    <p local-class=\"card-title\">{{@title}}</p>\n\n    {{#if (or @description (has-block \"description\"))}}\n      <p local-class=\"card-description\">\n        {{#if (has-block \"description\")}}\n          {{yield to=\"description\"}}\n        {{else}}\n          {{html-safe @description}}\n        {{/if}}\n      </p>\n    {{/if}}\n  </div>\n\n  <div local-class=\"side-info\">\n    {{#if (has-block \"enabledBlock\")}}\n      {{yield to=\"enabledBlock\"}}\n    {{else}}\n      {{@enabledText}}\n\n      &nbsp;\n\n      <FaIcon @icon=\"circle\" class=\"primary-green\" />\n    {{/if}}\n  </div>\n</div>\n\n{{#if @explanation}}\n  <div class=\"alert alert-info\" local-class=\"connect-info\">\n    <FaIcon @icon=\"random\" />\n\n    &nbsp;\n\n    {{@explanation}}\n  </div>\n{{/if}}\n","moduleName":"uplisting-frontend/components/ui/info-card/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/info-card/index.hbs"}});
import templateOnly from '@ember/component/template-only';

interface IArgs {
  title: string;
  description?: string;
  enabledText?: string;
  explanation?: string;
  small?: boolean;
}

interface InfoCardSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    enabledBlock?: [];
    description?: [];
  };
}

const UiInfoCardComponent = templateOnly<InfoCardSignature>();

export default UiInfoCardComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::InfoCard': typeof UiInfoCardComponent;
  }
}
