import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{!-- template-lint-disable table-groups --}}\n<table local-class=\"action-table\" data-test-table>\n  {{#each-in this.data as |date items|}}\n    <tbody data-test-table-body={{date-format date \"yyyy-MM-dd\"}}>\n      <Ui::Action::Table::Heading @date={{date}} />\n\n      {{#each items as |item|}}\n        <Ui::Action::Table::Row @item={{item}} @date={{date}} />\n      {{else}}\n        <Ui::Action::Table::Row::Empty />\n      {{/each}}\n    </tbody>\n  {{else}}\n    <div local-class=\"no-data\">\n      {{t \"action.no_data\"}}\n    </div>\n  {{/each-in}}\n</table>\n", {"contents":"{{!-- template-lint-disable table-groups --}}\n<table local-class=\"action-table\" data-test-table>\n  {{#each-in this.data as |date items|}}\n    <tbody data-test-table-body={{date-format date \"yyyy-MM-dd\"}}>\n      <Ui::Action::Table::Heading @date={{date}} />\n\n      {{#each items as |item|}}\n        <Ui::Action::Table::Row @item={{item}} @date={{date}} />\n      {{else}}\n        <Ui::Action::Table::Row::Empty />\n      {{/each}}\n    </tbody>\n  {{else}}\n    <div local-class=\"no-data\">\n      {{t \"action.no_data\"}}\n    </div>\n  {{/each-in}}\n</table>\n","moduleName":"uplisting-frontend/components/ui/action/table/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/action/table/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { type ActionDataFormat } from 'uplisting-frontend/pods/action/controller';

interface IArgs {
  data: ActionDataFormat;
}

interface ActionTableSignature {
  Element: HTMLTableElement;

  Args: IArgs;
}

export default class UiActionTableComponent extends Component<ActionTableSignature> {
  @cached
  get data(): ActionDataFormat {
    return this.args.data;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Action::Table': typeof UiActionTableComponent;
  }
}
