import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.price.hasGuestPrice}}\n  <table class=\"table-small table-striped no-margin\">\n    <tbody>\n      <tr><th colspan=\"10\">{{t \"action_bookings_price.price_summary.breakdown\"}}</th></tr>\n\n      <tr>\n        <td colspan=\"8\">{{t \"action_bookings_price.price_summary.guest_price\"}}</td>\n        <td colspan=\"2\">{{booking-price-format this.price.guestPrice this.currency}}</td>\n      </tr>\n    </tbody>\n  </table>\n{{/if}}\n", {"contents":"{{#if this.price.hasGuestPrice}}\n  <table class=\"table-small table-striped no-margin\">\n    <tbody>\n      <tr><th colspan=\"10\">{{t \"action_bookings_price.price_summary.breakdown\"}}</th></tr>\n\n      <tr>\n        <td colspan=\"8\">{{t \"action_bookings_price.price_summary.guest_price\"}}</td>\n        <td colspan=\"2\">{{booking-price-format this.price.guestPrice this.currency}}</td>\n      </tr>\n    </tbody>\n  </table>\n{{/if}}\n","moduleName":"uplisting-frontend/components/ui/booking/price/tables/guest-price/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/booking/price/tables/guest-price/index.hbs"}});
import UiBookingPriceTablesBaseComponent from 'uplisting-frontend/components/ui/booking/price/tables/base';

export default class extends UiBookingPriceTablesBaseComponent {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Price::Tables::GuestPrice': typeof UiBookingPriceTablesBaseComponent;
  }
}
