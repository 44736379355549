import Helper from '@ember/component/helper';
import { type Registry as Services, service } from '@ember/service';

export default class TranslationExistsHelper extends Helper {
  @service intl!: Services['intl'];

  compute([key]: [string]): boolean {
    return this.intl.exists(key);
  }
}
