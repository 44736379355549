import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @isReadOnlyAirbnb}}\n  <div class=\"inline-alert inline-alert-danger\">\n    <strong>{{t \"action_bookings_messages.read_only_message_header.title_1\"}}</strong>\n\n    {{t \"action_bookings_messages.read_only_message_header.title_2\"}}\n\n    <LinkTo @route=\"connect.airbnb\">\n      <strong>\n        {{t \"action_bookings_messages.read_only_message_header.title_3\"}}\n      </strong>\n    </LinkTo>\n\n    {{t \"action_bookings_messages.read_only_message_header.title_4\"}}\n  </div>\n{{/if}}\n", {"contents":"{{#if @isReadOnlyAirbnb}}\n  <div class=\"inline-alert inline-alert-danger\">\n    <strong>{{t \"action_bookings_messages.read_only_message_header.title_1\"}}</strong>\n\n    {{t \"action_bookings_messages.read_only_message_header.title_2\"}}\n\n    <LinkTo @route=\"connect.airbnb\">\n      <strong>\n        {{t \"action_bookings_messages.read_only_message_header.title_3\"}}\n      </strong>\n    </LinkTo>\n\n    {{t \"action_bookings_messages.read_only_message_header.title_4\"}}\n  </div>\n{{/if}}\n","moduleName":"uplisting-frontend/components/ui/booking/read-only-message-header/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/booking/read-only-message-header/index.hbs"}});
import templateOnly from '@ember/component/template-only';

interface IArgs {
  isReadOnlyAirbnb: boolean;
}

interface BookingReadOnlyMessageHeaderSignature {
  Element?: HTMLDivElement;

  Args: IArgs;
}

const UiReadOnlyMessageHeaderComponent =
  templateOnly<BookingReadOnlyMessageHeaderSignature>();

export default UiReadOnlyMessageHeaderComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::ReadOnlyMessageHeader': typeof UiReadOnlyMessageHeaderComponent;
  }
}
