import { cached } from '@glimmer/tracking';
import { type Registry as Services, service } from '@ember/service';
import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import TaxModel from 'uplisting-frontend/models/tax';
import { TaxValidation } from 'uplisting-frontend/validations';
import { TaxRateType } from 'uplisting-frontend/models/schemas';

export default class TaxRepositoryService extends BaseRepositoryService<TaxModel> {
  @service intl!: Services['intl'];

  recordName = 'tax';
  implementMethods = ['createRecord', 'findAll', 'peekAll', 'buildChangeset'];

  validation = TaxValidation;

  get defaultParams() {
    return {
      rateType: TaxRateType.exclusive,
    };
  }

  @cached
  get defaultTaxOption(): TaxModel {
    return this.createRecord({
      type: this.intl.t('tax.no_tax'),
    });
  }

  @cached
  get taxOptions(): TaxModel[] {
    return this.peekAll();
  }
}

declare module '@ember/service' {
  interface Registry {
    'repositories/tax': TaxRepositoryService;
  }
}
