import Service, { type Registry as Services, service } from '@ember/service';
import { cached } from '@glimmer/tracking';
import UserModel from 'uplisting-frontend/models/user';

export default class FeaturesService extends Service {
  @service session!: Services['session'];

  @service('repositories/account-configuration')
  accountConfigurationRepository!: Services['repositories/account-configuration'];

  @cached
  get currentUser(): UserModel {
    return this.session.currentUser as UserModel;
  }

  @cached
  get isDashboardEnabled(): boolean {
    return this.currentUser.trialist || this.planVersion >= 3;
  }

  @cached
  get isClientManagementEnabledOnAccount(): boolean {
    return this.accountConfigurationRepository.currentAccountConfiguration
      .clientManagementEnabled;
  }

  @cached
  get isClientStatementsEnabledOnAccount(): boolean {
    return this.accountConfigurationRepository.currentAccountConfiguration
      .clientStatementsEnabled;
  }

  @cached
  get isAdvancedReportingEnabledOnAccount(): boolean {
    return this.currentUser.trialist || this.planVersion >= 3;
  }

  @cached
  get planVersion(): number {
    return this.currentUser.customer?.planVersion ?? 0;
  }
}

declare module '@ember/service' {
  interface Registry {
    features: FeaturesService;
  }
}
