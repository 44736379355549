import Helper from '@ember/component/helper';
import { underscore } from '@ember/string';
import { type Registry as Services, service } from '@ember/service';
import ApplicationInstance from '@ember/application/instance';

export default class I18nHelper extends Helper {
  @service intl!: Services['intl'];

  // eslint-disable-next-line no-use-before-define
  static translator: I18nHelper;

  constructor(appInstance: ApplicationInstance) {
    super(appInstance);

    // Storing reference to the class instance to use class translation functionality in other places
    I18nHelper.translator = this;
  }

  compute([key, value]: [string, string]): string {
    // if key and value passed then we need to cast data into `i18n` formatted key
    // if value is not present - then key is already formatted correctly
    const intlKey = value ? `${underscore(key)}.${value}` : key;

    return this.intl.exists(intlKey) ? this.intl.t(intlKey) : value;
  }
}
