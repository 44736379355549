import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import ClientModel from 'uplisting-frontend/models/client';
import UserModel from 'uplisting-frontend/models/user';
import { type IPermissionSchema } from 'uplisting-frontend/models/schemas';
import TeammateModel from 'uplisting-frontend/models/teammate';

export default class PermissionModel
  extends Model
  implements IPermissionSchema
{
  @attr('string') permission!: string;
  @attr('boolean') permitted!: boolean;
  @attr('string') title!: string;
  @attr('string') category!: string;
  @attr('string-or-empty') subCategory!: string;

  @belongsTo('permission', { inverse: 'children', async: false })
  // eslint-disable-next-line no-use-before-define
  parent?: PermissionModel;

  @hasMany('permission', { inverse: 'parent', async: false })
  // eslint-disable-next-line no-use-before-define
  children?: PermissionModel[];

  @belongsTo('client', { inverse: 'permissions', async: false })
  client!: ClientModel;

  @belongsTo('user', { inverse: 'permissions', async: false })
  user!: UserModel;

  @belongsTo('teammate', { inverse: 'permissions', async: false })
  teammate!: TeammateModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    permission: PermissionModel;
  }
}
