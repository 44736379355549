import Model from '@ember-data/model';
import { EmberChangeset } from 'ember-changeset';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';

export async function isSaveDisabled<T>(
  changeset?: GenericChangeset<T>,
): Promise<boolean> {
  if (!changeset) {
    return true;
  }

  // skip if no changes was made
  if (changeset.changes.length === 0) {
    return true;
  }

  await changeset.validate();

  return changeset.isInvalid;
}

export function unloadRecord<T extends Model>(
  changeset: GenericChangeset<T> | T,
): void {
  const isChangeset = changeset.constructor === EmberChangeset;

  const data = isChangeset
    ? (changeset as GenericChangeset<T>).data
    : changeset;

  if (!changeset?.id) {
    data.unloadRecord();
  }
}
