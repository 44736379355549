import type Transition from '@ember/routing/transition';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';
import InsightsController, {
  TabOption,
} from 'uplisting-frontend/pods/insights/controller';

export default class InsightsRoute extends ProtectedRoute {
  setupController(
    controller: InsightsController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    controller.activeTab = transition?.to?.name.includes('insights.reports')
      ? TabOption.reports
      : TabOption.insights;
  }
}
