import Model, { attr } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import { htmlSafe } from '@ember/template';
import { SafeString } from 'handlebars';
import { type Registry as Services, service } from '@ember/service';
import {
  type IHistoryBookingPriceSchema,
  BalanceAdjustmentType,
} from 'uplisting-frontend/models/schemas';

export default class HistoryBookingPriceModel
  extends Model
  implements IHistoryBookingPriceSchema
{
  @service intl!: Services['intl'];

  @attr('string') status!: string;
  @attr('string') amount!: number;
  @attr('string') userName!: string;
  @attr('string') userEmail!: string;
  @attr('string') createdAt!: Date;

  @cached
  get adjustedAmount(): number {
    return Math.abs(this.amount);
  }

  @cached
  get type(): BalanceAdjustmentType {
    return this.amount > 0
      ? BalanceAdjustmentType.debit
      : BalanceAdjustmentType.credit;
  }

  @cached
  get note(): SafeString {
    const note = this.intl.t(
      'action_bookings_price.history_booking_price.note',
    );

    return htmlSafe(
      `${note} (<a href="mailto:${this.userEmail}">${this.userName}</a>)`,
    );
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'history-booking-price': HistoryBookingPriceModel;
  }
}
