export default {
  "table-title": "_table-title_9nswy3",
  "expenses-table": "_expenses-table_9nswy3",
  "expense-actions": "_expense-actions_9nswy3",
  "no-expenses-present": "_no-expenses-present_9nswy3",
  "add-new-expense": "_add-new-expense_9nswy3",
  "invoice-table": "_invoice-table_9nswy3",
  "sales-table": "_sales-table_9nswy3",
  "sales-total-row": "_sales-total-row_9nswy3",
  "no-income-items-present": "_no-income-items-present_9nswy3",
  "add-expense-form": "_add-expense-form_9nswy3",
  "form-item": "_form-item_9nswy3",
  "cancel-button": "_cancel-button_9nswy3"
};
