import Model, { attr, belongsTo } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import TaxModel from 'uplisting-frontend/models/tax';
import { type IAdditionalBookingChargeSchema } from 'uplisting-frontend/models/schemas';

export default class AdditionalBookingChargeModel
  extends Model
  implements IAdditionalBookingChargeSchema
{
  @attr('string') name!: string;
  @attr('number') amount!: number;

  @belongsTo('tax', {
    async: false,
    inverse: null,
  })
  tax?: TaxModel;

  @cached
  get amountWithTax(): number {
    const { tax, amount } = this;

    if (!tax?.id || tax.isInclusive) {
      return amount;
    }

    return amount + this.taxAmount;
  }

  @cached
  get amountWithoutTax(): number {
    const { tax, amount } = this;

    if (tax?.isInclusive) {
      return this.amount - this.taxAmount;
    }

    return amount;
  }

  @cached
  get taxAmount(): number {
    const { tax } = this;

    if (!tax) {
      return 0;
    }

    const { amount } = this;

    const percentage = tax.rate / 100;

    if (tax.isInclusive) {
      return amount - amount / (1 + percentage);
    }

    return amount * percentage;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'additional-booking-charge': AdditionalBookingChargeModel;
  }
}
