import { modifier } from 'ember-modifier';
import BaseController from 'uplisting-frontend/pods/base/controller';

export default class RegisterMeetingController extends BaseController {
  handleInsert = modifier((element: HTMLDivElement) => {
    const script = document.createElement('script');

    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.type = 'text/javascript';
    script.async = true;

    element?.insertAdjacentElement('afterend', script);
  });
}
