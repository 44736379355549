import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<hr>\n\n<h5 class=\"mb-2\">{{t \"action_bookings_summary.change_source.title\"}}</h5>\n\n<PowerSelect\n  @options={{this.sources}}\n  @selected={{this.booking.source}}\n  @onChange={{this.handleSelect}}\n  data-test-booking-source-selector\nas |source|>\n  {{t (concat \"action_bookings_summary.sources_list.\" source)}}\n</PowerSelect>\n", {"contents":"<hr>\n\n<h5 class=\"mb-2\">{{t \"action_bookings_summary.change_source.title\"}}</h5>\n\n<PowerSelect\n  @options={{this.sources}}\n  @selected={{this.booking.source}}\n  @onChange={{this.handleSelect}}\n  data-test-booking-source-selector\nas |source|>\n  {{t (concat \"action_bookings_summary.sources_list.\" source)}}\n</PowerSelect>\n","moduleName":"uplisting-frontend/components/ui/booking/source-selector/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/booking/source-selector/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, service } from '@ember/service';
import { action } from '@ember/object';
import BookingModel from 'uplisting-frontend/models/booking';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import { BookingSource } from 'uplisting-frontend/models/schemas';

interface IArgs {
  booking: BookingModel;
}

export interface BookingSourceSelectorSignature {
  Element: null;

  Args: IArgs;
}

export default class UiBookingSourceSelectorComponent extends Component<BookingSourceSelectorSignature> {
  @service analytics!: Services['analytics'];
  @service notifications!: Services['notifications'];

  sources = [
    BookingSource.apartmentsOnline,
    BookingSource.bookingSiteConversion,
    BookingSource.email,
    BookingSource.extension,
    BookingSource.livinc,
    BookingSource.openRent,
    BookingSource.phone,
    BookingSource.referral,
    BookingSource.silverDoor,
    BookingSource.situ,
    BookingSource.vrbo,
    BookingSource.website,
  ];

  @cached
  get booking(): BookingModel {
    return this.args.booking;
  }

  @action
  async handleSelect(source: BookingSource): Promise<void> {
    const { booking } = this;

    const previousSource = booking.source;

    booking.source = source;

    try {
      await booking.save();

      this.analytics.trackEvent(AnalyticsEvents.bookingSourceChanged);

      this.notifications.info(
        'action_bookings_summary.change_source.notifications.applied',
      );

      await booking.reload();
    } catch {
      booking.source = previousSource;

      this.notifications.error();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::SourceSelector': typeof UiBookingSourceSelectorComponent;
  }
}
