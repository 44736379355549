import { addDays } from 'date-fns/addDays';
import { subDays } from 'date-fns/subDays';
import { cached, tracked } from '@glimmer/tracking';
import DS from 'ember-data';
import { type Registry as Services, service } from '@ember/service';
import { formatDateQuery } from 'uplisting-frontend/utils';
import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import CalendarModel from 'uplisting-frontend/models/calendar';

interface IPageData {
  startDate: Date;
  endDate: Date;
  data: CalendarModel;
}

interface IFetchCalendarParams {
  start_date: string;
  end_date: string;
  include: string;
  property_id?: string;
}

export default class CalendarRepositoryService extends BaseRepositoryService<CalendarModel> {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  recordName = 'calendar';
  implementMethods = ['query'];

  public pageSize = 21;

  @cached @tracked pageData: IPageData[] = [];
  @cached @tracked dateOptions = this.buildDateOptions(
    subDays(new Date(), 1),
    this.pageSize,
  );

  @cached @tracked minItem = 0;
  @cached @tracked maxItem = this.pageSize;

  private buildDateOptions(startDate: Date, pageSize: number): Date[] {
    const result: Date[] = [];

    for (let i = 0; i < pageSize; i++) {
      result.push(startDate);

      startDate = addDays(startDate, 1);
    }

    return result;
  }

  public async loadData(
    startDate: Date,
    endDate: Date,
    ids?: string,
  ): Promise<DS.PromiseObject<CalendarModel>> {
    const params: IFetchCalendarParams = {
      start_date: formatDateQuery(startDate),
      end_date: formatDateQuery(endDate),
      include: [
        'bookings',
        'bookings.property',
        'bookings.multi_unit',
        'changeovers',
        'changeovers.booking',
        'changeovers.property',
        'changeovers.multi_unit',
      ].join(','),
    };

    if (ids) {
      params.property_id = ids;
    }

    const data = await this.query(params);

    return data[0] as CalendarModel;
  }

  public async loadInitialData(): Promise<DS.PromiseObject<CalendarModel>> {
    const { dateOptions } = this;

    const startDate = dateOptions[0] as Date;
    const endDate = dateOptions[this.pageSize - 1] as Date;
    const data = await this.loadData(startDate, endDate);

    this.pageData = [
      {
        startDate,
        endDate,
        data,
      },
    ];

    return data;
  }

  public async loadNext(): Promise<DS.PromiseObject<CalendarModel>> {
    const lastPage = this.pageData[this.pageData.length - 1] as IPageData;

    const dateOptions = this.buildDateOptions(
      addDays(lastPage.endDate, 1),
      this.pageSize,
    );

    this.dateOptions = [...this.dateOptions, ...dateOptions];

    const startDate = dateOptions[0] as Date;
    const endDate = dateOptions[this.pageSize - 1] as Date;

    const data = await this.loadData(startDate, endDate);

    this.pageData = [
      ...this.pageData,
      {
        startDate,
        endDate,
        data,
      },
    ];

    this.maxItem = this.dateOptions.length - 1;

    return data;
  }

  public async loadPrev(): Promise<DS.PromiseObject<CalendarModel>> {
    const firstPage = this.pageData[0] as IPageData;

    const dateOptions = this.buildDateOptions(
      subDays(firstPage.startDate, this.pageSize),
      this.pageSize,
    );

    this.dateOptions = [...dateOptions, ...this.dateOptions];

    const startDate = dateOptions[0] as Date;
    const endDate = dateOptions[this.pageSize - 1] as Date;

    const data = await this.loadData(startDate, endDate);

    this.pageData = [
      {
        startDate,
        endDate,
        data,
      },
      ...this.pageData,
    ];

    return data;
  }
}

declare module '@ember/service' {
  interface Registry {
    'repositories/calendar': CalendarRepositoryService;
  }
}
