import type Transition from '@ember/routing/transition';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';

export default class NotificationsRoute extends PermittedRoute {
  permission = 'notifications';

  async beforeModel(transition: Transition): Promise<void> {
    if (transition.targetName === 'notifications.index') {
      await this.router.transitionTo('notifications.uplisting.pending');
    }

    return super.beforeModel(transition);
  }
}
