import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import {
  type IQuoteSchema,
  type IExtraPriceSchema,
} from 'uplisting-frontend/models/schemas';
import BookingModel from 'uplisting-frontend/models/booking';
import PropertyModel from 'uplisting-frontend/models/property';
import ExtraPriceModel from 'uplisting-frontend/models/extra-price';

type QuoteToPrice = Omit<
  IQuoteSchema,
  | 'checkIn'
  | 'checkOut'
  | 'booking'
  | 'property'
  | 'fees'
  | 'taxes'
  | 'channelFees'
  | 'cancellationDetails'
  | 'discounts'
  | 'charges'
>;

export default class QuoteModel extends Model implements IQuoteSchema {
  @attr('day') checkIn!: Date;
  @attr('day') checkOut!: Date;
  @attr('number') numberOfGuests!: number;
  @attr('number') accommodation!: number;
  @attr('number') averagePricePerNight!: number;
  @attr('number') balance!: number;
  @attr('number') cancellationPayout!: number;
  @attr('number') cleaningFee!: number;
  @attr('number') commission!: number;
  @attr('string') currency!: string;
  @attr('number') extraGuest!: number;
  @attr('number') extraGuestCharge!: number;
  @attr('number') grossRevenue!: number;
  @attr('number') guestPrice!: number;
  @attr('number') hostEarnings!: number;
  @attr('boolean') hostEdited!: boolean;
  @attr('number') managementFee!: number;
  @attr('number') netRevenue!: number;
  @attr('number') nightlyTax!: number;
  @attr('number') personTax!: number;
  @attr('number') rentTax!: number;
  @attr('number') reservationTax!: number;
  @attr('number') subtotal!: number;
  @attr('array') discounts!: IExtraPriceSchema[];
  @attr('array') charges!: IExtraPriceSchema[];

  @belongsTo('booking', { async: false, inverse: null })
  booking!: BookingModel;

  @belongsTo('property', { async: false, inverse: null })
  property!: PropertyModel;

  @hasMany('extra-price', { async: false, inverse: null })
  fees!: ExtraPriceModel[];

  @hasMany('extra-price', { async: false, inverse: null })
  taxes!: ExtraPriceModel[];

  @hasMany('extra-price', { async: false, inverse: null })
  channelFees!: ExtraPriceModel[];

  @hasMany('extra-price', { async: false, inverse: null })
  cancellationDetails!: ExtraPriceModel[];

  public toObject(): QuoteToPrice {
    return {
      accommodation: this.accommodation,
      averagePricePerNight: this.averagePricePerNight,
      balance: this.balance,
      cancellationPayout: this.cancellationPayout,
      cleaningFee: this.cleaningFee,
      commission: this.commission,
      currency: this.currency,
      extraGuest: this.extraGuest,
      extraGuestCharge: this.extraGuestCharge,
      grossRevenue: this.grossRevenue,
      guestPrice: this.guestPrice,
      hostEarnings: this.hostEarnings,
      hostEdited: this.hostEdited,
      managementFee: this.managementFee,
      netRevenue: this.netRevenue,
      nightlyTax: this.nightlyTax,
      numberOfGuests: this.numberOfGuests,
      personTax: this.personTax,
      rentTax: this.rentTax,
      reservationTax: this.reservationTax,
      subtotal: this.subtotal,
    };
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    quote: QuoteModel;
  }
}
