import Model, { belongsTo, hasMany } from '@ember-data/model';
import { type IBulkAdditionalBookingChargeSchema } from 'uplisting-frontend/models/schemas';
import BookingModel from 'uplisting-frontend/models/booking';
import AdditionalBookingChargeModel from 'uplisting-frontend/models/additional-booking-charge';

export default class BulkAdditionalBookingChargeModel
  extends Model
  implements IBulkAdditionalBookingChargeSchema
{
  @belongsTo('booking', { inverse: null, async: false })
  booking!: BookingModel;

  @hasMany('additional-booking-charge', { inverse: null, async: false })
  additionalBookingCharges!: AdditionalBookingChargeModel[];
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'bulk-additional-booking-charge': BulkAdditionalBookingChargeModel;
  }
}
