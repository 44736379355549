import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import differenceBy from 'lodash-es/differenceBy';
import isEqual from 'lodash-es/isEqual';
import InsightModel, {
  type IRevenueItemFormatted,
} from 'uplisting-frontend/models/insight';
import InsightsBaseController from 'uplisting-frontend/pods/insights/base-controller';
import { type IApplyPropertiesFilterParams } from 'uplisting-frontend/components/ui/properties/filter';

export default class InsightsAllController extends InsightsBaseController<InsightModel> {
  queryParams = [
    'start',
    'end',

    'compareToStart',
    'compareToEnd',

    'propertyIds',
    'compareToPropertyIds',

    'propertyTagIds',
    'compareToPropertyTagIds',
  ];

  @cached
  get isDateFilterComparisonDisabled(): boolean {
    return (
      !!this.compareToPropertyIds.length ||
      !!this.compareToPropertyTagIds.length
    );
  }

  @cached
  get revenueMetrics(): IRevenueItemFormatted[] {
    return this.getRevenueMetrics('combinedRevenue');
  }

  @cached
  get averageDailyRevenueMetrics(): IRevenueItemFormatted[] {
    return this.getRevenueMetrics('combinedAverageDailyRevenue');
  }

  @action
  computeCompareToRevenueAmount(
    id: string,
    key: 'combinedRevenue' | 'combinedAverageDailyRevenue',
  ): string | undefined {
    return this.compareToRecord?.[key]?.find((item) => item.id === id)?.amount;
  }

  @action
  handlePropertiesFilterApply(obj: IApplyPropertiesFilterParams): void {
    const ids = obj.items.map((item) => item.id);
    const compareToIds = obj.compareToItems.map((item) => item.id);

    const selectedIds = this.selectedItems.map((item) => item.id);
    const selectedCompareToIds = this.selectedCompareToItems.map(
      (item) => item.id,
    );

    if (
      isEqual(selectedIds, ids) &&
      isEqual(selectedCompareToIds, compareToIds)
    ) {
      return;
    }

    if (obj.isSelectingProperties) {
      this.propertyIds = ids;
      this.compareToPropertyIds = compareToIds;

      this.propertyTagIds = [];
      this.compareToPropertyTagIds = [];
    } else {
      this.propertyTagIds = ids;
      this.compareToPropertyTagIds = compareToIds;

      this.propertyIds = [];
      this.compareToPropertyIds = [];
    }

    this.isLoading = true;
  }

  private getRevenueMetrics(
    key: 'combinedRevenue' | 'combinedAverageDailyRevenue',
  ): IRevenueItemFormatted[] {
    const { record, compareToRecord } = this;
    const base = record[key];

    if (compareToRecord) {
      const diffItems = differenceBy(compareToRecord[key], base, 'id');

      return [
        ...base,
        ...diffItems.map((item) => ({
          ...item,
          amount: '0',
        })),
      ];
    }

    return base;
  }

  public resetState(): void {
    super.resetState();

    this.propertyTagIds = [];
    this.compareToPropertyTagIds = [];
  }
}
