import type Transition from '@ember/routing/transition';
import { type Registry as Services, service } from '@ember/service';
import { hash } from 'rsvp';
import DS from 'ember-data';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';
import UpsellsEditController from 'uplisting-frontend/pods/upsells/edit/controller';
import UpsellModel from 'uplisting-frontend/models/upsell';
import PropertyModel from 'uplisting-frontend/models/property';
import TaxModel from 'uplisting-frontend/models/tax';

interface IUpsellsEditModel {
  upsells: DS.PromiseArray<UpsellModel>;
  properties: DS.PromiseArray<PropertyModel>;
  taxes: DS.PromiseArray<TaxModel>;
  upsell: UpsellModel;
}

export default class UpsellsIndexRoute extends ProtectedRoute {
  @service('repositories/tax') taxRepository!: Services['repositories/tax'];

  @service('repositories/upsell')
  upsellRepository!: Services['repositories/upsell'];

  async model({ id }: { id: string }): Promise<IUpsellsEditModel> {
    const data = await hash({
      upsells: this.upsellRepository.findAll(),
      properties: this.modelFor('upsells'),
      taxes: this.taxRepository.findAll(),
    });

    (data as IUpsellsEditModel).upsell = data.upsells.find(
      (upsell) => upsell.id === id,
    ) as UpsellModel;

    return data as IUpsellsEditModel;
  }

  setupController(
    controller: UpsellsEditController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    if (!model.upsell.tax?.get('id')) {
      model.upsell.tax = this.taxRepository.defaultTaxOption;
    }

    Object.assign(controller, {
      properties: model.properties.slice(),
      upsell: model.upsell,
    });
  }
}
