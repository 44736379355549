import { validateNumber } from 'ember-changeset-validations/validators';

export const BookingRefundActionValidation = {
  amount: [
    validateNumber({ gt: 0 }),
    function (
      _field,
      newAmount,
      _oldAmount,
      _content,
      changeset,
    ): undefined | 'error' {
      if (changeset.payment.maxRefundAmount >= Number(newAmount)) {
        return;
      }

      // if validation function returns string - it means that validation failed
      return 'error';
    },
  ],
};
