import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-expect-error: glint doesn't support usage of `component` helper }}\n{{#let (component this.component) as |OptionComponent|}}\n  <OptionComponent @filter={{@filter}} />\n{{/let}}\n", {"contents":"{{! @glint-expect-error: glint doesn't support usage of `component` helper }}\n{{#let (component this.component) as |OptionComponent|}}\n  <OptionComponent @filter={{@filter}} />\n{{/let}}\n","moduleName":"uplisting-frontend/components/ui/report-filters/options/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/report-filters/options/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import {
  type OccasionFilter,
  OccasionFilterComponent,
} from 'uplisting-frontend/utils/occasion-filters';

interface IArgs {
  filter: OccasionFilter;
}

interface ReportFiltersOptionsSignature {
  Element: null;

  Args: IArgs;
}

export default class UiReportFiltersOptionsComponent extends Component<ReportFiltersOptionsSignature> {
  @cached
  get component(): string {
    return `ui/report-filters/options/${this.componentType}`;
  }

  @cached
  get componentType(): string {
    switch (this.args.filter.component) {
      case OccasionFilterComponent.singleSelect:
        return 'single-select';
      case OccasionFilterComponent.multiSelect:
        return 'multi-select';
      case OccasionFilterComponent.storeMultiSelect:
        return 'store-multi-select';
      case OccasionFilterComponent.numerical:
        return 'numerical';
      case OccasionFilterComponent.date:
        return 'date';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::ReportFilters::Options': typeof UiReportFiltersOptionsComponent;
  }
}
