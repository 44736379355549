import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import orderBy from 'lodash-es/orderBy';
import { type Registry as Services, service } from '@ember/service';
import Ember from 'ember';
import BaseController from 'uplisting-frontend/pods/base/controller';
import ClientStatementModel from 'uplisting-frontend/models/client-statement';
import { ClientStatementStatus } from 'uplisting-frontend/models/schemas';
import { FilterId } from 'uplisting-frontend/utils/filters/abstract';
import {
  type ClientStatementsFilter,
  getFilterQueryParamName,
} from 'uplisting-frontend/utils/filters/types';

export default class ClientStatementsIndexController extends BaseController {
  @service('filters') filtersService!: Services['filters'];
  @service('repositories/client-statement')
  clientStatementRepository!: Services['repositories/client-statement'];

  queryParams = ['clientIds', 'propertyIds', 'period', 'status'];

  @cached @tracked isLoading = false;

  @cached @tracked statements!: Ember.Array<ClientStatementModel>;

  @cached @tracked clientIds: string[] = [];
  @cached @tracked propertyIds: string[] = [];
  @cached @tracked period?: string;
  @cached @tracked status?: ClientStatementStatus;

  @cached
  get filters(): ClientStatementsFilter[] {
    const { filtersService } = this;

    return [
      filtersService.createFilterFor(FilterId.clientName, this.clientIds),
      filtersService.createFilterFor(FilterId.property, this.propertyIds),
      filtersService.createFilterFor(FilterId.period, this.period),
      filtersService.createFilterFor(
        FilterId.clientStatementStatus,
        this.status,
      ),
    ] as ClientStatementsFilter[];
  }

  @cached
  get statementsSorted(): ClientStatementModel[] {
    return orderBy(this.statements?.slice(), ['endDate'], ['desc']);
  }

  @action
  computeStatusBadge(status: ClientStatementStatus): string {
    if (status === ClientStatementStatus.finalised) {
      return 'badge-finale';
    }

    return 'badge-warning';
  }

  @action
  handleResetFilters(): void {
    this.filters.forEach((filter) => {
      filter.reset();

      this.updateFilter(filter);
    });
  }

  @action
  async handleFetchStatements(filter?: ClientStatementsFilter): Promise<void> {
    this.isLoading = true;

    if (filter) {
      this.updateFilter(filter);
    }

    await this.fetchData();

    this.isLoading = false;
  }

  async fetchData(): Promise<void> {
    const data = await this.clientStatementRepository.query({
      property_ids: this.propertyIds,
      client_ids: this.clientIds,
      month: this.period,
      status: this.status,
    });

    this.statements = data;
  }

  private updateFilter(filter: ClientStatementsFilter): void {
    const queryParamField = getFilterQueryParamName(filter);

    this[queryParamField] = filter.toQuery();
  }
}
