import type Transition from '@ember/routing/transition';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';
import ClientsController from 'uplisting-frontend/pods/clients/controller';

export default class ClientsRoute extends ProtectedRoute {
  setupController(
    controller: ClientsController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { route: this });
  }
}
