import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import PriceModel from 'uplisting-frontend/models/price';
import { PriceValidation } from 'uplisting-frontend/validations';

export default class PriceRepositoryService extends BaseRepositoryService<PriceModel> {
  recordName = 'price';
  implementMethods = [];

  validation = PriceValidation;
}

declare module '@ember/service' {
  interface Registry {
    'repositories/price': PriceRepositoryService;
  }
}
