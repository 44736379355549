import BookingModel from 'uplisting-frontend/models/booking';
import AdditionalBookingChargeModel from 'uplisting-frontend/models/additional-booking-charge';

export enum GuestInvoiceStatus {
  open = 'open',
  paymentError = 'payment_error',
  paymentMethodMissing = 'payment_method_missing',
  markedAsPaid = 'marked_as_paid',
  paid = 'paid',
  void = 'void',
}

export interface IGuestInvoiceSchema {
  status: GuestInvoiceStatus | null;
  externalId: string;

  booking: BookingModel;
  additionalBookingCharges: AdditionalBookingChargeModel[];
}
