import DS from 'ember-data';
import { isPresent } from '@ember/utils';
import ApplicationSerializer from 'uplisting-frontend/serializers/application';

export default class PriceSerializer extends ApplicationSerializer {
  serialize(snapshot: DS.Snapshot, options: object) {
    const json = super.serialize(snapshot, options);

    const discounts = snapshot.record.discounts
      .filter((discount) => isPresent(discount.amount))
      .map((discount) => discount.toObject());

    const charges = snapshot.record.charges
      .filter((charge) => isPresent(charge.amount))
      .map((discount) => discount.toObject());

    if (discounts.length > 0) {
      // @ts-expect-error - we have no typings for the json api
      json.data.attributes.discounts = discounts;
    }

    if (charges.length > 0) {
      // @ts-expect-error - we have no typings for the json api
      json.data.attributes.charges = charges;
    }

    return json;
  }
}

declare module 'ember-data/types/registries/serializer' {
  interface SerializerRegistry {
    price: PriceSerializer;
  }
}
