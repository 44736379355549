import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"create-tax\">\n  <hr>\n\n  <LinkTo @route=\"account.taxes\" class=\"ember-power-select-option\">\n    {{t \"tax.create_a_tax\"}}\n  </LinkTo>\n</div>\n", {"contents":"<div local-class=\"create-tax\">\n  <hr>\n\n  <LinkTo @route=\"account.taxes\" class=\"ember-power-select-option\">\n    {{t \"tax.create_a_tax\"}}\n  </LinkTo>\n</div>\n","moduleName":"uplisting-frontend/components/ui/create-tax/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/create-tax/index.hbs"}});
import templateOnly from '@ember/component/template-only';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface IArgs {}

interface CreateTaxSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    default: [];
  };
}

const UiCreateTaxComponent = templateOnly<CreateTaxSignature>();

export default UiCreateTaxComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ui/create-tax': typeof UiCreateTaxComponent;
  }
}
