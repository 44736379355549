import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"tabs-wrapper\" data-test-nav-tabs>\n  {{#each @tabs as |tab|}}\n    <div\n      local-class=\"tab-item {{if (eq @activeTab tab.id) \"active\"}}\"\n      {{on \"click\" (fn @onClick tab)}}\n      data-test-nav-tabs-tab\n    >\n      {{tab.name}}\n    </div>\n  {{/each}}\n</div>\n", {"contents":"<div local-class=\"tabs-wrapper\" data-test-nav-tabs>\n  {{#each @tabs as |tab|}}\n    <div\n      local-class=\"tab-item {{if (eq @activeTab tab.id) \"active\"}}\"\n      {{on \"click\" (fn @onClick tab)}}\n      data-test-nav-tabs-tab\n    >\n      {{tab.name}}\n    </div>\n  {{/each}}\n</div>\n","moduleName":"uplisting-frontend/components/ui/nav-tabs/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/nav-tabs/index.hbs"}});
import Component from '@glimmer/component';

export interface INavTab<T> {
  id: T;
  name: string;
}

interface IArgs<T> {
  tabs: INavTab<T>[];
  activeTab?: T;
  onClick(item: INavTab<T>): void;
}

interface NavTabsSignature<T> {
  Element: HTMLDivElement;

  Args: IArgs<T>;
}

export default class UiNavTabsComponent<T extends string> extends Component<
  NavTabsSignature<T>
> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::NavTabs': typeof UiNavTabsComponent;
  }
}
