import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{!-- template-lint-disable no-yield-only --}}\n{{#if @text}}\n  {{@text}}\n{{else}}\n  {{yield}}\n{{/if}}\n", {"contents":"{{!-- template-lint-disable no-yield-only --}}\n{{#if @text}}\n  {{@text}}\n{{else}}\n  {{yield}}\n{{/if}}\n","moduleName":"uplisting-frontend/components/empty-component/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/empty-component/index.hbs"}});
import templateOnly from '@ember/component/template-only';

interface IArgs {
  text?: string;
}

interface EmptyComponentSignature {
  Element: null;

  Args: IArgs;

  Blocks: {
    default: [];
  };
}

const UiEmptyComponent = templateOnly<EmptyComponentSignature>();

export default UiEmptyComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'empty-component': typeof UiEmptyComponent;
  }
}
