import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ul class=\"column-selector\" data-test-report-filter-options>\n  {{#each this.options as |option|}}\n    <Ui::Dropdown::Multiselect::Item\n      @item={{t (concat this.translationField \".\" option)}}\n      @isSelected={{array-includes option this.filter.values}}\n      @onClick={{fn this.handleOptionSelect option}}\n    />\n  {{/each}}\n</ul>\n", {"contents":"<ul class=\"column-selector\" data-test-report-filter-options>\n  {{#each this.options as |option|}}\n    <Ui::Dropdown::Multiselect::Item\n      @item={{t (concat this.translationField \".\" option)}}\n      @isSelected={{array-includes option this.filter.values}}\n      @onClick={{fn this.handleOptionSelect option}}\n    />\n  {{/each}}\n</ul>\n","moduleName":"uplisting-frontend/components/ui/report-filters/options/multi-select/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/report-filters/options/multi-select/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type OccasionPredefinedOptionsFilter } from 'uplisting-frontend/utils/occasion-filters';

interface IArgs {
  filter: OccasionPredefinedOptionsFilter;
}

interface ReportFiltersOptionsSignature {
  Element: HTMLUListElement;

  Args: IArgs;
}

export default class UiReportFiltersOptionsMultiSelectComponent extends Component<ReportFiltersOptionsSignature> {
  @cached
  get filter(): OccasionPredefinedOptionsFilter {
    return this.args.filter;
  }

  @cached
  get options(): string[] {
    return this.filter.optionsList;
  }

  @cached
  get translationField(): string {
    return this.filter.field.replace('booking_', '');
  }

  @action
  handleOptionSelect(option: string): void {
    this.filter.updateValues(option);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::ReportFilters::Options::MultiSelect': typeof UiReportFiltersOptionsMultiSelectComponent;
  }
}
