import type Transition from '@ember/routing/transition';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';

export default class IndexRoute extends ProtectedRoute {
  async beforeModel(transition: Transition) {
    await super.beforeModel(transition);

    await this.redirectAfterLogin();
  }

  public async redirectAfterLogin(): Promise<void> {
    const [route, isExternal] = this.getRouteForAuthenticated();

    if (isExternal) {
      const url = this.router.urlFor(route);

      window.open(url, '_self');
    } else {
      await this.router.transitionTo(route);
    }
  }

  private getRouteForAuthenticated(): [string, boolean] {
    const { currentUser } = this;

    if (currentUser.hasAccessTo('calendar.index')) {
      return ['calendar', true];
    } else if (currentUser.hasAccessTo('insights')) {
      return ['insights', false];
    } else if (currentUser.hasAccessTo('calendar.reports')) {
      return ['calendar.reports', true];
    } else if (currentUser.hasAccessTo('calendar.inbox')) {
      return ['calendar.inbox', true];
    } else {
      return ['no-access', true];
    }
  }
}
