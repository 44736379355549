import { type Registry as Services, service } from '@ember/service';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';

export default class ConnectWebhookRoute extends ProtectedRoute {
  @service('repositories/zapier-api-key')
  zapierApiKeyRepository!: Services['repositories/zapier-api-key'];

  model() {
    return this.zapierApiKeyRepository.getDefaultRecord();
  }
}
