import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"mt-2 inline-alert booking-status-alert {{this.alertClass}}\" data-test-booking-status=\"lock-code\">\n  <div>\n    <strong>{{t \"action_bookings_summary.lock_code_status.title\"}}</strong>\n\n    {{t (concat \"action_bookings_summary.lock_code_status.\" this.status)}}\n\n    {{#if @pin}}\n      ({{@pin}})\n    {{/if}}\n  </div>\n\n  {{#if this.icon}}\n    <FaIcon @icon={{this.icon}} @prefix={{this.iconPrefix}} />\n  {{/if}}\n</div>\n", {"contents":"<div class=\"mt-2 inline-alert booking-status-alert {{this.alertClass}}\" data-test-booking-status=\"lock-code\">\n  <div>\n    <strong>{{t \"action_bookings_summary.lock_code_status.title\"}}</strong>\n\n    {{t (concat \"action_bookings_summary.lock_code_status.\" this.status)}}\n\n    {{#if @pin}}\n      ({{@pin}})\n    {{/if}}\n  </div>\n\n  {{#if this.icon}}\n    <FaIcon @icon={{this.icon}} @prefix={{this.iconPrefix}} />\n  {{/if}}\n</div>\n","moduleName":"uplisting-frontend/components/ui/booking/status/lock-code/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/booking/status/lock-code/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { LockCodeStatus } from 'uplisting-frontend/models/schemas';

interface IArgs {
  pin: string;
  status: LockCodeStatus;
}

export interface BookingStatusLockCodeSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiBookingStatusLockCodeComponent extends Component<BookingStatusLockCodeSignature> {
  @cached
  get status(): LockCodeStatus {
    return this.args.status;
  }

  @cached
  get alertClass(): 'inline-alert-processing' | 'inline-alert-success' {
    switch (this.status) {
      case LockCodeStatus.pending:
      case LockCodeStatus.awaiting:
        return 'inline-alert-processing';
      case LockCodeStatus.confirmed:
        return 'inline-alert-success';
    }
  }

  @cached
  get icon(): 'circle-check' | 'clock' | undefined {
    switch (this.status) {
      case LockCodeStatus.confirmed:
        return 'circle-check';
      case LockCodeStatus.pending:
      case LockCodeStatus.awaiting:
        return 'clock';
    }
  }

  @cached
  get iconPrefix(): 'far' | undefined {
    if (this.icon === 'clock') {
      return 'far';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Status::LockCode': typeof UiBookingStatusLockCodeComponent;
  }
}
