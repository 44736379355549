import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BsAlert @type=\"info\" @dismissible={{false}} class=\"alert-big\">\n  <p class=\"text-bold mb-3\">\n    <FaIcon @icon={{this.icon}} class={{@iconClass}} />\n\n    &nbsp;\n\n    {{@title}}\n  </p>\n\n  {{yield}}\n</BsAlert>\n", {"contents":"<BsAlert @type=\"info\" @dismissible={{false}} class=\"alert-big\">\n  <p class=\"text-bold mb-3\">\n    <FaIcon @icon={{this.icon}} class={{@iconClass}} />\n\n    &nbsp;\n\n    {{@title}}\n  </p>\n\n  {{yield}}\n</BsAlert>\n","moduleName":"uplisting-frontend/components/ui/connect/how-to-enable/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/connect/how-to-enable/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

interface IArgs {
  title: string;
  icon?: string;
  iconClass?: string;
}

interface ConnectHowToEnableSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    default: [];
  };
}

export default class UiConnectHowToEnableComponent extends Component<ConnectHowToEnableSignature> {
  @cached
  get icon(): string {
    return this.args.icon ?? 'circle-question';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Connect::HowToEnable': typeof UiConnectHowToEnableComponent;
  }
}
