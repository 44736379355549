import { type Registry as Services, service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import { hash } from 'rsvp';
import AutomateDepositsController from 'uplisting-frontend/pods/automate/deposits/controller';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';

export default class AutomateDepositsRoute extends PermittedRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/security-deposit-setting')
  securityDepositSettingRepository!: Services['repositories/security-deposit-setting'];

  permission = 'automate.deposits';

  model() {
    return hash({
      properties: this.propertyRepository.findAll(),
      securityDepositSettings: this.securityDepositSettingRepository.findAll(),
    });
  }

  setupController(
    controller: AutomateDepositsController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    const bulkSecurityDepositSetting = this.store.createRecord(
      'bulk-security-deposit-setting',
      {
        securityDepositSettings: model.securityDepositSettings,
      },
    );

    Object.assign(controller, {
      bulkSecurityDepositSetting,
      ...model,
    });

    controller.setDefaultState();
  }

  resetController(): void {
    this.securityDepositSettingRepository.unloadAll();
  }
}
