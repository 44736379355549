import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BsDropdown local-class=\"filter-operator {{if this.canChangeOperator \"__selectable\"}}\" @closeOnMenuClick={{false}} data-test-report-filter-operator-type as |dd|>\n  <dd.toggle class=\"ember-basic-dropdown-trigger\" data-test-report-filter-operators-open>\n    {{t (concat \"general.\" this.filterGroup.type)}}\n    <span class=\"ember-power-select-status-icon\"></span>\n  </dd.toggle>\n\n  {{#if this.canChangeOperator}}\n    <dd.menu>\n      {{#each this.filterTypes as |filterType|}}\n        <li\n          {{on \"click\" (fn this.handleFilterTypeChange filterType dd)}}\n          data-test-report-filter-operator-option\n        >\n          {{t (concat \"general.\" filterType)}}\n        </li>\n      {{/each}}\n    </dd.menu>\n  {{/if}}\n</BsDropdown>\n", {"contents":"<BsDropdown local-class=\"filter-operator {{if this.canChangeOperator \"__selectable\"}}\" @closeOnMenuClick={{false}} data-test-report-filter-operator-type as |dd|>\n  <dd.toggle class=\"ember-basic-dropdown-trigger\" data-test-report-filter-operators-open>\n    {{t (concat \"general.\" this.filterGroup.type)}}\n    <span class=\"ember-power-select-status-icon\"></span>\n  </dd.toggle>\n\n  {{#if this.canChangeOperator}}\n    <dd.menu>\n      {{#each this.filterTypes as |filterType|}}\n        <li\n          {{on \"click\" (fn this.handleFilterTypeChange filterType dd)}}\n          data-test-report-filter-operator-option\n        >\n          {{t (concat \"general.\" filterType)}}\n        </li>\n      {{/each}}\n    </dd.menu>\n  {{/if}}\n</BsDropdown>\n","moduleName":"uplisting-frontend/components/ui/report-filters/operator-selector/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/report-filters/operator-selector/index.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { cached } from '@glimmer/tracking';
import { type IDropdown } from 'ember-bootstrap/components/bs-dropdown';
import { OccasionFilterGroup } from 'uplisting-frontend/utils/occasion-filters';
import { OccasionFilterType } from 'uplisting-frontend/models/schemas';

interface IArgs {
  filterGroup: OccasionFilterGroup;
  canChangeOperator?: boolean;
  onFilterTypeChange?(): void;
}

interface ReportFiltersOperatorSelectorSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiReportFiltersOperatorSelectorComponent extends Component<ReportFiltersOperatorSelectorSignature> {
  filterTypes = [OccasionFilterType.and, OccasionFilterType.or];

  @cached
  get filterGroup(): OccasionFilterGroup {
    return this.args.filterGroup;
  }

  @cached
  get canChangeOperator(): boolean {
    return this.args.canChangeOperator ?? true;
  }

  @action
  handleFilterTypeChange(filterType: OccasionFilterType, dd: IDropdown): void {
    this.filterGroup.type = filterType;

    dd.closeDropdown();

    this.args.onFilterTypeChange?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::ReportFilters::OperatorSelector': typeof UiReportFiltersOperatorSelectorComponent;
  }
}
