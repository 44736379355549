import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"mt-2 inline-alert booking-status-alert {{this.alertClass}}\" data-test-booking-status=\"rental-agreement\">\n  <div>\n    <strong>{{t \"action_bookings_summary.rental_agreement_status.title\"}}</strong>\n\n    {{t (concat \"action_bookings_summary.rental_agreement_status.\" this.status)}}\n\n    {{#if this.showLink}}\n      {{t \"action_bookings_summary.rental_agreement_status.view_link_1\"}}\n\n      <a class=\"btn-link\" href={{this.rentalAgreement.fileLink}} target=\"_blank\" rel=\"noopener noreferrer\">\n        {{t \"action_bookings_summary.rental_agreement_status.view_link_2\"}}\n      </a>\n    {{/if}}\n  </div>\n\n  {{#if this.icon}}\n    <FaIcon @icon={{this.icon}} @prefix={{this.iconPrefix}} />\n  {{/if}}\n</div>\n", {"contents":"<div class=\"mt-2 inline-alert booking-status-alert {{this.alertClass}}\" data-test-booking-status=\"rental-agreement\">\n  <div>\n    <strong>{{t \"action_bookings_summary.rental_agreement_status.title\"}}</strong>\n\n    {{t (concat \"action_bookings_summary.rental_agreement_status.\" this.status)}}\n\n    {{#if this.showLink}}\n      {{t \"action_bookings_summary.rental_agreement_status.view_link_1\"}}\n\n      <a class=\"btn-link\" href={{this.rentalAgreement.fileLink}} target=\"_blank\" rel=\"noopener noreferrer\">\n        {{t \"action_bookings_summary.rental_agreement_status.view_link_2\"}}\n      </a>\n    {{/if}}\n  </div>\n\n  {{#if this.icon}}\n    <FaIcon @icon={{this.icon}} @prefix={{this.iconPrefix}} />\n  {{/if}}\n</div>\n","moduleName":"uplisting-frontend/components/ui/booking/status/rental-agreement/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/booking/status/rental-agreement/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { BookingRentalAgreementStatus } from 'uplisting-frontend/models/schemas';
import BookingRentalAgreementModel from 'uplisting-frontend/models/booking-rental-agreement';

interface IArgs {
  rentalAgreement: BookingRentalAgreementModel;
}

export interface BookingStatusRentalAgreementSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiBookingStatusRentalAgreementComponent extends Component<BookingStatusRentalAgreementSignature> {
  @cached
  get rentalAgreement(): BookingRentalAgreementModel {
    return this.args.rentalAgreement;
  }

  @cached
  get status(): BookingRentalAgreementStatus {
    return this.rentalAgreement.status;
  }

  @cached
  get showLink(): boolean {
    return this.status === BookingRentalAgreementStatus.processed;
  }

  @cached
  get alertClass():
    | 'inline-alert-danger'
    | 'inline-alert-processing'
    | 'inline-alert-success' {
    switch (this.status) {
      case BookingRentalAgreementStatus.pending:
        return 'inline-alert-danger';
      case BookingRentalAgreementStatus.processing:
      case BookingRentalAgreementStatus.markedAsSigned:
        return 'inline-alert-processing';
      case BookingRentalAgreementStatus.processed:
        return 'inline-alert-success';
    }
  }

  @cached
  get icon(): 'circle-xmark' | 'circle-check' | 'clock' | undefined {
    switch (this.status) {
      case BookingRentalAgreementStatus.pending:
        return 'circle-xmark';
      case BookingRentalAgreementStatus.processing:
      case BookingRentalAgreementStatus.markedAsSigned:
        return 'clock';
      case BookingRentalAgreementStatus.processed:
        return 'circle-check';
    }
  }

  @cached
  get iconPrefix(): 'far' | undefined {
    if (this.icon === 'clock') {
      return 'far';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Status::RentalAgreement': typeof UiBookingStatusRentalAgreementComponent;
  }
}
