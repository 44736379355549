import type Transition from '@ember/routing/transition';
import { type Registry as Services, service } from '@ember/service';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import ActionBookingsSummaryController from 'uplisting-frontend/pods/action/bookings/summary/controller';
import { type IParams } from 'uplisting-frontend/pods/-sidebar/bookings/route';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';

export default abstract class SidebarBookingsSummaryRoute extends PermittedRoute {
  @service analytics!: Services['analytics'];
  @service('repositories/booking')
  bookingRepository!: Services['repositories/booking'];

  permission = 'calendar.bookings';

  abstract parentRoute: string;

  model() {
    const params = this.paramsFor(this.parentRoute) as IParams;

    return this.bookingRepository.findRecord(params.booking_id, {
      reload: true,
      include:
        'property,booking_rental_agreement,guest_identity_verification,booking_payment,booking_payment.payments,security_deposit,security_deposit_action,channel_user,price',
    });
  }

  setupController(
    controller: ActionBookingsSummaryController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { booking: model });
  }

  afterModel() {
    this.analytics.trackEvent(AnalyticsEvents.viewedBookingSummary);
  }
}
