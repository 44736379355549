import BookingModel from 'uplisting-frontend/models/booking';
import PaymentModel from 'uplisting-frontend/models/payment';

export enum BookingPaymentStatus {
  charged = 'charged',
  markedAsPaid = 'marked_as_paid',
  failed = 'failed',
  failing = 'failing',
  hasPaymentInformation = 'has_payment_information',
  invalidPaymentInformation = 'invalid_payment_information',
}

export interface IBookingPaymentSchema {
  cardValid: boolean;
  paymentStatus: BookingPaymentStatus;
  stripeError: string;
  needsCharging: boolean;
  externalCustomerLink: string;

  booking: BookingModel;
  payments: PaymentModel[];
}
