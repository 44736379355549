import { type Registry as Services, service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';
import ReportsShowController from 'uplisting-frontend/pods/insights/reports/show/controller';

export default class ReportsShowRoute extends ProtectedRoute {
  @service('repositories/advanced-report-configuration')
  advancedReportConfigurationRepository!: Services['repositories/advanced-report-configuration'];

  model({ id }: { id: string }) {
    const record = this.advancedReportConfigurationRepository.peekRecord(id);

    if (!record) {
      return this.router.transitionTo('insights.reports.index');
    }

    return record;
  }

  async setupController(
    controller: ReportsShowController,
    model,
    transition: Transition,
  ): Promise<void> {
    super.setupController(controller, model, transition);

    Object.assign(controller, { report: model });

    if (!model) {
      return;
    }

    controller.setDefaultFilterGroup();

    const promises: Promise<any>[] = [controller.fetchData(true)];

    if (!this.isComingFromSameRoute(transition)) {
      promises.push(
        this.store.findAll('property'),
        this.store.findAll('property-tag'),
      );
    }

    await Promise.all(promises);
  }

  resetController(controller: ReportsShowController, isExiting: boolean): void {
    controller.resetPagination();
    controller.report.rollbackAttributes();
    controller.discardReportToEdit();

    if (isExiting) {
      controller.occasions.forEach((occasion) => {
        occasion.unloadRecord();
      });
    }
  }
}
