import { action } from '@ember/object';
import { cached, tracked } from '@glimmer/tracking';
import BaseController from 'uplisting-frontend/pods/base/controller';
import AirbnbOfficialConnectionModel from 'uplisting-frontend/models/airbnb-official-connection';

export default class CompSetsController extends BaseController {
  @cached @tracked airbnbOfficialConnections!: AirbnbOfficialConnectionModel[];

  @cached @tracked isSubmitting = false;

  @cached
  get haveNoConnections(): boolean {
    if (this.airbnbOfficialConnections.length) {
      return false;
    }

    return true;
  }

  @cached
  get ctaTranslationKey(): string {
    let base = 'comp_sets.actionable_section.cta.';

    if (this.haveNoConnections) {
      base += 'connect_airbnb';
    } else if (this.currentUser.airdnaRedirectClick) {
      base += 'go_to_airdna';
    } else {
      base += 'optimize_revenue';
    }

    return base;
  }

  @action
  async handleClick(): Promise<void> {
    if (this.haveNoConnections) {
      await this.router.transitionTo('connect.airbnb', {
        queryParams: {
          state: 'comp-sets',
        },
      });

      return;
    }

    if (!this.currentUser.airdnaRedirectClick) {
      this.currentUser.airdnaRedirectClick = true;

      this.isSubmitting = true;

      try {
        await this.currentUser.save();
      } finally {
        this.isSubmitting = false;
      }
    }

    window.open('https://app.airdna.co/data/my-listing/dashboard', '_blank');
  }
}
