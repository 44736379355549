import { hash } from 'rsvp';
import { type Registry as Services, service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import AutomatePaymentRulesController from 'uplisting-frontend/pods/automate/payment-rules/controller';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';

export default class AutomatePaymentRulesRoute extends PermittedRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/payment-rule')
  paymentRuleRepository!: Services['repositories/payment-rule'];

  permission = 'automate.payment_rules';

  model() {
    return hash({
      properties: this.propertyRepository.findAll(),
      paymentRules: this.paymentRuleRepository.findAll(),
    });
  }

  setupController(
    controller: AutomatePaymentRulesController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { paymentRules: model.paymentRules.slice() });
  }

  resetController(): void {
    this.paymentRuleRepository.unloadAll();
  }
}
