import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#let (component this.messageComponent) as |MessageComponent|}}\n  <MessageComponent @message={{@message}} @booking={{@booking}} @saveReplyRoute={{@saveReplyRoute}} />\n{{/let}}\n", {"contents":"{{#let (component this.messageComponent) as |MessageComponent|}}\n  <MessageComponent @message={{@message}} @booking={{@booking}} @saveReplyRoute={{@saveReplyRoute}} />\n{{/let}}\n","moduleName":"uplisting-frontend/components/ui/booking/messages/message/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/booking/messages/message/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import BookingModel from 'uplisting-frontend/models/booking';
import MessageModel from 'uplisting-frontend/models/message';

interface IArgs {
  booking: BookingModel;
  message: MessageModel;
  saveReplyRoute: string;
}

export interface BookingMessagesMessageSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

type MessageComponent =
  | 'ui/booking/messages/message/inbound'
  | 'ui/booking/messages/message/outbound';

export default class UiBookingMessagesMessageComponent extends Component<BookingMessagesMessageSignature> {
  @cached
  get messageComponent(): MessageComponent {
    if (this.args.message.inbound) {
      return 'ui/booking/messages/message/inbound';
    }

    return 'ui/booking/messages/message/outbound';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Messages::Message': typeof UiBookingMessagesMessageComponent;
  }
}
