import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"before-options-wrapper\">\n  {{!-- template-lint-disable require-input-label --}}\n  <input\n    local-class=\"before-options-input\"\n    type=\"text\"\n    autocomplete=\"nope\"\n    spellcheck=\"false\"\n    placeholder={{@placeholder}}\n\n    value={{@select.searchText}}\n    {{on \"input\" @onInput}}\n  >\n\n  {{!-- template-lint-disable no-bare-strings --}}\n  {{#if @select.searchText}}\n    <span\n      class=\"ember-power-select-clear-btn\"\n      role=\"button\"\n      {{on \"mouseup\" this.handleResetInput}}\n      {{on \"touchstart\" this.handleResetInput}}\n    >\n      &#215;\n    </span>\n  {{/if}}\n</div>\n", {"contents":"<div local-class=\"before-options-wrapper\">\n  {{!-- template-lint-disable require-input-label --}}\n  <input\n    local-class=\"before-options-input\"\n    type=\"text\"\n    autocomplete=\"nope\"\n    spellcheck=\"false\"\n    placeholder={{@placeholder}}\n\n    value={{@select.searchText}}\n    {{on \"input\" @onInput}}\n  >\n\n  {{!-- template-lint-disable no-bare-strings --}}\n  {{#if @select.searchText}}\n    <span\n      class=\"ember-power-select-clear-btn\"\n      role=\"button\"\n      {{on \"mouseup\" this.handleResetInput}}\n      {{on \"touchstart\" this.handleResetInput}}\n    >\n      &#215;\n    </span>\n  {{/if}}\n</div>\n","moduleName":"uplisting-frontend/components/ui/filters/filter/multi-select/before-options/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/filters/filter/multi-select/before-options/index.hbs"}});
import Component from '@glimmer/component';
import { type Select } from 'ember-power-select/components/power-select';
import { action } from '@ember/object';

interface IArgs {
  select: Select;
  placeholder: string;
  onInput(event: Event): void;
}

interface FiltersFilterMultiSelectBeforeOptionsSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class FiltersFilterMultiSelectBeforeOptionsComponent extends Component<FiltersFilterMultiSelectBeforeOptionsSignature> {
  @action
  handleResetInput(): void {
    this.args.select.actions.search('');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Filters::Filter::MultiSelect::BeforeOptions': typeof FiltersFilterMultiSelectBeforeOptionsComponent;
    'ui/filters/filter/multi-select/before-options': typeof FiltersFilterMultiSelectBeforeOptionsComponent;
  }
}
