import Model, { attr } from '@ember-data/model';
import { type IOnboardSchema } from 'uplisting-frontend/models/schemas';

export default class OnboardModel extends Model implements IOnboardSchema {
  @attr('string') country!: string;
  @attr('string') countryCodeName!: string;
  @attr('number') countryCallingCode!: number;
  @attr('number') phoneNumber!: number;
  @attr('number') numberOfProperties!: number;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    onboard: OnboardModel;
  }
}
