import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label for={{this.id}} title={{this.title}} class=\"filters-filter-wrapper single-select-filter\" data-test-filter-item>\n  {{this.label}}\n\n  <PowerSelect\n    class=\"filters-filter\"\n    @triggerId={{this.id}}\n    @options={{this.filter.options}}\n    @selected={{this.filter.params.value}}\n    @placeholder={{this.placeholder}}\n    @allowClear={{true}}\n    @triggerClass=\"big\"\n    @noMatchesMessage={{this.noMatchesMessage}}\n    @onChange={{this.handleChange}}\n  as |name|>\n    {{t (concat this.filter.id \".\" name)}}\n  </PowerSelect>\n</label>\n", {"contents":"<label for={{this.id}} title={{this.title}} class=\"filters-filter-wrapper single-select-filter\" data-test-filter-item>\n  {{this.label}}\n\n  <PowerSelect\n    class=\"filters-filter\"\n    @triggerId={{this.id}}\n    @options={{this.filter.options}}\n    @selected={{this.filter.params.value}}\n    @placeholder={{this.placeholder}}\n    @allowClear={{true}}\n    @triggerClass=\"big\"\n    @noMatchesMessage={{this.noMatchesMessage}}\n    @onChange={{this.handleChange}}\n  as |name|>\n    {{t (concat this.filter.id \".\" name)}}\n  </PowerSelect>\n</label>\n","moduleName":"uplisting-frontend/components/ui/filters/filter/single-select/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/filters/filter/single-select/index.hbs"}});
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import BaseFilterComponent from 'uplisting-frontend/components/ui/filters/filter/base';
import { type SingleSelectFilter } from 'uplisting-frontend/utils/filters/types';

export default class UiFiltersFilterSingleSelectComponent extends BaseFilterComponent<SingleSelectFilter> {
  @cached
  get title(): string | undefined {
    return this.filter.params.value;
  }

  @action
  handleChange(data: string | null): void {
    if (data) {
      this.filter.updateValue(data);
    } else {
      this.filter.reset();
    }

    this.args.onFilterUpdate();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ui/filters/filter/SingleSelect': typeof UiFiltersFilterSingleSelectComponent;
    'Ui::Filters::Filter::SingleSelect': typeof UiFiltersFilterSingleSelectComponent;
  }
}
