import Model, { attr } from '@ember-data/model';
import { type IRefundSchema } from 'uplisting-frontend/models/schemas';

export default class RefundModel extends Model implements IRefundSchema {
  @attr('number') amount!: number;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    refund: RefundModel;
  }
}
