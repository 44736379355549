import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import TeammateModel from 'uplisting-frontend/models/teammate';

export default class TeammateRepositoryService extends BaseRepositoryService<
  TeammateModel,
  TeammateModel
> {
  recordName = 'teammate';
  implementMethods = ['findAll', 'findRecord', 'unloadAll'];

  public async fetchTeammateById(id: string): Promise<TeammateModel> {
    const cache = this.getDataFromCache(id);

    if (cache) {
      return cache;
    }

    const teammate = await this.findRecord(id, {
      reload: true,
      include: 'permissions,permissions.parent',
    });

    this.cache[id] = teammate;

    return teammate;
  }
}

declare module '@ember/service' {
  interface Registry {
    'repositories/teammate': TeammateRepositoryService;
  }
}
