import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import BaseController from 'uplisting-frontend/pods/base/controller';

export enum NavTab {
  pending,
  resolved,
  airbnbHostNotifications,
  airbnbOpportunities,
}

const routes = [
  'notifications.uplisting.pending',
  'notifications.uplisting.resolved',
  'notifications.airbnb.host',
  'notifications.airbnb.opportunities',
];

export default class NotificationsController extends BaseController {
  tabs = NavTab;

  @cached
  get activeNavTab(): NavTab {
    return routes.indexOf(this.router.currentRouteName) as NavTab;
  }

  @action
  async handleNavTabChange(tab: NavTab): Promise<void> {
    await this.router.transitionTo(routes[tab] as string);
  }
}
