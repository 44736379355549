import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-expect-error: glint doesn't support usage of `component` helper }}\n{{#let (component this.rowComponent) as |RowComponent|}}\n  {{! @glint-expect-error: glint don't understand that component is rendered based on the model type }}\n  <RowComponent @item={{this.item}} @date={{@date}} />\n{{/let}}\n", {"contents":"{{! @glint-expect-error: glint doesn't support usage of `component` helper }}\n{{#let (component this.rowComponent) as |RowComponent|}}\n  {{! @glint-expect-error: glint don't understand that component is rendered based on the model type }}\n  <RowComponent @item={{this.item}} @date={{@date}} />\n{{/let}}\n","moduleName":"uplisting-frontend/components/ui/action/table/row/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/action/table/row/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { type ActionDataItem } from 'uplisting-frontend/pods/action/controller';
import ChangeoverModel from 'uplisting-frontend/models/changeover';

interface IArgs {
  item: ActionDataItem;
  date: string;
}

interface ActionTableRowSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

type RowComponent =
  | 'ui/action/table/row/booking'
  | 'ui/action/table/row/changeover';

export default class UiActionTableRowComponent extends Component<ActionTableRowSignature> {
  @cached
  get item(): ActionDataItem {
    return this.args.item;
  }

  @cached
  get rowComponent(): RowComponent {
    if (this.item instanceof ChangeoverModel) {
      return 'ui/action/table/row/changeover';
    }

    return 'ui/action/table/row/booking';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Action::Table::Row': typeof UiActionTableRowComponent;
  }
}
