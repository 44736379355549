import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, service } from '@ember/service';
import { modifier } from 'ember-modifier';
import BaseController from 'uplisting-frontend/pods/base/controller';
import OccasionModel, {
  SectionIds,
  TableSectionFields,
  sortFieldsMapping,
  OccasionTypes,
  OccasionAttribute,
} from 'uplisting-frontend/models/occasion';
import { searchOptions, handleTableScrollSize } from 'uplisting-frontend/utils';
import {
  type IFieldInfo,
  type ISectionItem,
} from 'uplisting-frontend/services/occasions';
import { type IDateOption } from 'uplisting-frontend/services/repositories/occasion';
import {
  storage,
  StorageKey,
  type ITableColumnSelector,
} from 'uplisting-frontend/utils/storage';
import {
  type ISectionCount,
  type IDashboardBookingData,
  type IDashboardActivityData,
  type IDashboardActionData,
  type IActivityData,
  type IBookingData,
  type IActionData,
  type IDashboardData,
  type IDashboardRecords,
  DashboardDateOptions,
} from 'uplisting-frontend/utils/interfaces';
import { type INavTab } from 'uplisting-frontend/components/ui/nav-tabs';

export interface ITab {
  id: SectionIds;
  name: TableSectionFields;
  title: string;
  counter: number;
}

export type TableSection = 'Bookings' | 'Activity' | 'Action';

const bookingSections = [
  SectionIds.checkIn,
  SectionIds.checkOut,
  SectionIds.stays,
];

const activitySections = [
  SectionIds.newBookings,
  SectionIds.enquiries,
  SectionIds.bookingRequests,
  SectionIds.pendingBookings,
  SectionIds.cancelledBookings,
];

const actionSections = [
  SectionIds.bookingPayment,
  SectionIds.securityDeposits,
  SectionIds.bookingGuestIdentityVerifications,
  SectionIds.bookingRentalAgreements,
];

export default class DashboardIndexController extends BaseController {
  @service('occasions') occasionsService!: Services['occasions'];

  @service('repositories/occasion')
  occasionRepositoryService!: Services['repositories/occasion'];

  queryParams = [
    {
      _activeDayTab: 'active',
    },
    {
      _activeBookingsTab: 'summary',
    },
    {
      _activeActivityTab: 'activity',
    },
    {
      _activeActionTab: 'action',
    },
  ];

  // data from the model
  // bookings counters
  @cached @tracked staysCount!: ISectionCount;
  @cached @tracked checkedInCount!: ISectionCount;
  @cached @tracked checkedOutCount!: ISectionCount;

  // activity counters
  @cached @tracked newBookingsCount!: ISectionCount;
  @cached @tracked enquiriesCount!: ISectionCount;
  @cached @tracked bookingRequestCount!: ISectionCount;
  @cached @tracked pendingBookingCount!: ISectionCount;
  @cached @tracked cancelledBookingsCount!: ISectionCount;

  // action counters
  @cached @tracked bookingPaymentsCount!: ISectionCount;
  @cached @tracked securityDepositsCount!: ISectionCount;
  @cached @tracked bookingGuestIdentityVerificationsCount!: ISectionCount;
  @cached @tracked bookingRentalAgreementsCount!: ISectionCount;

  @cached @tracked occasionBookings!: OccasionModel[];
  @cached @tracked occasionActivity!: OccasionModel[];
  @cached @tracked occasionAction!: OccasionModel[];

  @cached @tracked inputBookingsValue = '';
  @cached @tracked inputActivityValue = '';
  @cached @tracked inputActionValue = '';

  @cached @tracked tableHeightBookings: number | undefined;
  @cached @tracked tableHeightActivity: number | undefined;
  @cached @tracked tableHeightAction: number | undefined;

  @cached @tracked orderBookingsBy: string | undefined;
  @cached @tracked orderActivityBy: string | undefined;
  @cached @tracked orderActionBy: string | undefined;

  @cached @tracked recalculateSelectedItems = false;

  // NOTE: https://github.com/emberjs/ember.js/issues/18715#issuecomment-609415073
  // a fix for the query parameter validation
  @cached @tracked _activeBookingsTab = SectionIds.checkIn;
  @cached @tracked _activeActivityTab = SectionIds.newBookings;
  @cached @tracked _activeActionTab = SectionIds.bookingPayment;
  @cached @tracked _activeDayTab = DashboardDateOptions.today;

  @cached
  get activeBookingsTab(): SectionIds {
    if (bookingSections.includes(this._activeBookingsTab)) {
      return this._activeBookingsTab;
    }

    return SectionIds.checkIn;
  }

  set activeBookingsTab(value: SectionIds) {
    this._activeBookingsTab = bookingSections.includes(this._activeBookingsTab)
      ? value
      : SectionIds.checkIn;
  }

  @cached
  get activeActivityTab(): SectionIds {
    if (activitySections.includes(this._activeActivityTab)) {
      return this._activeActivityTab;
    }

    return SectionIds.newBookings;
  }

  set activeActivityTab(value: SectionIds) {
    this._activeActivityTab = activitySections.includes(this._activeActivityTab)
      ? value
      : SectionIds.newBookings;
  }

  @cached
  get activeActionTab(): SectionIds {
    if (actionSections.includes(this._activeActionTab)) {
      return this._activeActionTab;
    }

    return SectionIds.bookingPayment;
  }

  set activeActionTab(value: SectionIds) {
    this._activeActionTab = actionSections.includes(this._activeActionTab)
      ? value
      : SectionIds.bookingPayment;
  }

  @cached
  get activeDayTab(): DashboardDateOptions {
    if (Object.values(DashboardDateOptions).includes(this._activeDayTab)) {
      return this._activeDayTab;
    }

    return DashboardDateOptions.today;
  }

  set activeDayTab(value: DashboardDateOptions) {
    this._activeDayTab = Object.values(DashboardDateOptions).includes(
      this._activeDayTab,
    )
      ? value
      : DashboardDateOptions.today;
  }

  @cached
  get activeDayTabFormatted(): string {
    const { activeDayTab } = this;

    if (activeDayTab === DashboardDateOptions.next_7_days) {
      return 'next_7_days';
    }

    return activeDayTab;
  }

  @cached
  get navTabs(): INavTab<string>[] {
    return [
      {
        id: 'occasions-bookings-section',
        name: this.intl.t('dashboard_index.nav_tabs.summary'),
      },
      {
        id: 'occasions-action-section',
        name: this.intl.t('dashboard_index.nav_tabs.action_required'),
      },
      {
        id: 'occasions-activity-section',
        name: this.intl.t('dashboard_index.nav_tabs.new_activity'),
      },
    ];
  }

  // do not marking those tables as cached, so their values will be recomputed, as when changing the sorting - new table is being rendered
  get tableBookings(): HTMLTableElement {
    return document.getElementById('occasions-bookings') as HTMLTableElement;
  }

  get tableActivity(): HTMLTableElement {
    return document.getElementById('occasions-activity') as HTMLTableElement;
  }

  get tableAction(): HTMLTableElement {
    return document.getElementById('occasions-action') as HTMLTableElement;
  }

  @cached
  get selectedCheckInColumns(): OccasionAttribute[] {
    // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
    this.recalculateSelectedItems;

    return this.getSelectedTableColumns(
      StorageKey.DASHBOARD_CHECK_IN,
      SectionIds.checkIn,
    );
  }

  @cached
  get selectedCheckOutColumns(): OccasionAttribute[] {
    // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
    this.recalculateSelectedItems;

    return this.getSelectedTableColumns(
      StorageKey.DASHBOARD_CHECK_OUT,
      SectionIds.checkOut,
    );
  }

  @cached
  get selectedStaysColumns(): OccasionAttribute[] {
    // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
    this.recalculateSelectedItems;

    return this.getSelectedTableColumns(
      StorageKey.DASHBOARD_STAYS,
      SectionIds.stays,
    );
  }

  @cached
  get selectedNewBookingsColumns(): OccasionAttribute[] {
    // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
    this.recalculateSelectedItems;

    return this.getSelectedTableColumns(
      StorageKey.DASHBOARD_NEW_BOOKINGS,
      SectionIds.newBookings,
    );
  }

  @cached
  get selectedEnquiriesColumns(): OccasionAttribute[] {
    // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
    this.recalculateSelectedItems;

    return this.getSelectedTableColumns(
      StorageKey.DASHBOARD_ENQUIRIES,
      SectionIds.enquiries,
    );
  }

  @cached
  get selectedBookingRequestsColumns(): OccasionAttribute[] {
    // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
    this.recalculateSelectedItems;

    return this.getSelectedTableColumns(
      StorageKey.DASHBOARD_BOOKING_REQUESTS,
      SectionIds.bookingRequests,
    );
  }

  @cached
  get selectedPendingBookingsColumns(): OccasionAttribute[] {
    // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
    this.recalculateSelectedItems;

    return this.getSelectedTableColumns(
      StorageKey.DASHBOARD_PENDING,
      SectionIds.pendingBookings,
    );
  }

  @cached
  get selectedCancelledBookingsColumns(): OccasionAttribute[] {
    // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
    this.recalculateSelectedItems;

    return this.getSelectedTableColumns(
      StorageKey.DASHBOARD_CANCELLED,
      SectionIds.cancelledBookings,
    );
  }

  @cached
  get selectedBookingPaymentsColumns(): OccasionAttribute[] {
    // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
    this.recalculateSelectedItems;

    return this.getSelectedTableColumns(
      StorageKey.DASHBOARD_BOOKING_PAYMENT,
      SectionIds.bookingPayment,
    );
  }

  @cached
  get selectedSecurityDepositsColumns(): OccasionAttribute[] {
    // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
    this.recalculateSelectedItems;

    return this.getSelectedTableColumns(
      StorageKey.DASHBOARD_SECURITY_DEPOSITS,
      SectionIds.securityDeposits,
    );
  }

  @cached
  get selectedBookingGuestIdentityVerificationColumns(): OccasionAttribute[] {
    // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
    this.recalculateSelectedItems;

    return this.getSelectedTableColumns(
      StorageKey.DASHBOARD_BOOKING_GUEST_IDENTITY_VERIFICATION,
      SectionIds.bookingGuestIdentityVerifications,
    );
  }

  @cached
  get selectedBookingRentalAgreementColumns(): OccasionAttribute[] {
    // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
    this.recalculateSelectedItems;

    return this.getSelectedTableColumns(
      StorageKey.DASHBOARD_BOOKING_RENTAL_AGREEMENT,
      SectionIds.bookingRentalAgreements,
    );
  }

  /**
   * @description When getting data from the local storage for the selected table fields
   * we want to limit and ignore if there are fields stored in the local storage
   * that are not available for certain table
   *
   * @param {StorageKey} key a storage key under which the data is stored
   * @param {SectionIds} section table section id
   *
   * @returns {string[]} a list of table selected columns
   */
  private getSelectedTableColumns(
    key: StorageKey,
    section: SectionIds,
  ): OccasionAttribute[] {
    const columns = storage.local.get<ITableColumnSelector>(key)
      ?.shownColumns as OccasionAttribute[];

    const { fieldsInfo } = this.occasionsService;

    return columns.filter((column) => {
      const info = fieldsInfo.find((item) => item.property === column);

      return info?.availableTables.includes(section);
    });
  }

  @cached
  get selectedBookingsColumns(): OccasionAttribute[] {
    switch (this.activeBookingsTab) {
      case SectionIds.checkOut:
        return this.selectedCheckOutColumns;
      case SectionIds.checkIn:
        return this.selectedCheckInColumns;
      default:
        return this.selectedStaysColumns;
    }
  }

  set selectedBookingsColumns(value: OccasionAttribute[]) {
    this.setColumnsData(this.selectedBookingsColumnsStorageKey, value);
  }

  @cached
  get selectedBookingsColumnsStorageKey(): StorageKey {
    switch (this.activeBookingsTab) {
      case SectionIds.checkOut:
        return StorageKey.DASHBOARD_CHECK_OUT;
      case SectionIds.checkIn:
        return StorageKey.DASHBOARD_CHECK_IN;
      default:
        return StorageKey.DASHBOARD_STAYS;
    }
  }

  @cached
  get selectedActivityColumns(): OccasionAttribute[] {
    switch (this.activeActivityTab) {
      case SectionIds.newBookings:
        return this.selectedNewBookingsColumns;
      case SectionIds.enquiries:
        return this.selectedEnquiriesColumns;
      case SectionIds.bookingRequests:
        return this.selectedBookingRequestsColumns;
      case SectionIds.pendingBookings:
        return this.selectedPendingBookingsColumns;
      default:
        return this.selectedCancelledBookingsColumns;
    }
  }

  set selectedActivityColumns(value: OccasionAttribute[]) {
    this.setColumnsData(this.selectedActivityColumnsStorageKey, value);
  }

  @cached
  get selectedActionColumnsStorageKey(): StorageKey {
    switch (this.activeActionTab) {
      case SectionIds.bookingPayment:
        return StorageKey.DASHBOARD_BOOKING_PAYMENT;
      case SectionIds.bookingGuestIdentityVerifications:
        return StorageKey.DASHBOARD_BOOKING_GUEST_IDENTITY_VERIFICATION;
      case SectionIds.bookingRentalAgreements:
        return StorageKey.DASHBOARD_BOOKING_RENTAL_AGREEMENT;
      default:
        return StorageKey.DASHBOARD_SECURITY_DEPOSITS;
    }
  }

  @cached
  get selectedActionColumns(): OccasionAttribute[] {
    switch (this.activeActionTab) {
      case SectionIds.bookingPayment:
        return this.selectedBookingPaymentsColumns;
      case SectionIds.bookingGuestIdentityVerifications:
        return this.selectedBookingGuestIdentityVerificationColumns;
      case SectionIds.bookingRentalAgreements:
        return this.selectedBookingRentalAgreementColumns;
      default:
        return this.selectedSecurityDepositsColumns;
    }
  }

  set selectedActionColumns(value: OccasionAttribute[]) {
    this.setColumnsData(this.selectedActionColumnsStorageKey, value);
  }

  @cached
  get selectedActivityColumnsStorageKey(): StorageKey {
    switch (this.activeActivityTab) {
      case SectionIds.newBookings:
        return StorageKey.DASHBOARD_NEW_BOOKINGS;
      case SectionIds.enquiries:
        return StorageKey.DASHBOARD_ENQUIRIES;
      case SectionIds.bookingRequests:
        return StorageKey.DASHBOARD_BOOKING_REQUESTS;
      case SectionIds.pendingBookings:
        return StorageKey.DASHBOARD_PENDING;
      default:
        return StorageKey.DASHBOARD_CANCELLED;
    }
  }

  @cached
  get summaryText(): string {
    const { intl, activeDayTab } = this;

    switch (activeDayTab) {
      case DashboardDateOptions.yesterday:
        return intl.t('dashboard_index.summary.yesterday');
      case DashboardDateOptions.tomorrow:
        return intl.t('dashboard_index.summary.tomorrow');
      case DashboardDateOptions.next_7_days:
        return intl.t('dashboard_index.summary.next_7_days');
      case DashboardDateOptions.today:
        return intl.t('dashboard_index.summary.today');
    }
  }

  @cached
  get bookingTabs(): ITab[] {
    const { intl } = this;

    return [
      {
        id: SectionIds.checkIn,
        name: TableSectionFields.checkIn,
        title: intl.t('dashboard_index.booking_tabs.check_in'),
        counter: this.checkedInCount.total as number,
      },
      {
        id: SectionIds.checkOut,
        name: TableSectionFields.checkOut,
        title: intl.t('dashboard_index.booking_tabs.check_out'),
        counter: this.checkedOutCount.total as number,
      },
      {
        id: SectionIds.stays,
        name: TableSectionFields.stays,
        title: intl.t('dashboard_index.booking_tabs.stays'),
        counter: this.staysCount.total as number,
      },
    ];
  }

  @cached
  get activityTabs(): ITab[] {
    const { intl } = this;

    return [
      {
        id: SectionIds.newBookings,
        name: TableSectionFields.newBookings,
        title: intl.t('dashboard_index.activity_tabs.new_bookings'),
        counter: this.newBookingsCount.total as number,
      },
      {
        id: SectionIds.enquiries,
        name: TableSectionFields.enquiries,
        title: intl.t('dashboard_index.activity_tabs.enquiries'),
        counter: this.enquiriesCount.total as number,
      },
      {
        id: SectionIds.bookingRequests,
        name: TableSectionFields.bookingRequests,
        title: intl.t('dashboard_index.activity_tabs.booking_requests'),
        counter: this.bookingRequestCount.total as number,
      },
      {
        id: SectionIds.pendingBookings,
        name: TableSectionFields.pendingBookings,
        title: intl.t('dashboard_index.activity_tabs.pending_bookings'),
        counter: this.pendingBookingCount.total as number,
      },
      {
        id: SectionIds.cancelledBookings,
        name: TableSectionFields.cancelledBookings,
        title: intl.t('dashboard_index.activity_tabs.cancelled_bookings'),
        counter: this.cancelledBookingsCount.total as number,
      },
    ];
  }

  @cached
  get actionTabs(): ITab[] {
    const { intl } = this;

    return [
      {
        id: SectionIds.bookingPayment,
        name: TableSectionFields.bookingPayment,
        title: intl.t('dashboard_index.action_tabs.booking_payments'),
        counter: this.bookingPaymentsCount.total as number,
      },
      {
        id: SectionIds.securityDeposits,
        name: TableSectionFields.securityDeposits,
        title: intl.t('dashboard_index.action_tabs.security_deposits'),
        counter: this.securityDepositsCount.total as number,
      },
      {
        id: SectionIds.bookingGuestIdentityVerifications,
        name: TableSectionFields.bookingGuestIdentityVerifications,
        title: intl.t(
          'dashboard_index.action_tabs.booking_guest_identity_verifications',
        ),
        counter: this.bookingGuestIdentityVerificationsCount.total as number,
      },
      {
        id: SectionIds.bookingRentalAgreements,
        name: TableSectionFields.bookingRentalAgreements,
        title: intl.t('dashboard_index.action_tabs.booking_rental_agreements'),
        counter: this.bookingRentalAgreementsCount.total as number,
      },
    ];
  }

  @cached
  get selectedBookingFieldInfo(): IFieldInfo[] {
    return this.sortAndFilterFieldInfo(
      'selectedBookingsColumns',
      this.activeBookingsTab,
    );
  }

  @cached
  get selectedActivityFieldInfo(): IFieldInfo[] {
    return this.sortAndFilterFieldInfo(
      'selectedActivityColumns',
      this.activeActivityTab,
    );
  }

  @cached
  get selectedActionFieldInfo(): IFieldInfo[] {
    return this.sortAndFilterFieldInfo(
      'selectedActionColumns',
      this.activeActionTab,
    );
  }

  @cached
  get searchBookingFields(): string[] {
    return this.selectedBookingFieldInfo.map((item) => item.property);
  }

  @cached
  get searchActivityFields(): string[] {
    return this.selectedActivityFieldInfo.map((item) => item.property);
  }

  @cached
  get searchActionFields(): string[] {
    return this.selectedActionFieldInfo.map((item) => item.property);
  }

  @cached
  get filteredBookingItems(): OccasionModel[] {
    return searchOptions<OccasionModel>(
      this.occasionBookings,
      this.inputBookingsValue,
      this.searchBookingFields,
    );
  }

  @cached
  get filteredActivityItems(): OccasionModel[] {
    return searchOptions<OccasionModel>(
      this.occasionActivity,
      this.inputActivityValue,
      this.searchActivityFields,
    );
  }

  @cached
  get filteredActionItems(): OccasionModel[] {
    return searchOptions<OccasionModel>(
      this.occasionAction,
      this.inputActionValue,
      this.searchActionFields,
    );
  }

  @cached
  get noDataBookingsStyle(): string {
    if (this.tableHeightBookings) {
      return `height: ${this.tableHeightBookings}px`;
    }

    return '';
  }

  @cached
  get noDataActivityStyle(): string {
    if (this.tableHeightActivity) {
      return `height: ${this.tableHeightActivity}px`;
    }

    return '';
  }

  @cached
  get noDataActionStyle(): string {
    if (this.tableHeightAction) {
      return `height: ${this.tableHeightAction}px`;
    }

    return '';
  }

  @action
  async handleTabChange(section: TableSection, tab: SectionIds): Promise<void> {
    const field = `active${section}Tab`;

    if (this[field] === tab) {
      return;
    }

    this[field] = tab;
    this.resetSearch();

    await this.fetchOccasions(false, section);
  }

  @action
  isActiveTab(section: TableSection, tab: SectionIds): boolean {
    return this[`active${section}Tab`] === tab;
  }

  @action
  async handleDayTabChange(item: IDateOption): Promise<void> {
    if (this.activeDayTab === item.id) {
      return;
    }

    this.activeDayTab = item.id;
    this.resetSearch();

    await this.fetchOccasions(true);
  }

  @action
  handleNavTabChange(item: INavTab<string>): void {
    const section = document.getElementById(item.id);

    section?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }

  @action
  handleInputSearch(section: TableSection, value: string): void {
    this[`input${section}Value`] = value;
  }

  @action
  handleColumnsApply(section: TableSection, items: OccasionAttribute[]): void {
    this[`selected${section}Columns`] = items;

    const table = this[`table${section}`];

    handleTableScrollSize(this, table);
  }

  /**
   * @description get name of the field to be sent to the BE for the records sorting
   *
   * @returns {String} field name in the BE formatted way
   */
  @action
  getSortMappingsKey(section: TableSection, field: string): string | undefined {
    const activeSection = this[`active${section}Tab`];

    let sortMappingsKey;

    switch (activeSection) {
      case SectionIds.checkIn:
      case SectionIds.checkOut:
      case SectionIds.stays:
      case SectionIds.newBookings:
      case SectionIds.pendingBookings:
      case SectionIds.cancelledBookings:
      case SectionIds.bookingPayment:
      case SectionIds.securityDeposits:
      case SectionIds.bookingGuestIdentityVerifications:
      case SectionIds.bookingRentalAgreements:
        sortMappingsKey = OccasionTypes.bookings;
        break;

      case SectionIds.enquiries:
        sortMappingsKey = OccasionTypes.enquiries;
        break;

      case SectionIds.bookingRequests:
        sortMappingsKey = OccasionTypes.bookingRequests;
        break;
    }

    return sortFieldsMapping[sortMappingsKey][field];
  }

  @action
  async handleChangeOrder(section: TableSection, field: string): Promise<void> {
    const sortField = this.getSortMappingsKey(section, field);

    if (!sortField) {
      return;
    }

    this[`tableHeight${section}`] = this[`table${section}`].clientHeight;

    if (this[`order${section}By`] === sortField) {
      this[`order${section}By`] = `-${sortField}`;
    } else if (this[`order${section}By`] === `-${sortField}`) {
      this[`order${section}By`] = undefined;
    } else {
      this[`order${section}By`] = sortField;
    }

    const method = this.getFetchRecordsMethod(section);

    const data = await this[method](
      this.activeDayTabFormatted,
      false,
      this[`order${section}By`],
    );

    Object.assign(this, data);

    this[`tableHeight${section}`] = undefined;
  }

  /**
   * @description compute sorting class to show correct caret on the header item
   */
  @action
  computeSortingClass(section: TableSection, field: string): string {
    const sortField = this.getSortMappingsKey(section, field);

    if (!sortField) {
      return '';
    }

    if (this[`order${section}By`] === sortField) {
      return 'sorting-asc';
    } else if (this[`order${section}By`] === `-${sortField}`) {
      return 'sorting-desc';
    }

    return '';
  }

  /**
   * @description compute all available fields for the certain table
   *
   * @param {SectionIds} section a section table name
   *
   * @returns {ISectionItem[]} list of all sections with filtered items
   */
  @action
  computeAvailableFields(section: SectionIds): ISectionItem[] {
    const { fieldsInfo } = this.occasionsService;

    return this.occasionsService.dashboardColumns.map((column) => ({
      ...column,
      items: column.items.filter((item) => {
        const fieldInfo = fieldsInfo.find((info) => info.property === item.id);

        if (!fieldInfo) {
          return false;
        }

        return fieldInfo.availableTables.includes(section);
      }),
    }));
  }

  @action
  async handleSetTimeZone(timezone: string): Promise<void> {
    this.occasionRepositoryService.timezone = timezone;

    await this.fetchOccasions(true);
  }

  handleInsert = modifier((table: HTMLTableElement) => {
    handleTableScrollSize(this, table);
  });

  /**
   * @description fetch data for the bookings or activity or both tables, based on the section passed(or not)
   *
   * @param {Boolean} fetchMeta defines if meta information(data for the counters) should be fetched
   * @param {TableSection} section? section for which to fetch occasion data
   *
   * @returns {Promise<void>}
   */
  public async fetchOccasions(
    fetchMeta: boolean,
    section?: TableSection,
  ): Promise<void> {
    if (fetchMeta) {
      this.unsetCounters();
    }

    if (!this.occasionRepositoryService.timezone) {
      this.occasionRepositoryService.timezone = this.defaultTimezone;
    }

    const method = this.getFetchRecordsMethod(section);

    const data = await this[method](this.activeDayTabFormatted, fetchMeta);

    Object.assign(this, data);
  }

  private setColumnsData(key: StorageKey, value: OccasionAttribute[]): void {
    const data: ITableColumnSelector = {
      shownColumns: value,
    };

    storage.local.set<ITableColumnSelector>(key, data);

    this.recalculateSelectedItems = !this.recalculateSelectedItems;
  }

  private getFetchRecordsMethod(section?: TableSection): string {
    if (section === 'Bookings') {
      return 'fetchBookings';
    } else if (section === 'Activity') {
      return 'fetchActivity';
    } else if (section === 'Action') {
      return 'fetchActions';
    } else {
      return 'fetchAllOccasions';
    }
  }

  /**
   * @description fetch data for the bookings table
   *
   * @param {String} dateQuery date range query
   * @param {Boolean} fetchMeta defines if meta information(data for the counters) should be fetched
   *
   * @returns {Promise<IDashboardBookingData | IBookingData>}
   */
  private async fetchBookings(
    dateQuery: string,
    fetchMeta: boolean,
    sort?: string,
  ): Promise<IDashboardBookingData | IBookingData> {
    this.unsetData('occasionBookings');

    return this.occasionRepositoryService.fetchBookings(
      dateQuery,
      this.activeBookingsTab,
      fetchMeta,
      sort,
    );
  }

  /**
   * @description fetch data for the activity table
   *
   * @param {String} dateQuery date range query
   * @param {Boolean} fetchMeta defines if meta information(data for the counters) should be fetched
   *
   * @returns {Promise<IDashboardActivityData | IActivityData>}
   */
  private async fetchActivity(
    dateQuery: string,
    fetchMeta: boolean,
    sort?: string,
  ): Promise<IDashboardActivityData | IActivityData> {
    this.unsetData('occasionActivity');

    return this.occasionRepositoryService.fetchBookingActivity(
      dateQuery,
      this.activeActivityTab,
      fetchMeta,
      sort,
    );
  }

  /**
   * @description fetch data for the action table
   *
   * @param {String} dateQuery date range query
   * @param {Boolean} fetchMeta defines if meta information(data for the counters) should be fetched
   *
   * @returns {Promise<IDashboardActionData | IActionData>}
   */
  private async fetchActions(
    dateQuery: string,
    fetchMeta: boolean,
    sort?: string,
  ): Promise<IDashboardActionData | IActionData> {
    this.unsetData('occasionAction');

    return this.occasionRepositoryService.fetchActions(
      dateQuery,
      this.activeActionTab,
      fetchMeta,
      sort,
    );
  }

  /**
   * @description fetch data for the bookings and activity tables
   *
   * @param {String} dateQuery date range query
   * @param {Boolean} fetchMeta defines if meta information(data for the counters) should be fetched
   *
   * @returns {Promise<IDashboardData | IDashboardRecords>}
   */
  private async fetchAllOccasions(
    dateQuery: string,
    fetchMeta: boolean,
  ): Promise<IDashboardData | IDashboardRecords> {
    this.unsetData('occasionBookings');
    this.unsetData('occasionActivity');
    this.unsetData('occasionAction');

    return this.occasionRepositoryService.fetchDashboardData(
      dateQuery,
      {
        bookingSection: this.activeBookingsTab,
        activitySection: this.activeActivityTab,
        actionSection: this.activeActionTab,
      },
      fetchMeta,
    );
  }

  /**
   * @description unset and unload data for the `field` property
   *
   * @param {String} field field name under which records are stored in the controller
   */
  private unsetData(field: string): void {
    if (!this[field]) {
      return;
    }

    this[field] = [];
    this.occasionRepositoryService.unloadRecords(this[field]);
  }

  private unsetCounters(): void {
    this.checkedInCount = {
      total: '-',
      count: '-',
    };

    this.checkedOutCount = {
      total: '-',
      count: '-',
    };

    this.staysCount = {
      total: '-',
      count: '-',
    };

    this.newBookingsCount = {
      total: '-',
    };

    this.enquiriesCount = {
      total: '-',
    };

    this.bookingRequestCount = {
      total: '-',
    };

    this.pendingBookingCount = {
      total: '-',
    };

    this.cancelledBookingsCount = {
      total: '-',
    };

    this.bookingPaymentsCount = {
      total: '-',
    };

    this.securityDepositsCount = {
      total: '-',
    };

    this.bookingGuestIdentityVerificationsCount = {
      total: '-',
    };

    this.bookingRentalAgreementsCount = {
      total: '-',
    };
  }

  /**
   * @description get selected table columns, and order them according to our priority definition
   *
   * @param {string} field field name under which to get configuration from the storage
   *
   * @returns {IFieldInfo[]} columns definition ordered by our priority list
   */
  private sortAndFilterFieldInfo(
    field: string,
    section: SectionIds,
  ): IFieldInfo[] {
    const selectedColumns = this[field];

    return this.occasionsService.fieldsInfo
      .filter(
        (item) =>
          item.availableTables.includes(section) &&
          selectedColumns.includes(item.property),
      )
      .sort(
        (x, y) =>
          selectedColumns.indexOf(x.title) - selectedColumns.indexOf(y.title),
      );
  }

  public resetSearch(): void {
    this.handleInputSearch('Activity', '');
    this.handleInputSearch('Bookings', '');
    this.handleInputSearch('Action', '');
  }

  public resetQueryParams(): void {
    this.activeBookingsTab = SectionIds.checkIn;
    this.activeActivityTab = SectionIds.newBookings;
    this.activeActionTab = SectionIds.bookingPayment;
    this.activeDayTab = DashboardDateOptions.today;
  }
}
