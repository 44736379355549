import { helper } from '@ember/component/helper';
import UpsellModel from 'uplisting-frontend/models/upsell';
import AdditionalBookingChargeModel from 'uplisting-frontend/models/additional-booking-charge';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import { TaxRateType } from 'uplisting-frontend/models/schemas';

type ModelWithTax = UpsellModel | AdditionalBookingChargeModel;

function getPriceWithTax([item, field]: [
  GenericChangeset<ModelWithTax> | ModelWithTax,
  string,
]): number {
  const price = Number(item[field || 'price']);
  const { tax } = item;

  if (!tax?.id || tax.rateType === TaxRateType.inclusive) {
    return price;
  }

  const taxAmount = (price * tax.rate) / 100;

  return price + taxAmount;
}

export default helper(getPriceWithTax);
