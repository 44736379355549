import { action } from '@ember/object';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, service } from '@ember/service';
import sortBy from 'lodash-es/sortBy';
import CustomPropertyAttributeModel from 'uplisting-frontend/models/custom-property-attribute';
import BaseController from 'uplisting-frontend/pods/base/controller';

export interface IAutomatePropertyAttributesTableColSizes {
  tagCode: number;
  description: number;
  edit: number;
}

export default class AutomatePropertyAttributesController extends BaseController {
  @service('repositories/custom-property-attribute')
  customPropertyAttributeRepository!: Services['repositories/custom-property-attribute'];

  colSizes: IAutomatePropertyAttributesTableColSizes = {
    tagCode: 5,
    description: 7,
    edit: 3,
  };

  @cached
  get propertyAttributesSorted(): CustomPropertyAttributeModel[] {
    return sortBy(this.customPropertyAttributeRepository.peekAll(), 'order');
  }

  @cached
  get isCreateNewDisabled(): boolean {
    return this.propertyAttributesSorted.some((model) => !model.isPersisted);
  }

  @action
  handleCreatePropertyAttribute(): void {
    this.customPropertyAttributeRepository.createRecord();
  }
}
