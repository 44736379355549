import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"empty-state\" data-test-empty-state>\n  <div local-class=\"empty-state-text\">{{@text}}</div>\n\n  {{#if (has-block)}}\n    <div local-class=\"empty-state-block\">\n      {{yield}}\n    </div>\n  {{/if}}\n</div>\n", {"contents":"<div local-class=\"empty-state\" data-test-empty-state>\n  <div local-class=\"empty-state-text\">{{@text}}</div>\n\n  {{#if (has-block)}}\n    <div local-class=\"empty-state-block\">\n      {{yield}}\n    </div>\n  {{/if}}\n</div>\n","moduleName":"uplisting-frontend/components/ui/empty-state/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/empty-state/index.hbs"}});
import templateOnly from '@ember/component/template-only';

interface IArgs {
  text: string;
}

interface EmptyStateSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    default?: [];
  };
}

const UiEmptyStateComponent = templateOnly<EmptyStateSignature>();

export default UiEmptyStateComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::EmptyState': typeof UiEmptyStateComponent;
  }
}
