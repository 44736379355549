import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{@text}}\n", {"contents":"{{@text}}\n","moduleName":"uplisting-frontend/components/ui/power-select/trigger/empty/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/power-select/trigger/empty/index.hbs"}});
import templateOnly from '@ember/component/template-only';

interface IArgs {
  text: string;
}

interface PowerSelectTriggerEmptySignature {
  Element: null;

  Args: IArgs;
}

const UiPowerSelectTriggerEmptyComponent =
  templateOnly<PowerSelectTriggerEmptySignature>();

export default UiPowerSelectTriggerEmptyComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ui/power-select/trigger/empty': typeof UiPowerSelectTriggerEmptyComponent;
    'Ui::PowerSelect::Trigger::Empty': typeof UiPowerSelectTriggerEmptyComponent;
  }
}
