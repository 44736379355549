import type Transition from '@ember/routing/transition';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';

export default class RegisterBaseRoute extends ProtectedRoute {
  async beforeModel(transition: Transition): Promise<void> {
    await super.beforeModel(transition);

    const { onboardRoute } = this.currentUser;

    if (onboardRoute && transition.to?.name !== onboardRoute) {
      await this.router.transitionTo(onboardRoute);
    }
  }
}
