import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, service } from '@ember/service';
import BaseController from 'uplisting-frontend/pods/base/controller';
import UserModel from 'uplisting-frontend/models/user';
import ClientModel from 'uplisting-frontend/models/client';
import InvitationModel from 'uplisting-frontend/models/invitation';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import { isSaveDisabled, toggleHasManyValue } from 'uplisting-frontend/utils';

export enum InviteUserStatus {
  newUser, // no user present in our database
  userPresent, // user is present in our database, but is not linked to any client
  hasClient, // user is present and is already linked to some client
}

export default class ClientsClientController extends BaseController {
  @service('repositories/user') userRepository!: Services['repositories/user'];
  @service('repositories/invitation')
  invitationRepository!: Services['repositories/invitation'];

  @cached @tracked inviteUserStatus!: InviteUserStatus;
  @cached @tracked isFetchingUser = false;

  @cached @tracked showInviteUserModal = false;
  @cached @tracked showRevokeInvitationModal = false;
  @cached @tracked showUnlinkUserModal = false;

  @cached @tracked user!: UserModel | undefined;
  @cached @tracked changeset!: GenericChangeset<InvitationModel>;

  @cached @tracked userToUnlink: UserModel | undefined;
  @cached @tracked invitationToRevoke: InvitationModel | undefined;

  @cached
  get invitations(): InvitationModel[] {
    return this.client.invitations.filter((invitation) => invitation.id);
  }

  @cached
  get client(): ClientModel {
    return this.model as ClientModel;
  }

  @cached
  get clientUsers(): UserModel[] {
    return this.client.users;
  }

  @cached
  get hasAnyTableData(): boolean {
    return !!this.invitations.length || !!this.clientUsers.length;
  }

  @action
  handleUpdateInviteEmail(email: string): void {
    this.changeset.email = email;
  }

  @action
  async handleInvitationSubmit(): Promise<void> {
    const saveDisabled = await isSaveDisabled(this.changeset);

    if (saveDisabled) {
      return;
    }

    this.isFetchingUser = true;

    const users = await this.userRepository.query({
      email: this.changeset.email,
    });

    if (users.length === 0) {
      this.inviteUserStatus = InviteUserStatus.newUser;
    } else if ((users[0] as UserModel).client) {
      this.inviteUserStatus = InviteUserStatus.hasClient;
    } else {
      this.inviteUserStatus = InviteUserStatus.userPresent;
    }

    this.user = users[0];

    this.isFetchingUser = false;
    this.showInviteUserModal = true;
  }

  @action
  handleOpenInvitationRevokeModal(invitation: InvitationModel): void {
    this.invitationToRevoke = invitation;
    this.showRevokeInvitationModal = true;
  }

  @action
  handleHideInvitationRevokeModal(): void {
    this.invitationToRevoke = undefined;
  }

  @action
  async handleInvitationRevoke(): Promise<void> {
    await this.invitationRepository.destroyRecord(
      this.invitationToRevoke as InvitationModel,
    );
  }

  @action
  handleOpenUserUnlinkModal(user: UserModel): void {
    this.userToUnlink = user;
    this.showUnlinkUserModal = true;
  }

  @action
  handleHideUserUnlinkModal(): void {
    this.userToUnlink = undefined;
  }

  @action
  async handleUserUnlink(): Promise<void> {
    toggleHasManyValue(this.client, 'users', this.userToUnlink);

    await this.client.save();
  }

  @action
  handleInviteUserSubmit(): void {
    this.buildChangeset();
  }

  @action
  handleCloseInviteUserModal(): void {
    this.showInviteUserModal = false;
  }

  public buildChangeset(): void {
    const record = this.invitationRepository.createRecord({
      client: this.client,
      role: 'owner',
    });

    this.changeset = this.invitationRepository.buildChangeset(record);
  }
}
