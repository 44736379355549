import Service, { type Registry as Services, service } from '@ember/service';

export default class TranslationsService extends Service {
  @service intl!: Services['intl'];

  public getChannelNameFor(channel: string): string {
    return this.getTranslationWithDefault(
      `channel.${channel}`,
      'channel.unknown',
    );
  }

  private getTranslationWithDefault(
    translationKey: string,
    defaultKey: string,
  ): string {
    const { intl } = this;

    if (intl.exists(translationKey)) {
      return intl.t(translationKey);
    }

    return intl.t(defaultKey);
  }
}

declare module '@ember/service' {
  interface Registry {
    translations: TranslationsService;
  }
}
