import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"filters-section\" ...attributes data-test-filters-wrapper>\n  <div local-class=\"filters-wrapper\">\n    {{yield to=\"filtersList\"}}\n\n    {{#each this.filters as |filter|}}\n      <Ui::Filters::Filter @filter={{filter}} @onFilterUpdate={{@onFilterUpdate}} />\n    {{/each}}\n\n    {{yield to=\"filtersActions\"}}\n  </div>\n</div>\n", {"contents":"<div local-class=\"filters-section\" ...attributes data-test-filters-wrapper>\n  <div local-class=\"filters-wrapper\">\n    {{yield to=\"filtersList\"}}\n\n    {{#each this.filters as |filter|}}\n      <Ui::Filters::Filter @filter={{filter}} @onFilterUpdate={{@onFilterUpdate}} />\n    {{/each}}\n\n    {{yield to=\"filtersActions\"}}\n  </div>\n</div>\n","moduleName":"uplisting-frontend/components/ui/filters/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/filters/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { type Filter } from 'uplisting-frontend/utils/filters/types';

interface IArgs {
  filters: Filter[];
  onFilterUpdate(filter?: Filter): void;
}

interface FiltersSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    filtersList: [];
    filtersActions: [];
  };
}

export default class UiFiltersComponent extends Component<FiltersSignature> {
  @cached
  get filters(): Filter[] {
    return this.args.filters;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Filters': typeof UiFiltersComponent;
  }
}
