import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"page-header\" data-test-ui-header>\n  <div local-class=\"page-title\">\n    {{#if (has-block \"title\")}}\n      {{yield to=\"title\"}}\n    {{else}}\n      <span local-class=\"title-label\" data-test-ui-header-title>\n        {{this.title}}\n      </span>\n\n      <div id=\"navigation-panel\"></div>\n    {{/if}}\n  </div>\n\n  {{#if this.description}}\n    <div local-class=\"page-description\">\n      <span local-class=\"description-label\" data-test-ui-header-description>\n        {{this.description}}\n\n        <span id=\"page-header-additional-description\"></span>\n      </span>\n    </div>\n  {{/if}}\n\n  <div id=\"page-nav\"></div>\n</div>\n", {"contents":"<div local-class=\"page-header\" data-test-ui-header>\n  <div local-class=\"page-title\">\n    {{#if (has-block \"title\")}}\n      {{yield to=\"title\"}}\n    {{else}}\n      <span local-class=\"title-label\" data-test-ui-header-title>\n        {{this.title}}\n      </span>\n\n      <div id=\"navigation-panel\"></div>\n    {{/if}}\n  </div>\n\n  {{#if this.description}}\n    <div local-class=\"page-description\">\n      <span local-class=\"description-label\" data-test-ui-header-description>\n        {{this.description}}\n\n        <span id=\"page-header-additional-description\"></span>\n      </span>\n    </div>\n  {{/if}}\n\n  <div id=\"page-nav\"></div>\n</div>\n","moduleName":"uplisting-frontend/components/ui/header/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/header/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

interface IArgs {
  title?: string;
  description?: string;
}

interface HeaderSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    title?: [];
  };
}

export default class UiHeaderComponent extends Component<HeaderSignature> {
  @cached
  get title(): string | undefined {
    return this.args.title;
  }

  @cached
  get description(): string | undefined {
    return this.args.description;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Header': typeof UiHeaderComponent;
  }
}
