import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import BaseController from 'uplisting-frontend/pods/base/controller';
import AirbnbOfficialOpportunityModel from 'uplisting-frontend/models/airbnb-official-opportunity';

export default class NotificationsAirbnbOpportunitiesController extends BaseController {
  @cached @tracked showApplyOpportunityModal = false;
  @cached @tracked selectedOpportunity:
    | AirbnbOfficialOpportunityModel
    | undefined;

  @cached
  get airbnbOpportunities(): AirbnbOfficialOpportunityModel[] {
    return this.store.peekAll('airbnb-official-opportunity').slice();
  }

  @action
  handleOpportunityApply(opportunity: AirbnbOfficialOpportunityModel): void {
    this.selectedOpportunity = opportunity;

    this.showApplyOpportunityModal = true;
  }

  @action
  handleCloseApplyOpportunityModal(): void {
    this.showApplyOpportunityModal = false;
  }
}
