import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"loading-template {{if @inPlace \"in-place\" \"global\"}}\" ...attributes></div>\n", {"contents":"<div local-class=\"loading-template {{if @inPlace \"in-place\" \"global\"}}\" ...attributes></div>\n","moduleName":"uplisting-frontend/components/ui/loading-state/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/loading-state/index.hbs"}});
import templateOnly from '@ember/component/template-only';

interface IArgs {
  inPlace?: boolean;
}

interface LoadingStateSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

const UiLoadingStateComponent = templateOnly<LoadingStateSignature>();

export default UiLoadingStateComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::LoadingState': typeof UiLoadingStateComponent;
  }
}
