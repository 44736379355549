import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (and this.price.ownerNetRevenue (has-access-to \"bookings.price.showOwnerNetRevenue\"))}}\n  <table class=\"table-small table-striped\">\n    <tbody>\n      <tr class=\"bold\">\n        <td colspan=\"10\">{{t \"action_bookings_price.price_summary.owner_revenue\"}}</td>\n      </tr>\n\n      <tr>\n        <td colspan=\"8\">{{t \"action_bookings_price.price_summary.owner_net_revenue\"}}</td>\n        <td colspan=\"2\">{{booking-price-format this.price.ownerNetRevenue this.currency}}</td>\n      </tr>\n    </tbody>\n  </table>\n{{/if}}\n", {"contents":"{{#if (and this.price.ownerNetRevenue (has-access-to \"bookings.price.showOwnerNetRevenue\"))}}\n  <table class=\"table-small table-striped\">\n    <tbody>\n      <tr class=\"bold\">\n        <td colspan=\"10\">{{t \"action_bookings_price.price_summary.owner_revenue\"}}</td>\n      </tr>\n\n      <tr>\n        <td colspan=\"8\">{{t \"action_bookings_price.price_summary.owner_net_revenue\"}}</td>\n        <td colspan=\"2\">{{booking-price-format this.price.ownerNetRevenue this.currency}}</td>\n      </tr>\n    </tbody>\n  </table>\n{{/if}}\n","moduleName":"uplisting-frontend/components/ui/booking/price/tables/owner-net-revenue/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/booking/price/tables/owner-net-revenue/index.hbs"}});
import UiBookingPriceTablesBaseComponent from 'uplisting-frontend/components/ui/booking/price/tables/base';

export default class extends UiBookingPriceTablesBaseComponent {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Price::Tables::OwnerNetRevenue': typeof UiBookingPriceTablesBaseComponent;
  }
}
