import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import Alert from 'uplisting-frontend/models/alert';

export default class AlertRepositoryService extends BaseRepositoryService<Alert> {
  recordName = 'alert';
  implementMethods = ['query', 'unloadAll'];
}

declare module '@ember/service' {
  interface Registry {
    'repositories/alert': AlertRepositoryService;
  }
}
