import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"inline-alert {{this.alertClass}}\" ...attributes>\n  {{#if (or (has-block \"title\") @title)}}\n    <strong>\n      {{#if (has-block \"title\")}}\n        {{yield to=\"title\"}}\n      {{else}}\n        {{@title}}\n      {{/if}}\n    </strong>\n\n    <br>\n  {{/if}}\n\n  <span>\n    {{#if (has-block \"description\")}}\n      {{yield to=\"description\"}}\n    {{else}}\n      {{@description}}\n    {{/if}}\n  </span>\n\n  {{#if (has-block \"footer\")}}\n    {{yield to=\"footer\"}}\n  {{/if}}\n</div>\n", {"contents":"<div class=\"inline-alert {{this.alertClass}}\" ...attributes>\n  {{#if (or (has-block \"title\") @title)}}\n    <strong>\n      {{#if (has-block \"title\")}}\n        {{yield to=\"title\"}}\n      {{else}}\n        {{@title}}\n      {{/if}}\n    </strong>\n\n    <br>\n  {{/if}}\n\n  <span>\n    {{#if (has-block \"description\")}}\n      {{yield to=\"description\"}}\n    {{else}}\n      {{@description}}\n    {{/if}}\n  </span>\n\n  {{#if (has-block \"footer\")}}\n    {{yield to=\"footer\"}}\n  {{/if}}\n</div>\n","moduleName":"uplisting-frontend/components/ui/inline-alert/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/inline-alert/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

type AlertType = 'success' | 'danger' | 'warning';

interface IArgs {
  title?: string;
  description?: string;
  type: AlertType;
}

interface InlineAlertSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    title?: [];
    description?: [];
    footer?: [];
  };
}

export default class UiHInlineAlertComponent extends Component<InlineAlertSignature> {
  @cached
  get alertClass():
    | 'inline-alert-warning'
    | 'inline-alert-danger'
    | 'inline-alert-processing'
    | 'inline-alert-success'
    | '' {
    switch (this.args.type) {
      case 'success':
        return 'inline-alert-success';
      case 'danger':
        return 'inline-alert-danger';
      case 'warning':
        return 'inline-alert-warning';
      default:
        return '';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::InlineAlert': typeof UiHInlineAlertComponent;
  }
}
