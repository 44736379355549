export default {
  "insight-card": "_insight-card_130fes",
  "metric-header": "_metric-header_130fes",
  "metric-title": "_metric-title_130fes",
  "metric-body": "_metric-body_130fes",
  "comparison-wrapper": "_comparison-wrapper_130fes",
  "success": "_success_130fes",
  "failure": "_failure_130fes",
  "metric-footer": "_metric-footer_130fes"
};
