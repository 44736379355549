import type BookingModel from 'uplisting-frontend/models/booking';
import type AirbnbOfficialConnectionModel from 'uplisting-frontend/models/airbnb-official-connection';

export enum AlertStatus {
  pending = 'pending',
  resolved = 'resolved',
}

export enum AlertType {
  airbnbUserSuspension = 'airbnb_official_connection_user_suspension',
  airbnbOfficialAlterationRequestCreated = 'airbnb_official_alteration_request_created',
  airbnbOfficialAlterationRequestDeclined = 'airbnb_official_alteration_request_declined',
  airbnbOfficialAlterationRequestAccepted = 'airbnb_official_alteration_request_accepted',
}

export interface IAlertSchema {
  title: string;
  description: string;
  status: AlertStatus;
  type: AlertType;
  createdAt: Date;

  bookings?: BookingModel[];
  airbnbOfficialConnections?: AirbnbOfficialConnectionModel[];
}
