import GuestInvoiceModel from 'uplisting-frontend/models/guest-invoice';

export enum GuestInvoiceAction {
  charge = 'charge',
  markVoid = 'mark_void',
}

export interface IGuestInvoiceActionSchema {
  action: GuestInvoiceAction;

  guestInvoice: GuestInvoiceModel;
}
