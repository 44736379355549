import Model, { attr, belongsTo } from '@ember-data/model';
import {
  type IGuestInvoiceActionSchema,
  GuestInvoiceAction,
} from 'uplisting-frontend/models/schemas';
import GuestInvoiceModel from 'uplisting-frontend/models/guest-invoice';

export default class GuestInvoiceActionModel
  extends Model
  implements IGuestInvoiceActionSchema
{
  @attr('string') action!: GuestInvoiceAction;

  @belongsTo('guest-invoice', { async: false, inverse: null })
  guestInvoice!: GuestInvoiceModel;
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'guest-invoice-action': GuestInvoiceActionModel;
  }
}
