import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"mt-2 inline-alert booking-status-alert {{this.alertClass}}\" data-test-booking-status=\"security-deposit\">\n  <div>\n    <strong>{{t \"action_bookings_summary.security_deposit_status.title\"}}</strong>\n\n    {{t (concat \"action_bookings_summary.security_deposit_status.\" this.status) reason=this.stripeError}}\n  </div>\n\n  {{#if this.icon}}\n    <FaIcon @icon={{this.icon}} @prefix={{this.iconPrefix}} />\n  {{/if}}\n</div>\n", {"contents":"<div class=\"mt-2 inline-alert booking-status-alert {{this.alertClass}}\" data-test-booking-status=\"security-deposit\">\n  <div>\n    <strong>{{t \"action_bookings_summary.security_deposit_status.title\"}}</strong>\n\n    {{t (concat \"action_bookings_summary.security_deposit_status.\" this.status) reason=this.stripeError}}\n  </div>\n\n  {{#if this.icon}}\n    <FaIcon @icon={{this.icon}} @prefix={{this.iconPrefix}} />\n  {{/if}}\n</div>\n","moduleName":"uplisting-frontend/components/ui/booking/status/security-deposit/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/booking/status/security-deposit/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { isAfter } from 'date-fns/isAfter';
import { type Registry as Services, service } from '@ember/service';
import { subDays } from 'date-fns/subDays';
import SecurityDepositModel from 'uplisting-frontend/models/security-deposit';
import { SecurityDepositStatus } from 'uplisting-frontend/models/schemas';

interface IArgs {
  securityDeposit: SecurityDepositModel;
}

export interface BookingStatusSecurityDepositSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

const REMINDERS_SENT_THRESHOLD = 3;

export default class UiBookingStatusSecurityDepositComponent extends Component<BookingStatusSecurityDepositSignature> {
  @service intl!: Services['intl'];

  @cached
  get securityDeposit(): SecurityDepositModel {
    return this.args.securityDeposit;
  }

  @cached
  get status(): SecurityDepositStatus | 'missing_payment_information' {
    const { status, booking } = this.securityDeposit;

    const chargeTime = subDays(booking.checkInDate, 1);
    const needsPaymentInformation =
      status === SecurityDepositStatus.needsPaymentInformation;
    const lessThan24HoursToCheckIn = isAfter(new Date(), chargeTime);
    const remindersSent =
      this.securityDeposit.remindersSent >= REMINDERS_SENT_THRESHOLD;

    if (
      needsPaymentInformation &&
      (lessThan24HoursToCheckIn || remindersSent)
    ) {
      return 'missing_payment_information';
    }

    return status;
  }

  @cached
  get alertClass():
    | 'inline-alert-warning'
    | 'inline-alert-danger'
    | 'inline-alert-processing'
    | 'inline-alert-success' {
    switch (this.status) {
      case SecurityDepositStatus.needsPaymentInformation:
        return 'inline-alert-warning';
      case 'missing_payment_information':
      case SecurityDepositStatus.failing:
      case SecurityDepositStatus.failed:
        return 'inline-alert-danger';
      case SecurityDepositStatus.authorized:
      case SecurityDepositStatus.collected:
        return 'inline-alert-success';
      case SecurityDepositStatus.hasPaymentInformation:
      case SecurityDepositStatus.overridden:
        return 'inline-alert-processing';
    }
  }

  @cached
  get stripeError(): string {
    const key =
      'action_bookings_summary.security_deposit_status.stripe_generic_error';

    return this.securityDeposit.stripeError ?? this.intl.t(key);
  }

  @cached
  get icon(): 'circle-xmark' | 'circle-check' | 'clock' | undefined {
    switch (this.status) {
      case 'missing_payment_information':
      case SecurityDepositStatus.failing:
      case SecurityDepositStatus.failed:
        return 'circle-xmark';
      case SecurityDepositStatus.authorized:
      case SecurityDepositStatus.collected:
      case SecurityDepositStatus.hasPaymentInformation:
      case SecurityDepositStatus.overridden:
        return 'circle-check';
      case SecurityDepositStatus.needsPaymentInformation:
        return 'clock';
    }
  }

  @cached
  get iconPrefix(): 'far' | undefined {
    if (this.icon === 'clock') {
      return 'far';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Status::SecurityDeposit': typeof UiBookingStatusSecurityDepositComponent;
  }
}
