export default {
  "paywall": "_paywall_1gm7iz",
  "multiple": "_multiple_1gm7iz",
  "paywall-title": "_paywall-title_1gm7iz",
  "plan-includes": "_plan-includes_1gm7iz",
  "paywall-body": "_paywall-body_1gm7iz",
  "paywall-body-left": "_paywall-body-left_1gm7iz",
  "paywall-price": "_paywall-price_1gm7iz",
  "price-amount": "_price-amount_1gm7iz",
  "paywall-actions": "_paywall-actions_1gm7iz",
  "paywall-description": "_paywall-description_1gm7iz",
  "paywall-description-after": "_paywall-description-after_1gm7iz",
  "paywall-image": "_paywall-image_1gm7iz"
};
