import type Transition from '@ember/routing/transition';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';
import ConnectCallbackEAirbnbController from 'uplisting-frontend/pods/connect/callback/airbnb/controller';

export default class ConnectCallbackAirbnbRoute extends ProtectedRoute {
  async setupController(
    controller: ConnectCallbackEAirbnbController,
    model,
    transition: Transition,
  ): Promise<void> {
    super.setupController(controller, model, transition);

    await controller.handleCallbackResponse();
  }
}
