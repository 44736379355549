import type Transition from '@ember/routing/transition';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';

export default class AccountLapsedRoute extends ProtectedRoute {
  async beforeModel(transition: Transition): Promise<void> {
    await super.beforeModel(transition);

    if (!this.currentUser.lapsed) {
      window.open('/', '_self');
    }
  }
}
