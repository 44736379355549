export function getGaClientId(): string | undefined {
  const cookies = document.cookie.split('; ');
  const gaCookie = cookies.find((item) => item.startsWith('_ga='));

  if (!gaCookie) {
    return;
  }

  return gaCookie.replace(/_ga=GA\d.\d./, '');
}
