import type Transition from '@ember/routing/transition';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';
import { isRouteForbiddenWhenLapsed } from 'uplisting-frontend/router';
import UserModel from 'uplisting-frontend/models/user';

export default class PermittedRoute extends ProtectedRoute {
  permission!: string;

  async beforeModel(transition: Transition): Promise<void> {
    await super.beforeModel(transition);

    const { session, permission } = this;

    if (!permission) {
      throw new Error('You must define `permission` property');
    }

    const currentUser = session.currentUser as UserModel;

    if (!currentUser.hasAccessTo(this.permission)) {
      transition.abort();

      window.open('/', '_self');
    }

    if (
      currentUser.lapsed &&
      isRouteForbiddenWhenLapsed(transition.targetName)
    ) {
      await transition.abort();
    }
  }
}
