import { type Registry as Services, service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import AccountSettingsController from 'uplisting-frontend/pods/account/settings/controller';

export default class AccountSettingsRoute extends PermittedRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  permission = 'account.settings';

  model() {
    if (!this.currentUser.hasAccessTo('properties.sort')) {
      return [];
    }

    return this.propertyRepository.findAll();
  }

  setupController(
    controller: AccountSettingsController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { properties: model.slice() });
  }
}
