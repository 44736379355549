import Model, { attr, hasMany } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, service } from '@ember/service';
import {
  type IPaymentRuleSchema,
  PaymentRuleChannel,
  PaymentRulePreposition,
  PaymentRuleEvent,
} from 'uplisting-frontend/models/schemas';
import PropertyModel from 'uplisting-frontend/models/property';

export default class PaymentRuleModel
  extends Model
  implements IPaymentRuleSchema
{
  @service intl!: Services['intl'];
  @service translations!: Services['translations'];

  @attr('number') immediateChargePercentage!: number;
  @attr('number') remainingBalanceChargeDays!: number;
  @attr('boolean', { defaultValue: false }) default!: boolean;

  @attr('string', { defaultValue: PaymentRuleChannel.uplisting })
  channel!: PaymentRuleChannel;

  @attr('string', { defaultValue: PaymentRuleEvent.checkIn })
  event!: PaymentRuleEvent;

  @attr('string', { defaultValue: PaymentRulePreposition.before })
  preposition!: PaymentRulePreposition;

  @hasMany('property', { inverse: null, async: false })
  properties!: PropertyModel[];

  @cached
  get isPersisted(): boolean {
    return !!this.id;
  }

  @cached
  get name(): string {
    const { intl, immediateChargePercentage: charge } = this;

    const channelName = this.translations.getChannelNameFor(this.channel);

    let name = `[${channelName}] ${charge}% ${intl.t('automate_payment_rules.name.time_of_booking')}`;

    if (charge !== 100) {
      const { remainingBalanceChargeDays: numberOfDays } = this;
      const days = intl.t('time_formatted.days', { count: numberOfDays });
      const preposition = intl.t(`preposition.${this.preposition}`);
      const event = intl.t(`event.${this.event}`);

      name += ` ${intl.t('automate_payment_rules.name.remainder')} ${days} ${preposition} ${event}`;
    }

    if (this.default) {
      name += ` ${intl.t('automate_payment_rules.name.default')}`;
    }

    return name;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    'payment-rule': PaymentRuleModel;
  }
}
