import { cached, tracked } from '@glimmer/tracking';
import { type Registry as Services, service } from '@ember/service';
import { action } from '@ember/object';
import orderBy from 'lodash-es/orderBy';
import BaseController from 'uplisting-frontend/pods/base/controller';
import UpsellModel from 'uplisting-frontend/models/upsell';
import { FilterId } from 'uplisting-frontend/utils/filters/abstract';
import { type Filter } from 'uplisting-frontend/utils/filters/types';
import { searchOptions } from 'uplisting-frontend/utils';

export default class UpsellsIndexController extends BaseController {
  @service('filters') filtersService!: Services['filters'];

  queryParams = ['propertyIds', 'nickname'];

  @cached @tracked propertyIds: string[] = [];
  @cached @tracked nickname = '';

  @cached @tracked upsells!: UpsellModel[];

  @cached @tracked orderBy: 'asc' | 'desc' | undefined;

  @cached
  get upsellsPresent(): boolean {
    return this.upsells.length !== 0;
  }

  @cached
  get filteredItems(): UpsellModel[] {
    const upsells = this.upsells.filter((upsell) => upsell.id);

    const searched = searchOptions<UpsellModel>(
      upsells,
      this.nickname,
      'nickname',
    );

    const { propertyIds } = this;

    if (!propertyIds.length) {
      return searched;
    }

    return searched.filter((upsell) => {
      const ids = upsell.hasMany('properties').ids();

      return ids.some((id) => propertyIds.includes(id));
    });
  }

  @cached
  get sortedItems(): UpsellModel[] {
    const { orderBy: order, filteredItems } = this;

    if (!order) {
      return filteredItems;
    }

    return orderBy(filteredItems, ['nickname'], [order]);
  }

  @cached
  get sortedItemsEmpty(): boolean {
    return this.sortedItems.length === 0;
  }

  @cached
  get filters(): Filter[] {
    return [
      this.filtersService.createFilterFor(FilterId.property, this.propertyIds),
    ];
  }

  @cached
  get orderClass(): string | undefined {
    const { orderBy } = this;

    if (orderBy === 'asc') {
      return 'sorting-asc';
    } else if (orderBy === 'desc') {
      return 'sorting-desc';
    }
  }

  @action
  handleResetFilters(): void {
    this.filters.forEach((filter) => {
      filter.reset();

      this.handleFilterUpdate(filter);
    });

    this.nickname = '';
  }

  @action
  handleFilterUpdate(filter: Filter): void {
    this.propertyIds = filter.toQuery();
  }

  @action
  handleInput(event: Event): void {
    this.nickname = (event.target as HTMLInputElement).value;
  }

  @action
  handleChangeOrder(): void {
    const { orderBy } = this;

    if (orderBy === undefined) {
      this.orderBy = 'asc';
    } else if (orderBy === 'asc') {
      this.orderBy = 'desc';
    } else if (orderBy === 'desc') {
      this.orderBy = undefined;
    }
  }
}
