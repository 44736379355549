import type Transition from '@ember/routing/transition';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';
import DirectBaseController from 'uplisting-frontend/pods/direct/base-controller';

export default class DirectRoute extends ProtectedRoute {
  setupController(
    controller: DirectBaseController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { configuration: this.modelFor('direct') });
  }
}
