import Model, { attr, hasMany } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, service } from '@ember/service';
import sumBy from 'lodash-es/sumBy';
import uniqBy from 'lodash-es/uniqBy';
import sortBy from 'lodash-es/sortBy';
import numeral from 'numeral';
import TotalRevenueMetricModel from 'uplisting-frontend/models/total-revenue-metric';
import OccupancyMetricModel from 'uplisting-frontend/models/occupancy-metric';
import { type IInsightSchema } from 'uplisting-frontend/models/schemas';

interface IRevenueItem {
  currency: string;
  amount: number;
  booked: number;
}

export interface IRevenueItemFormatted {
  id: string;
  type: string;
  currency: string;
  amount: string;
}

export default class InsightModel extends Model implements IInsightSchema {
  @service intl!: Services['intl'];

  @attr('date') endDate!: Date;
  @attr('date') startDate!: Date;

  @hasMany('occupancy-metric', { async: false, inverse: null })
  occupancyMetrics!: OccupancyMetricModel[];

  @hasMany('total-revenue-metric', { async: false, inverse: null })
  totalRevenueMetrics!: TotalRevenueMetricModel[];

  @cached
  get occupiedItems(): OccupancyMetricModel[] {
    return this.occupancyMetrics.slice();
  }

  @cached
  get occupancy(): string {
    const relevant = this.occupiedItems.filter((item) => item.available !== 0);
    const allAvailableSum = sumBy(relevant, 'available');
    const allBookedSum = sumBy(relevant, 'booked');

    const amount = (100 / allAvailableSum) * allBookedSum;
    const percentage = amount / 100;

    return numeral(percentage).format('0.00%');
  }

  @cached
  get averageLengthOfStay(): string {
    return this.calculateAverageFor('averageLengthOfStay');
  }

  @cached
  get bookedNights(): number {
    return sumBy(this.occupiedItems, 'booked');
  }

  @cached
  get averageLeadTime(): string {
    return this.calculateAverageFor('averageLeadTime');
  }

  @cached
  get ownerNetRevenue(): IRevenueItemFormatted[] {
    const currencies = this.calculateCurrencyFor('ownerNetAmount');

    return currencies.map((metric) =>
      this.getRevenueItem(
        this.intl.t('insight.owner_net'),
        metric.amount,
        metric.currency,
      ),
    );
  }

  @cached
  get totalRevenue(): IRevenueItemFormatted[] {
    const currencies = this.calculateCurrencyFor('amount');

    return currencies.map((metric) =>
      this.getRevenueItem(
        this.intl.t('insight.gross'),
        metric.amount,
        metric.currency,
      ),
    );
  }

  @cached
  get netRevenue(): IRevenueItemFormatted[] {
    const currencies = this.calculateCurrencyFor('netAmount');

    return currencies.map((metric) =>
      this.getRevenueItem(
        this.intl.t('insight.net'),
        metric.amount,
        metric.currency,
      ),
    );
  }

  @cached
  get averageDailyOwnerNetRevenue(): IRevenueItemFormatted[] {
    const currencies = this.calculateCurrencyFor('ownerNetAmount');

    return this.getRevenueItems(this.intl.t('insight.owner'), currencies);
  }

  @cached
  get averageDailyNetRevenue(): IRevenueItemFormatted[] {
    const currencies = this.calculateCurrencyFor('netAmount');

    return this.getRevenueItems(this.intl.t('insight.net'), currencies);
  }

  @cached
  get averageDailyRevenue(): IRevenueItemFormatted[] {
    const currencies = this.calculateCurrencyFor('amount');

    return this.getRevenueItems(this.intl.t('insight.gross'), currencies);
  }

  @cached
  get combinedRevenue(): IRevenueItemFormatted[] {
    const ownerNetRevenue = this.filterRevenueItems(
      'ownerNetRevenue',
      'netRevenue',
    );

    return sortBy(
      [...this.totalRevenue, ...this.netRevenue, ...ownerNetRevenue],
      'currency',
    );
  }

  @cached
  get combinedAverageDailyRevenue(): IRevenueItemFormatted[] {
    const averageDailyOwnerNetRevenue = this.filterRevenueItems(
      'averageDailyOwnerNetRevenue',
      'averageDailyNetRevenue',
    );

    return sortBy(
      [
        ...this.averageDailyRevenue,
        ...this.averageDailyNetRevenue,
        ...averageDailyOwnerNetRevenue,
      ],
      'currency',
    );
  }

  private calculateCurrencyFor(field: string): IRevenueItem[] {
    const currencies = uniqBy(this.totalRevenueMetrics.slice(), 'currency').map(
      (metric) => ({
        currency: metric.currency,
        amount: 0.0,
        booked: 0,
      }),
    );

    this.totalRevenueMetrics.forEach((metric) => {
      const currency = currencies.find(
        (item) => item.currency === metric.currency,
      ) as IRevenueItem;

      currency.amount += metric[field];
      currency.booked += metric.booked;
    });

    return currencies;
  }

  private getRevenueItems(
    type: string,
    currencies: IRevenueItem[],
  ): IRevenueItemFormatted[] {
    return currencies.map((metric) => {
      const average = this.calculateAverage(metric.booked, metric.amount);

      return this.getRevenueItem(type, average, metric.currency);
    });
  }

  private getRevenueItem(
    type: string,
    amount: number,
    currency: string,
  ): IRevenueItemFormatted {
    return {
      id: `${type}-${currency}`,
      type,
      amount: numeral(amount).format('0,0[.]00'),
      currency,
    };
  }

  private calculateAverage(booked: number, amount: number): number {
    return booked > 0 ? amount / booked : 0;
  }

  private filterRevenueItems(
    revenueKey: string,
    rejectKey: string,
  ): IRevenueItemFormatted[] {
    return this[revenueKey].filter((metric) => {
      return !this[rejectKey].some(
        (netRev) =>
          netRev.currency === metric.currency &&
          netRev.amount === metric.amount,
      );
    });
  }

  private calculateAverageFor(field: string): string {
    const relevant = this.occupiedItems.filter((item) => item[field] !== 0);
    const overall = sumBy(relevant, field) / relevant.length;

    return numeral(overall).format('0.00');
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    insight: InsightModel;
  }
}
