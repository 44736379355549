import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ul class=\"column-selector\" data-test-report-filter-options>\n  {{#each this.filter.optionsList as |option|}}\n    <li local-class=\"single-select-filter\" {{on \"click\" (fn this.handleOptionSelect option)}} data-test-multiselect-item>\n      <label class=\"text-truncate\">\n        <input type=\"radio\" checked={{array-includes option this.filter.values}}>\n        <span>\n          {{t (concat this.filter.field \".\" option)}}\n        </span>\n      </label>\n    </li>\n  {{/each}}\n</ul>\n", {"contents":"<ul class=\"column-selector\" data-test-report-filter-options>\n  {{#each this.filter.optionsList as |option|}}\n    <li local-class=\"single-select-filter\" {{on \"click\" (fn this.handleOptionSelect option)}} data-test-multiselect-item>\n      <label class=\"text-truncate\">\n        <input type=\"radio\" checked={{array-includes option this.filter.values}}>\n        <span>\n          {{t (concat this.filter.field \".\" option)}}\n        </span>\n      </label>\n    </li>\n  {{/each}}\n</ul>\n","moduleName":"uplisting-frontend/components/ui/report-filters/options/single-select/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/report-filters/options/single-select/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type OccasionSingleSelectFilter } from 'uplisting-frontend/utils/occasion-filters';

interface IArgs {
  filter: OccasionSingleSelectFilter;
}

interface ReportFiltersOptionsSignature {
  Element: HTMLUListElement;

  Args: IArgs;
}

export default class UiReportFiltersOptionsSingleSelectComponent extends Component<ReportFiltersOptionsSignature> {
  @cached
  get filter(): OccasionSingleSelectFilter {
    return this.args.filter;
  }

  @action
  handleOptionSelect(option: string): void {
    this.filter.updateValues(option);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::ReportFilters::Options::SingleSelect': typeof UiReportFiltersOptionsSingleSelectComponent;
  }
}
