import { type Registry as Services, service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import AutomateMarkupController from 'uplisting-frontend/pods/automate/markup/controller';

export default class AutomateMarkupRoute extends PermittedRoute {
  @service('repositories/channel-commission')
  channelCommissionRepository!: Services['repositories/channel-commission'];

  permission = 'automate.markup';

  model() {
    return this.channelCommissionRepository.findAll();
  }

  setupController(
    controller: AutomateMarkupController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    this.channelCommissionRepository.setDefaultRecords();
  }

  resetController(): void {
    this.channelCommissionRepository.unloadAll();
  }
}
