import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import BillingQuoteModel from 'uplisting-frontend/models/billing-quote';

export default class BillingQuoteRepositoryService extends BaseRepositoryService<BillingQuoteModel> {
  recordName = 'billing-quote';
  implementMethods = ['query', 'unloadAll'];
}

declare module '@ember/service' {
  interface Registry {
    'repositories/billing-quote': BillingQuoteRepositoryService;
  }
}
