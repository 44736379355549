import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"mt-2 inline-alert booking-status-alert {{this.alertClass}}\" data-test-booking-status=\"payment-nested\">\n  <div>\n    <strong>{{t \"action_bookings_summary.payment_status.title\"}}</strong>\n\n    {{#if this.sequence}}\n      {{this.sequence}}\n    {{/if}}\n\n    {{t (concat \"action_bookings_summary.payment_status.\" this.status) reason=this.lastErrorMessage}}\n\n    {{#unless this.payment.isPaid}}\n      {{t \"action_bookings_summary.payment_status.scheduled_for\" time=(date-format this.payment.dueAt \"MMM do, yyyy\")}}\n    {{/unless}}\n  </div>\n\n  {{#if this.icon}}\n    <FaIcon @icon={{this.icon}} @prefix={{this.iconPrefix}} />\n  {{/if}}\n</div>\n", {"contents":"<div class=\"mt-2 inline-alert booking-status-alert {{this.alertClass}}\" data-test-booking-status=\"payment-nested\">\n  <div>\n    <strong>{{t \"action_bookings_summary.payment_status.title\"}}</strong>\n\n    {{#if this.sequence}}\n      {{this.sequence}}\n    {{/if}}\n\n    {{t (concat \"action_bookings_summary.payment_status.\" this.status) reason=this.lastErrorMessage}}\n\n    {{#unless this.payment.isPaid}}\n      {{t \"action_bookings_summary.payment_status.scheduled_for\" time=(date-format this.payment.dueAt \"MMM do, yyyy\")}}\n    {{/unless}}\n  </div>\n\n  {{#if this.icon}}\n    <FaIcon @icon={{this.icon}} @prefix={{this.iconPrefix}} />\n  {{/if}}\n</div>\n","moduleName":"uplisting-frontend/components/ui/payment/status/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/payment/status/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, service } from '@ember/service';
import PaymentModel from 'uplisting-frontend/models/payment';
import { PaymentStatus } from 'uplisting-frontend/models/schemas';

interface IArgs {
  payment: PaymentModel;
  index: number;
  count: number;
}

export interface PaymentStatusSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiPaymentStatusComponent extends Component<PaymentStatusSignature> {
  @service intl!: Services['intl'];

  @cached
  get payment(): PaymentModel {
    return this.args.payment;
  }

  @cached
  get status(): PaymentStatus {
    return this.payment.status;
  }

  @cached
  get alertClass():
    | 'inline-alert-danger'
    | 'inline-alert-processing'
    | 'inline-alert-success' {
    // TODO: define/confirm actual classes for the PaymentStatus.refunded & PaymentStatus.authorized
    // as they are not used/defined in the old app, but status can have that value
    switch (this.status) {
      case PaymentStatus.pending:
        return 'inline-alert-processing';
      case PaymentStatus.failing:
      case PaymentStatus.failed:
        return 'inline-alert-danger';
      case PaymentStatus.charged:
      case PaymentStatus.markedAsPaid:
      case PaymentStatus.refunded:
      case PaymentStatus.authorized:
        return 'inline-alert-success';
    }
  }

  @cached
  get icon(): 'circle-xmark' | 'circle-check' | 'clock' | undefined {
    switch (this.status) {
      case PaymentStatus.pending:
        return 'clock';
      case PaymentStatus.failing:
      case PaymentStatus.failed:
        return 'circle-xmark';
      case PaymentStatus.charged:
      case PaymentStatus.markedAsPaid:
      case PaymentStatus.refunded:
      case PaymentStatus.authorized:
        return 'circle-check';
    }
  }

  @cached
  get iconPrefix(): 'far' | undefined {
    if (this.icon === 'clock') {
      return 'far';
    }
  }

  @cached
  get sequence(): string {
    if (this.args.count === 1) {
      return '';
    }

    return 'I'.repeat(this.args.index + 1);
  }

  @cached
  get lastErrorMessage(): string {
    return (
      this.payment.lastErrorMessage ??
      this.intl.t('action_bookings_summary.payment_status.default_reason')
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Payment::Status': typeof UiPaymentStatusComponent;
  }
}
