import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"numerical-filter\" data-test-report-numerical-type-selector>\n  <PowerSelect\n    @triggerId={{this.filter.field}}\n    @options={{this.filter.types}}\n    @selected={{this.filter.type}}\n    @placeholder={{t (concat this.filter.field \".\" this.filter.type)}}\n    @dropdownClass=\"power-select-nested\"\n    @onChange={{this.handleTypeChange}}\n  as |type|>\n    {{t (concat this.filter.field \".\" type)}}\n  </PowerSelect>\n\n  {{!-- template-lint-disable require-input-label --}}\n  <input class=\"ember-power-select-trigger\" type=\"number\" value={{this.value}} {{on \"input\" this.handleInputChange}} data-test-report-numerical-input>\n</div>\n", {"contents":"<div local-class=\"numerical-filter\" data-test-report-numerical-type-selector>\n  <PowerSelect\n    @triggerId={{this.filter.field}}\n    @options={{this.filter.types}}\n    @selected={{this.filter.type}}\n    @placeholder={{t (concat this.filter.field \".\" this.filter.type)}}\n    @dropdownClass=\"power-select-nested\"\n    @onChange={{this.handleTypeChange}}\n  as |type|>\n    {{t (concat this.filter.field \".\" type)}}\n  </PowerSelect>\n\n  {{!-- template-lint-disable require-input-label --}}\n  <input class=\"ember-power-select-trigger\" type=\"number\" value={{this.value}} {{on \"input\" this.handleInputChange}} data-test-report-numerical-input>\n</div>\n","moduleName":"uplisting-frontend/components/ui/report-filters/options/numerical/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/report-filters/options/numerical/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import { OccasionFilterType } from 'uplisting-frontend/models/schemas';
import { type OccasionNumericalFilter } from 'uplisting-frontend/utils/occasion-filters';

interface IArgs {
  filter: OccasionNumericalFilter;
}

interface ReportFiltersOptionsSignature {
  Element: HTMLUListElement;

  Args: IArgs;
}

export default class UiReportFiltersOptionsNumericalComponent extends Component<ReportFiltersOptionsSignature> {
  @cached
  get filter(): OccasionNumericalFilter {
    return this.args.filter;
  }

  @cached
  get value(): string | undefined {
    return this.filter.values[0];
  }

  @action
  handleTypeChange(type: OccasionFilterType): void {
    this.filter.updateType(type);
  }

  @action
  handleInputChange(event: Event): void {
    const { value } = event.target as HTMLInputElement;

    this.filter.updateValues(value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::ReportFilters::Options::Numerical': typeof UiReportFiltersOptionsNumericalComponent;
  }
}
