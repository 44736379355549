export default {
  "sidebar": "_sidebar_ptq084",
  "navbar-brand": "_navbar-brand_ptq084",
  "item": "_item_ptq084",
  "popover": "_popover_ptq084",
  "link": "_link_ptq084",
  "counter": "_counter_ptq084",
  "active": "_active_ptq084",
  "link-title": "_link-title_ptq084",
  "bottom-section": "_bottom-section_ptq084"
};
