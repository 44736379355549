import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"booking-price-summary\">\n  {{#if this.price.hasHostEarnings}}\n    <Ui::Booking::Price::Tables::Default @price={{this.price}} />\n    <Ui::Booking::Price::Tables::Balance @price={{this.price}} />\n    <Ui::Booking::Price::Tables::OwnerNetRevenue @price={{this.price}} />\n    <Ui::Booking::Price::Tables::GuestInvoices @price={{this.price}} />\n    <Ui::Booking::Price::Tables::PendingItems @price={{this.price}} />\n    <Ui::Booking::Price::Tables::Payments @price={{this.price}} />\n    <Ui::Booking::Price::Tables::Revenue @price={{this.price}} />\n    <Ui::Booking::Price::Tables::ManagementFee @price={{this.price}} />\n  {{/if}}\n\n  <Ui::Booking::Price::Tables::GuestPrice @price={{this.price}} />\n</div>\n", {"contents":"<div local-class=\"booking-price-summary\">\n  {{#if this.price.hasHostEarnings}}\n    <Ui::Booking::Price::Tables::Default @price={{this.price}} />\n    <Ui::Booking::Price::Tables::Balance @price={{this.price}} />\n    <Ui::Booking::Price::Tables::OwnerNetRevenue @price={{this.price}} />\n    <Ui::Booking::Price::Tables::GuestInvoices @price={{this.price}} />\n    <Ui::Booking::Price::Tables::PendingItems @price={{this.price}} />\n    <Ui::Booking::Price::Tables::Payments @price={{this.price}} />\n    <Ui::Booking::Price::Tables::Revenue @price={{this.price}} />\n    <Ui::Booking::Price::Tables::ManagementFee @price={{this.price}} />\n  {{/if}}\n\n  <Ui::Booking::Price::Tables::GuestPrice @price={{this.price}} />\n</div>\n","moduleName":"uplisting-frontend/components/ui/booking/price/summary/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/booking/price/summary/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import PriceModel from 'uplisting-frontend/models/price';

interface IArgs {
  price: PriceModel;
}

export interface BookingPriceSummarySignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiBookingPriceSummaryComponent extends Component<BookingPriceSummarySignature> {
  @cached
  get price(): PriceModel {
    return this.args.price;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Price::Summary': typeof UiBookingPriceSummaryComponent;
  }
}
