import { type Registry as Services, service } from '@ember/service';
import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import UpsellModel from 'uplisting-frontend/models/upsell';
import { UpsellValidation } from 'uplisting-frontend/validations';
import {
  OccasionEvent,
  OccasionPreposition,
  OccasionTermUnit,
} from 'uplisting-frontend/models/schemas';

export default class UpsellRepositoryService extends BaseRepositoryService<UpsellModel> {
  @service pricing!: Services['pricing'];

  @service('repositories/tax') taxRepository!: Services['repositories/tax'];

  recordName = 'upsell';
  implementMethods = ['findAll', 'createRecord', 'buildChangeset', 'query'];

  validation = UpsellValidation;

  get defaultParams() {
    return {
      currency: this.pricing.currency,
      from: {
        term: 1,
        term_unit: OccasionTermUnit.hours,
        preposition: OccasionPreposition.after,
        event: OccasionEvent.booked,
      },
      to: {
        term: 1,
        term_unit: OccasionTermUnit.hours,
        preposition: OccasionPreposition.before,
        event: OccasionEvent.arrived,
      },
      tax: this.taxRepository.defaultTaxOption,
    };
  }
}

declare module '@ember/service' {
  interface Registry {
    'repositories/upsell': UpsellRepositoryService;
  }
}
