import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"action-bookings-wrapper\">\n  <h4>{{t \"action_bookings_logs.title\"}}</h4>\n\n  <br>\n\n  <p>{{html-safe (t \"action_bookings_logs.description\")}}</p>\n\n  <hr>\n\n  <Ui::InlineAlert class=\"mb-3\" @type=\"success\" @description={{t \"action_bookings_logs.alert\"}} />\n\n  {{#each @booking.scheduledMessages as |scheduledMessage|}}\n    <Ui::Booking::ScheduledMessage @booking={{@booking}} @scheduledMessage={{scheduledMessage}} />\n  {{/each}}\n</div>\n\n<div class=\"action-bookings-footer\">\n  {{t \"action_bookings_logs.footer\"}}\n\n  {{faq \"outbound-message-logs\" (t \"action_bookings_logs.footer_link\")}}\n</div>\n", {"contents":"<div class=\"action-bookings-wrapper\">\n  <h4>{{t \"action_bookings_logs.title\"}}</h4>\n\n  <br>\n\n  <p>{{html-safe (t \"action_bookings_logs.description\")}}</p>\n\n  <hr>\n\n  <Ui::InlineAlert class=\"mb-3\" @type=\"success\" @description={{t \"action_bookings_logs.alert\"}} />\n\n  {{#each @booking.scheduledMessages as |scheduledMessage|}}\n    <Ui::Booking::ScheduledMessage @booking={{@booking}} @scheduledMessage={{scheduledMessage}} />\n  {{/each}}\n</div>\n\n<div class=\"action-bookings-footer\">\n  {{t \"action_bookings_logs.footer\"}}\n\n  {{faq \"outbound-message-logs\" (t \"action_bookings_logs.footer_link\")}}\n</div>\n","moduleName":"uplisting-frontend/components/ui/booking/logs/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/booking/logs/index.hbs"}});
import templateOnly from '@ember/component/template-only';
import BookingModel from 'uplisting-frontend/models/booking';

interface IArgs {
  booking: BookingModel;
}

export interface BookingLogsSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

const UiBookingLogsComponent = templateOnly<BookingLogsSignature>();

export default UiBookingLogsComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Logs': typeof UiBookingLogsComponent;
  }
}
