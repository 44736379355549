import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';
import AdvancedReportConfigurationModel from 'uplisting-frontend/models/advanced-report-configuration';

export default class ReportsIndexRoute extends ProtectedRoute {
  async redirect(): Promise<void> {
    const reports = this.modelFor(
      'insights.reports',
    ) as AdvancedReportConfigurationModel[];

    const firstReport = reports?.[0];

    if (!firstReport) {
      return;
    }

    await this.router.transitionTo('insights.reports.show', firstReport.id);
  }
}
