import Helper from '@ember/component/helper';
import { type Registry as Services, service } from '@ember/service';

export default class ComputeUrlHelper extends Helper {
  @service router!: Services['router'];

  compute(params: [string, object]): string {
    return this.router.urlFor(...params);
  }
}
