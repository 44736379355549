import Controller from '@ember/controller';
import { type Registry as Services, service } from '@ember/service';
import config from 'ember-get-config';
import { cached, tracked } from '@glimmer/tracking';

export default class ApplicationController extends Controller {
  @service router!: Services['router'];

  closeAfter = config.notify.closeAfter;

  @cached @tracked isLoading = true;

  @cached
  get currentRouteName(): string {
    return this.router.currentRouteName ?? '';
  }

  @cached
  get isRegisterRoute(): boolean {
    return this.currentRouteName.startsWith('register.');
  }

  @cached
  get isV2Page(): boolean {
    return ['comp-sets'].includes(this.currentRouteName);
  }
}
