import type Transition from '@ember/routing/transition';
import BaseRoute from 'uplisting-frontend/pods/base/route';

export default class ReportsShowRoute extends BaseRoute {
  beforeModel(transition: Transition) {
    const params = transition.to?.params;

    if (!params) {
      return;
    }

    const { id } = params;

    this.router.replaceWith('insights.reports.show', id as string);
  }
}
