import { type Registry as Services, service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import { scrollRhsToTop } from 'uplisting-frontend/pods/-sidebar/utils';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import ActionBookingsController from 'uplisting-frontend/pods/action/bookings/controller';

export interface IParams {
  booking_id: string;
}

export default class SidebarBookingsRoute extends PermittedRoute {
  @service('repositories/booking')
  bookingRepository!: Services['repositories/booking'];

  permission = 'action.bookings';

  model(params: IParams) {
    return this.bookingRepository.findRecord(params.booking_id, {
      reload: true,
    });
  }

  setupController(
    controller: ActionBookingsController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { booking: model });

    scrollRhsToTop(this);
  }
}
