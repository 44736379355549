import type Transition from '@ember/routing/transition';
import OriginalInsightsRoute from 'uplisting-frontend/pods/insights/original-insights-route';
import InsightsSettingsController from 'uplisting-frontend/pods/insights/settings/controller';

export default class InsightsSettingsRoute extends OriginalInsightsRoute {
  permission = 'insights.settings';

  model() {
    return this.store.findRecord('net-revenue-rule', 'me');
  }

  setupController(
    controller: InsightsSettingsController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { netRevenueRule: model });
  }

  resetController(controller: InsightsSettingsController) {
    controller.netRevenueRule?.unloadRecord();
  }
}
