import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label data-test-input-group class=\"input-group\">\n  <span data-test-input-group-label>{{this.label}}</span>\n\n  <div\n    local-class={{if this.sidePlaceholderPresent \"side-holder\"}}\n    data-side-holder=\"{{@sidePlaceholder}}\"\n    data-test-input-group-wrapper\n  >\n    <input\n      placeholder={{this.placeholder}}\n      type=\"text\"\n      autocomplete=\"off\"\n      spellcheck=\"false\"\n      value={{@value}}\n      ...attributes\n      data-test-input\n      {{on \"input\" this.handleInput}}\n    >\n  </div>\n</label>\n", {"contents":"<label data-test-input-group class=\"input-group\">\n  <span data-test-input-group-label>{{this.label}}</span>\n\n  <div\n    local-class={{if this.sidePlaceholderPresent \"side-holder\"}}\n    data-side-holder=\"{{@sidePlaceholder}}\"\n    data-test-input-group-wrapper\n  >\n    <input\n      placeholder={{this.placeholder}}\n      type=\"text\"\n      autocomplete=\"off\"\n      spellcheck=\"false\"\n      value={{@value}}\n      ...attributes\n      data-test-input\n      {{on \"input\" this.handleInput}}\n    >\n  </div>\n</label>\n","moduleName":"uplisting-frontend/components/ui/input-group/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/input-group/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';

interface IArgs {
  label: string;
  placeholder: string;
  value: string;
  sidePlaceholder?: string;
  onChange?(value: string): void;
}

interface InputGroupSignature {
  Element: HTMLInputElement;

  Args: IArgs;
}

export default class UiInputGroupComponent extends Component<InputGroupSignature> {
  @cached
  get label(): string {
    return this.args.label;
  }

  @cached
  get placeholder(): string {
    return this.args.placeholder ?? '';
  }

  @cached
  get sidePlaceholderPresent(): boolean {
    return !!this.args.sidePlaceholder;
  }

  @action
  handleInput(event: Event): void {
    const value = (event.target as HTMLInputElement).value;

    this.args.onChange?.(value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::InputGroup': typeof UiInputGroupComponent;
  }
}
