import type Transition from '@ember/routing/transition';
import { type Registry as Services, service } from '@ember/service';
import { hash } from 'rsvp';
import ConnectAirbnbController from 'uplisting-frontend/pods/connect/airbnb/controller';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';

export default class ConnectCallbackAirbnbRoute extends ProtectedRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  model() {
    return hash({
      properties: this.propertyRepository.findAll(),
      airbnbOfficialConnections: this.store.findAll(
        'airbnb-official-connection',
      ),
    });
  }

  setupController(
    controller: ConnectAirbnbController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, model);

    controller.handleCallbackResponse();
  }
}
