import Service, { type Registry as Services, service } from '@ember/service';
import config from 'ember-get-config';
import { cached } from '@glimmer/tracking';
import {
  type IGaData,
  type ISegmentOptions,
  type ITrackEventParams,
} from 'ember-cli-segment';
import UserModel from 'uplisting-frontend/models/user';
import { getGaClientId } from 'uplisting-frontend/utils';

export enum AnalyticsEvents {
  clientManagementEnabled = 'client-management-enabled',
  clientManagementDisabled = 'client-management-disabled',
  clientStatementsDisabled = 'client-statements-disabled',
  clientStatementsEnabled = 'client-statements-enabled',

  edgestateAccountRemoved = 'edgestate-account-removed',
  edgestateAccountConnected = 'edgestate-account-connected',
  edgestateLockMapped = 'edgestate-lock-mapped',

  seamAccountRemoved = 'seam-account-removed',
  seamAccountConnected = 'seam-account-connected',
  seamLockMapped = 'seam-lock-mapped',

  airbnbOpportunityApplied = 'airbnb-opportunity-applied',
  airbnbConnectionAdded = 'airbnb-official-connection-added',
  airbnbConnectionUpgraded = 'airbnb-official-connection-upgraded',
  airbnbConnectionRemoved = 'airbnb-official-connection-removed',

  connectStripe = 'connected-stripe',

  directBookingEngineEnabled = 'direct-booking-engine-enabled',

  promotionEdited = 'promotion-edit',

  propertyManagementFeeSettingUpdated = 'updated-property-management-fee',

  bookingWindowRuleUpdated = 'updated-booking-window',

  automatedResponderEdit = 'automated-responder-edit',
  automatedResponderTurnedOn = 'automated-responder-turned-true',
  automatedResponderTurnedOff = 'automated-responder-turned-false',

  guestIdentityVerificationEnabled = 'guest-identity-verification-enabled',
  guestIdentityVerificationDisabled = 'guest-identity-verification-disabled',
  guestIdentityActivated = 'guest-identity-activated',

  legacyPlanUpgrade = 'legacy-plan-upgrade',

  securityDepositActivated = 'security-deposit-activated',

  customPropertyAttributeEdit = 'custom-property-attribute-edit',

  customMessageTagEdit = 'custom-message-tag-edit',

  bookingRentalAgreementSettingEdit = 'booking-rental-agreement-setting-edit',
  bookingRentalAgreementSettingEnabled = 'booking-rental-agreement-setting-enabled',
  bookingRentalAgreementSettingDisabled = 'booking-rental-agreement-setting-disabled',

  automatedReviewTemplateEdit = 'automated-review-template-edit',
  automatedReviewsActivated = 'automated-reviews-activated',
  automatedReviewsDisabled = 'automated-reviews-disabled',

  paymentRuleEdit = 'payment-rule-edit',

  messageSent = 'sent-message',

  invitationSentHost = 'invitation-sent-host',
  invitationSentOwner = 'invitation-sent-owner',
  invitationSentHousekeeper = 'invitation-sent-housekeeper',

  viewedBookingSummary = 'viewed-booking-summary',

  bookingAutomatedReviewsEnabled = 'automated-reviews-booking-enabled',
  bookingAutomatedReviewsDisabled = 'automated-reviews-booking-disabled',

  bookingSourceChanged = 'booking-source-changed',

  bookingMoved = 'moved-booking',
}

export default class AnalyticsService extends Service {
  @service session!: Services['session'];
  @service segment!: Services['segment'];

  @cached
  get trackEventParams(): ITrackEventParams {
    const currentUser = this.session.currentUser as UserModel;

    return {
      id: currentUser?.id,
      email: currentUser?.email,
    };
  }

  private gaData: IGaData | undefined;

  public async identifyUser(): Promise<void> {
    const { currentUser } = this.session;

    if (!config.IS_PROD || !currentUser) {
      return;
    }

    if (!this.gaData) {
      await this.getGaData();
    }

    const data = {
      ...currentUser.toObject(),
      ...this.gaData,
    };

    this.segment.identifyUser(currentUser.id, data, {
      integrations: {
        Intercom: {
          user_hash: currentUser.userHash,
          hideDefaultLauncher: currentUser.isOwnerOrHousekeeper,
        },
      },
    });
  }

  public trackEvent(event: AnalyticsEvents): void {
    const segmentOptions: ISegmentOptions = {
      integrations: {
        All: true,
      },
    };

    if (this.gaData) {
      segmentOptions.traits = {
        ...this.gaData,
      };
    }

    this.segment.trackEvent(event, this.trackEventParams, segmentOptions);
  }

  private async getGaData(): Promise<void> {
    if (!window.gtag) {
      return;
    }

    if (!this.gaData) {
      this.gaData = {};
    }

    const sessionNumberPromise = new Promise((resolve) =>
      window.gtag('get', config.gaTag, 'session_number', resolve),
    );

    const sessionIdPromise = new Promise((resolve) =>
      window.gtag('get', config.gaTag, 'session_id', resolve),
    );

    const [sessionId, sessionNumber] = (await Promise.all([
      sessionIdPromise,
      sessionNumberPromise,
    ])) as [string, string];

    const gaClientId = getGaClientId();

    if (sessionId) {
      this.gaData.gaSessionId = sessionId;
    }

    if (sessionNumber) {
      this.gaData.gaSessionNumber = sessionNumber;
    }

    if (gaClientId) {
      this.gaData.gaClientId = gaClientId;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    analytics: AnalyticsService;
  }
}
