import Service, { type Registry as Services, service } from '@ember/service';
import type { FormatMessageParameters } from 'ember-intl/-private/formatjs/index';
import { type IEmberNotifyOptions } from 'ember-notify';

export default class NotificationsService extends Service {
  @service intl!: Services['intl'];
  @service sentry!: Services['sentry'];
  @service notify!: Services['notify'];

  public info(
    key = 'notifications.applied',
    config?: FormatMessageParameters[1],
    options?: IEmberNotifyOptions,
  ): void {
    return this.notify.info(this.getMessage(key, config), options);
  }

  public error(
    key = 'notifications.error',
    config?: FormatMessageParameters[1],
    options?: IEmberNotifyOptions,
  ): void {
    return this.notify.error(this.getMessage(key, config), options);
  }

  private getMessage(
    key: string,
    config: FormatMessageParameters[1] = {},
  ): string {
    try {
      return this.intl.t(key, config);
    } catch {
      this.sentry.captureMessage(
        `#notifications service: unknown translation key - ${key}`,
      );

      return key;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    notifications: NotificationsService;
  }
}
