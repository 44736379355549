import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import GuestInvoiceModel from 'uplisting-frontend/models/guest-invoice';

export default class GuestInvoiceRepositoryService extends BaseRepositoryService<GuestInvoiceModel> {
  recordName = 'guest-invoice';
  implementMethods = ['createRecord', 'buildChangeset'];
}

declare module '@ember/service' {
  interface Registry {
    'repositories/guest-invoice': GuestInvoiceRepositoryService;
  }
}
