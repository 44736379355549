export default {
  "registration-header": "_registration-header_mftuvv",
  "mui-custom-input": "_mui-custom-input_mftuvv",
  "custom-button": "_custom-button_mftuvv",
  "typography-h2": "_typography-h2_mftuvv",
  "typography-h5": "_typography-h5_mftuvv",
  "form-wrapper": "_form-wrapper_mftuvv",
  "input-wrapper": "_input-wrapper_mftuvv",
  "country-flag": "_country-flag_mftuvv",
  "country-code": "_country-code_mftuvv",
  "multi-column-row": "_multi-column-row_mftuvv"
};
