import { type Registry as Services, service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import AutomateEnquiriesController from 'uplisting-frontend/pods/automate/enquiries/controller';

export default class AutomateEnquiriesRoute extends PermittedRoute {
  @service('repositories/autoresponder')
  autoresponderRepository!: Services['repositories/autoresponder'];

  permission = 'automate.enquiries';

  model() {
    return this.autoresponderRepository.findRecord('me');
  }

  setupController(
    controller: AutomateEnquiriesController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { autoresponder: model });
  }

  resetController(): void {
    this.autoresponderRepository.unloadAll();
  }
}
