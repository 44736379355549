import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<li\n  local-class=\"multiselect-item\"\n  class=\"ui-multiselect-item {{if this.isSelected \"selected\"}} {{if this.isDisabled \"disabled\"}} {{if this.isMultiUnit \"multi-unit\"}}\"\n  {{on \"click\" (fn @onClick this.item)}}\n  data-test-multiselect-item\n  ...attributes\n>\n  <Ui::Checkbox\n    @text={{this.text}}\n    @onClick={{fn @onClick this.item}}\n    @isChecked={{this.isSelected}}\n    @isDisabled={{this.isDisabled}}\n  />\n</li>\n", {"contents":"<li\n  local-class=\"multiselect-item\"\n  class=\"ui-multiselect-item {{if this.isSelected \"selected\"}} {{if this.isDisabled \"disabled\"}} {{if this.isMultiUnit \"multi-unit\"}}\"\n  {{on \"click\" (fn @onClick this.item)}}\n  data-test-multiselect-item\n  ...attributes\n>\n  <Ui::Checkbox\n    @text={{this.text}}\n    @onClick={{fn @onClick this.item}}\n    @isChecked={{this.isSelected}}\n    @isDisabled={{this.isDisabled}}\n  />\n</li>\n","moduleName":"uplisting-frontend/components/ui/dropdown/multiselect/item/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/dropdown/multiselect/item/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import MultiUnitModel from 'uplisting-frontend/models/multi-unit';

interface IArgs<T> {
  item: T | object;
  field?: keyof T;
  isDisabled?: boolean;
  isSelected?: boolean;
  computeSelected?(item: T): boolean;
  computeDisabledText?(item: T): string;
  onClick(item: T): void;
}

interface UiDropdownMultiselectItemSignature<T> {
  Element: HTMLLIElement;

  Args: IArgs<T>;
}

export default class UiDropdownMultiselectItemComponent<T> extends Component<
  UiDropdownMultiselectItemSignature<T>
> {
  @cached
  get item(): T {
    return this.args.item as T;
  }

  @cached
  get isMultiUnit(): boolean {
    return this.item instanceof MultiUnitModel;
  }

  @cached
  get isSelected(): boolean {
    return !!(
      this.args.isSelected || this.args.computeSelected?.(this.item as T)
    );
  }

  @cached
  get isDisabled(): boolean | undefined {
    return this.args.isDisabled;
  }

  @cached
  get text(): string {
    const { field, item } = this.args;

    if (!field) {
      return item as string;
    }

    const text = (this.item as T)[field] as string;

    if (this.isDisabled && this.args.computeDisabledText) {
      return `${text} ${this.args.computeDisabledText?.(this.item as T)}`;
    }

    return text;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Dropdown::Multiselect::Item': typeof UiDropdownMultiselectItemComponent;
  }
}
