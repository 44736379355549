import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import OnboardModel from 'uplisting-frontend/models/onboard';
import { OnboardValidation } from 'uplisting-frontend/validations';

export default class OnboardRepositoryService extends BaseRepositoryService<OnboardModel> {
  recordName = 'onboard';
  implementMethods = ['buildChangeset', 'createRecord'];

  validation = OnboardValidation;

  get defaultParams() {
    return {
      id: 'me',
    };
  }
}

declare module '@ember/service' {
  interface Registry {
    'repositories/onboard': OnboardRepositoryService;
  }
}
