import { type Registry as Services, service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { ref } from 'ember-ref-bucket';
import { scheduleTask, runTask } from 'ember-lifeline';
import type AlertModel from 'uplisting-frontend/models/alert';
import BaseController from 'uplisting-frontend/pods/base/controller';
import { AlertStatus, AlertType } from 'uplisting-frontend/models/schemas';

export default class NotificationsUplistingController extends BaseController {
  @service sentry!: Services['sentry'];

  @service('repositories/alert')
  alertsRepository!: Services['repositories/alert'];

  @cached @tracked allAlertsLoaded = false;
  @cached @tracked status = AlertStatus.pending;
  @cached @tracked alerts: AlertModel[] = [];
  @cached @tracked isLoading = false;
  @cached @tracked isSubmitting = false;

  @ref('table') table!: HTMLTableElement;

  activeObserver!: IntersectionObserver;
  observerElements!: Element[];

  pageSize = 20;

  @cached
  get isPendingSelected(): boolean {
    return this.status === 'pending';
  }

  @action
  public async handleActionClick(alert: AlertModel): Promise<void> {
    if (alert.isAirbnbUserSuspension) {
      window.open('https://www.airbnb.com/hosting', '_blank');
    } else if (alert.isAirbnbAlterationAlert) {
      const booking = alert.bookings?.[0];

      if (!booking) {
        return;
      }

      const route = this.getRouteForAlterationType(alert.type);
      const url = this.router.urlFor(route, booking.id);

      window.open(url, '_self');
    }
  }

  @action
  public async handleDismissClick(alert: AlertModel): Promise<void> {
    if (this.isSubmitting) {
      return;
    }

    alert.status = alert.isResolved
      ? AlertStatus.pending
      : AlertStatus.resolved;

    try {
      await alert.save();

      this.alerts = this.alerts.filter((a) => a.id !== alert.id);

      const successMessage = alert.isResolved
        ? 'uplisting_notifications.actions.marked_as_resolved'
        : 'uplisting_notifications.actions.marked_as_pending';

      this.notifications.info(successMessage);
    } catch {
      alert.rollbackAttributes();
      this.notifications.error();
    } finally {
      this.isSubmitting = false;
    }
  }

  @action
  public async fetchData(
    status: AlertStatus,
    isInitial = false,
  ): Promise<void> {
    if (this.fetchDataTask.isRunning || this.allAlertsLoaded) {
      return;
    }

    await this.fetchDataTask.perform(status, isInitial);
  }

  public fetchDataTask = dropTask(
    async (status: AlertStatus, isInitial: boolean) => {
      if (this.allAlertsLoaded) {
        return;
      }

      this.isLoading = true;

      const lastId = this.alerts[this.alerts.length - 1]?.id;

      const data = await this.alertsRepository.query({
        filter: { status },
        page: { before: lastId, size: this.pageSize },
      });

      this.isLoading = false;

      this.alerts = isInitial
        ? data.slice()
        : [...this.alerts, ...data.slice()];

      if (!data.length || (data.length as number) < this.pageSize) {
        this.allAlertsLoaded = true;

        return;
      }

      this.calculateTableSize();
    },
  );

  public calculateTableSize(): void {
    runTask(
      this,
      () => {
        scheduleTask(this, 'render', () => {
          this.setIntersectionObserver();
        });
      },
      10,
    );
  }

  private setIntersectionObserver(): void {
    this.clearActiveObserver();

    const items = this.getLastRowItems();

    if (!items) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(async (entry) => {
          const isOutOfScreen = entry.intersectionRatio === 0;

          if (isOutOfScreen) {
            return;
          }

          const isLastItemReached = items.some((item) => entry.target === item);

          if (isLastItemReached) {
            await this.fetchData(this.status);
          }
        });
      },
      {
        threshold: 0.25,
      },
    );

    items.forEach((item) => {
      observer.observe(item);
    });

    this.activeObserver = observer;
    this.observerElements = items;
  }

  private getLastRowItems(): Element[] | undefined {
    const tBody = this.table?.tBodies?.[0];

    if (!tBody) {
      return;
    }

    const lastRow = tBody.children[tBody.children.length - 1] as Element;

    return [...lastRow.children];
  }

  private clearActiveObserver(): void {
    if (this.observerElements?.length) {
      this.observerElements.forEach((element) => {
        this.activeObserver.unobserve(element);
      });
    }

    this.activeObserver?.disconnect();
  }

  private getRouteForAlterationType(type: AlertType): string {
    switch (type) {
      case AlertType.airbnbOfficialAlterationRequestCreated:
        return 'calendar.bookings.summary.accept';
      case AlertType.airbnbOfficialAlterationRequestDeclined:
      case AlertType.airbnbOfficialAlterationRequestAccepted:
        return 'calendar.bookings.summary.index';
      default:
        this.sentry.captureMessage(
          `#NotificationsUplistingController controller: unknown alert type - ${type}`,
        );

        return '';
    }
  }
}
