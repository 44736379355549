import Helper from '@ember/component/helper';
import { type Registry as Services, service } from '@ember/service';

export default class HasAccessToHelper extends Helper {
  @service session!: Services['session'];

  compute([permission]: [string]): boolean {
    return this.session.currentUser?.hasAccessTo(permission) ?? false;
  }
}
