import { type Registry as Services, service } from '@ember/service';
import BaseRoute from 'uplisting-frontend/pods/base/route';
import { AlertStatus } from 'uplisting-frontend/models/schemas';
import type NotificationsUplistingController from 'uplisting-frontend/pods/notifications/uplisting/controller';

export default abstract class NotificationsUplistingBaseRoute extends BaseRoute {
  @service('repositories/alert')
  alertsRepository!: Services['repositories/alert'];

  abstract alertStatus: AlertStatus;

  async setupController(controller, model, transition): Promise<void> {
    const parentController = this.controllerFor(
      'notifications.uplisting',
    ) as NotificationsUplistingController;

    super.setupController(controller, model, transition);

    parentController.status = this.alertStatus;
    parentController.allAlertsLoaded = false;

    await parentController.fetchData(this.alertStatus, true);
  }

  resetController(): void {
    const parentController = this.controllerFor(
      'notifications.uplisting',
    ) as NotificationsUplistingController;

    parentController.alerts = [];

    this.alertsRepository.unloadAll();
  }
}
