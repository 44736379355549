import { type Registry as Services, service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import ClientsAccountDetailsController from 'uplisting-frontend/pods/clients/account-details/controller';

export default class ClientsAccountDetailsRoute extends PermittedRoute {
  @service('repositories/client-statement-account-detail')
  clientStatementAccountDetailRepository!: Services['repositories/client-statement-account-detail'];

  permission = 'client_statements.settings';

  model() {
    return this.clientStatementAccountDetailRepository.findAll();
  }

  setupController(
    controller: ClientsAccountDetailsController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { accountDetails: model });
  }
}
