import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class=\"action-card\" data-test-action-card>\n  <div local-class=\"action-card-title\" data-test-action-card-title>\n    {{this.card.title}}\n  </div>\n\n  <div local-class=\"action-card-description\" data-test-action-card-description>\n    {{this.card.description}}\n  </div>\n\n  <a\n    local-class=\"action-card-button\"\n    target=\"_blank\"\n    rel=\"noopener noreferrer\"\n    href={{this.card.featureLink}}\n    data-test-action-card-button\n  >\n    {{this.card.featureText}}\n  </a>\n\n  <a\n    local-class=\"action-card-documentation\"\n    target=\"_blank\"\n    rel=\"noopener noreferrer\"\n    href=\"https://support.uplisting.io/docs{{this.card.documentationLink}}\"\n    data-test-action-card-documentation\n  >\n    {{this.card.documentationText}}\n  </a>\n</div>\n", {"contents":"<div local-class=\"action-card\" data-test-action-card>\n  <div local-class=\"action-card-title\" data-test-action-card-title>\n    {{this.card.title}}\n  </div>\n\n  <div local-class=\"action-card-description\" data-test-action-card-description>\n    {{this.card.description}}\n  </div>\n\n  <a\n    local-class=\"action-card-button\"\n    target=\"_blank\"\n    rel=\"noopener noreferrer\"\n    href={{this.card.featureLink}}\n    data-test-action-card-button\n  >\n    {{this.card.featureText}}\n  </a>\n\n  <a\n    local-class=\"action-card-documentation\"\n    target=\"_blank\"\n    rel=\"noopener noreferrer\"\n    href=\"https://support.uplisting.io/docs{{this.card.documentationLink}}\"\n    data-test-action-card-documentation\n  >\n    {{this.card.documentationText}}\n  </a>\n</div>\n","moduleName":"uplisting-frontend/components/ui/action-card/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/action-card/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

export interface IActionCard {
  title: string;
  description: string;
  featureText: string;
  featureLink: string;
  documentationLink: string;
  documentationText: string;
}

interface IArgs {
  actionCard: IActionCard;
}

interface ActionCardSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiActionCardComponent extends Component<ActionCardSignature> {
  @cached
  get card(): IActionCard {
    return this.args.actionCard;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::ActionCard': typeof UiActionCardComponent;
  }
}
