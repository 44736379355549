import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Registry as Services, service } from '@ember/service';
import sortBy from 'lodash-es/sortBy';
import BaseController from 'uplisting-frontend/pods/base/controller';
import { AnalyticsEvents } from 'uplisting-frontend/services/analytics';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';
import PropertyManagementFeeSettingModel from 'uplisting-frontend/models/property-management-fee-setting';
import { isSaveDisabled } from 'uplisting-frontend/utils';

interface IErr {
  code?: string;
}

export default class OperationsManagementFeesController extends BaseController {
  @service analytics!: Services['analytics'];

  @service('repositories/property-management-fee-setting')
  propertyManagementFeeSettingRepository!: Services['repositories/property-management-fee-setting'];

  @cached @tracked managementFees!: PropertyManagementFeeSettingModel[];

  @cached
  get managementFeesOrdered(): PropertyManagementFeeSettingModel[] {
    return sortBy(this.managementFees, (fee) =>
      fee.property.nickname.toLowerCase(),
    );
  }

  @cached
  get changesets(): GenericChangeset<PropertyManagementFeeSettingModel>[] {
    const {
      managementFeesOrdered,
      propertyManagementFeeSettingRepository: repository,
    } = this;

    return managementFeesOrdered.map((fee) => repository.buildChangeset(fee));
  }

  @action
  async handleSave(
    changeset: GenericChangeset<PropertyManagementFeeSettingModel>,
  ): Promise<void> {
    const saveDisabled = await isSaveDisabled(changeset);

    if (saveDisabled) {
      return;
    }

    try {
      await changeset.save();

      this.notifySaved();
    } catch (e) {
      if ((e as IErr).code) {
        this.notifications.error();
      } else {
        this.notifySaved();
      }
    }
  }

  private notifySaved() {
    this.notifications.info('operations_management_fees.notifications.saved');
    this.analytics.trackEvent(
      AnalyticsEvents.propertyManagementFeeSettingUpdated,
    );
  }
}
