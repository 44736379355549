export default {
  "image-upload": "_image-upload_1u67f3",
  "form-image-upload": "_form-image-upload_1u67f3",
  "upload-message-text": "_upload-message-text_1u67f3",
  "reset-image": "_reset-image_1u67f3",
  "form-image-upload-wrapper": "_form-image-upload-wrapper_1u67f3",
  "upload-image-title": "_upload-image-title_1u67f3",
  "active": "_active_1u67f3",
  "upload-image-icon": "_upload-image-icon_1u67f3",
  "upload-message": "_upload-message_1u67f3",
  "upload-error-icon": "_upload-error-icon_1u67f3",
  "upload-waring-icon": "_upload-waring-icon_1u67f3"
};
