import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span class=\"text-truncate\" local-class=\"selected-item\" data-count={{@extra.counter}}>\n  {{this.value}}\n</span>\n\n{{#if this.value}}\n  {{!-- template-lint-disable no-bare-strings --}}\n  <span\n    class=\"ember-power-select-clear-btn\"\n    role=\"button\"\n    {{on \"mouseup\" this.handleReset}}\n    {{on \"touchstart\" this.handleReset}}\n  >\n    &#215;\n  </span>\n{{/if}}\n", {"contents":"<span class=\"text-truncate\" local-class=\"selected-item\" data-count={{@extra.counter}}>\n  {{this.value}}\n</span>\n\n{{#if this.value}}\n  {{!-- template-lint-disable no-bare-strings --}}\n  <span\n    class=\"ember-power-select-clear-btn\"\n    role=\"button\"\n    {{on \"mouseup\" this.handleReset}}\n    {{on \"touchstart\" this.handleReset}}\n  >\n    &#215;\n  </span>\n{{/if}}\n","moduleName":"uplisting-frontend/components/ui/filters/filter/multi-select/selected/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/filters/filter/multi-select/selected/index.hbs"}});
import Component from '@glimmer/component';
import Model from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Select } from 'ember-power-select/components/power-select';
import { type IExtra } from 'uplisting-frontend/components/ui/filters/filter/multi-select';

interface IArgs {
  select: Select;
  option: Model;
  extra: IExtra;
}

interface FiltersFilterMultiSelectSelectedSignature {
  Element: null;

  Args: IArgs;
}

export default class FiltersFilterMultiSelectSelectedComponent extends Component<FiltersFilterMultiSelectSelectedSignature> {
  @cached
  get value(): string {
    return this.args.option[this.args.extra.modelField] as string;
  }

  @action
  handleReset(): void {
    this.args.extra.handleChange(null);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Filters::Filter::MultiSelect::Selected': typeof FiltersFilterMultiSelectSelectedComponent;
    'ui/filters/filter/multi-select/selected': typeof FiltersFilterMultiSelectSelectedComponent;
  }
}
