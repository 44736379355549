import Service, { type Registry as Services, service } from '@ember/service';
import { cached } from '@glimmer/tracking';
import {
  SectionIds,
  OccasionAttribute,
  TableSectionFields,
} from 'uplisting-frontend/models/occasion';
import {
  type OccasionFilter,
  BookingStatusFilter,
  BookingSecurityDepositStatusFilter,
  BookingRentalAgreementStatusFilter,
  BookingGuestIdentityVerificationStatusFilter,
  BookingPaymentStatusFilter,
  BookingChannelFilter,
  BookingPropertyIdFilter,
  BookingPropertyTagIdFilter,
  BookingMultiUnitFilter,
  BookingDurationFilter,
  BookingCreationDateFilter,
  BookingCancellationDateFilter,
  BookingCheckInDateFilter,
  BookingCheckOutDateFilter,
  BookingStaysFilter,
  OccasionFilterGroup,
} from 'uplisting-frontend/utils/occasion-filters';
import {
  type IOccasionFilter,
  type OccasionFilters,
  OccasionFilterType,
  type IOccasionFilterGroup,
} from 'uplisting-frontend/models/schemas';

export interface ISectionSubItem {
  id: OccasionAttribute;
  text: string;
}

export interface ISectionItem {
  title: string;
  items: ISectionSubItem[];
}

export interface IOccasionFilterSectionSubItem {
  id: TableSectionFields;
  text: string;
}

export interface IOccasionFilterSectionItem {
  title: string;
  items: IOccasionFilterSectionSubItem[];
}

export interface IFieldInfo {
  title: string;
  property: OccasionAttribute;
  class: string;
  availableTables: SectionIds[];
}

export default class OccasionsService extends Service {
  @service intl!: Services['intl'];
  @service store!: Services['store'];

  @service('repositories/occasion')
  occasionRepositoryService!: Services['repositories/occasion'];

  @cached
  get occasionFilters(): IOccasionFilterSectionItem[] {
    const { intl } = this;

    return [
      {
        title: intl.t('dashboard_index.table.rows.dates'),
        items: [
          {
            id: TableSectionFields.newBookings,
            text: intl.t('dashboard_index.table.cells.creation_date'),
          },
          {
            id: TableSectionFields.cancelledBookings,
            text: intl.t('dashboard_index.table.cells.cancellation_date'),
          },
          {
            id: TableSectionFields.checkIn,
            text: intl.t('dashboard_index.table.cells.check_in'),
          },
          {
            id: TableSectionFields.checkOut,
            text: intl.t('dashboard_index.table.cells.check_out'),
          },
          {
            id: TableSectionFields.stays,
            text: intl.t('dashboard_index.table.cells.stays'),
          },
        ],
      },
      {
        title: intl.t('dashboard_index.table.rows.features'),
        items: [
          {
            id: TableSectionFields.duration,
            text: intl.t('dashboard_index.table.cells.duration'),
          },
          {
            id: TableSectionFields.channel,
            text: intl.t('dashboard_index.table.cells.channel'),
          },
          {
            id: TableSectionFields.bookingStatus,
            text: intl.t('dashboard_index.table.cells.status'),
          },
        ],
      },
      {
        title: intl.t('dashboard_index.table.rows.pre_arrival'),
        items: [
          {
            id: TableSectionFields.paid,
            text: intl.t('dashboard_index.table.cells.payment_status'),
          },
          {
            id: TableSectionFields.bookingRentalAgreementStatus,
            text: intl.t('dashboard_index.table.cells.rental_agreement_status'),
          },
          {
            id: TableSectionFields.bookingGuestIdentityVerificationStatus,
            text: intl.t(
              'dashboard_index.table.cells.guest_identity_verification_status',
            ),
          },
          {
            id: TableSectionFields.depositStatus,
            text: intl.t('dashboard_index.table.cells.security_deposit_status'),
          },
        ],
      },
      {
        title: intl.t('dashboard_index.table.rows.property'),
        items: [
          {
            id: TableSectionFields.propertyId,
            text: intl.t('dashboard_index.table.cells.property_name'),
          },
          {
            id: TableSectionFields.multiUnitId,
            text: intl.t('dashboard_index.table.cells.multi_unit_name'),
          },
          {
            id: TableSectionFields.propertyTagId,
            text: intl.t('dashboard_index.table.cells.property_tag'),
          },
        ],
      },
    ];
  }

  @cached
  get occasionReportFilters(): IOccasionFilterSectionItem[] {
    const { intl } = this;

    return [
      {
        title: intl.t('dashboard_index.table.rows.features'),
        items: [
          {
            id: TableSectionFields.duration,
            text: intl.t('dashboard_index.table.cells.duration'),
          },
          {
            id: TableSectionFields.channel,
            text: intl.t('dashboard_index.table.cells.channel'),
          },
        ],
      },
      {
        title: intl.t('dashboard_index.table.rows.pre_arrival'),
        items: [
          {
            id: TableSectionFields.paid,
            text: intl.t('dashboard_index.table.cells.payment_status'),
          },
          {
            id: TableSectionFields.bookingRentalAgreementStatus,
            text: intl.t('dashboard_index.table.cells.rental_agreement_status'),
          },
          {
            id: TableSectionFields.bookingGuestIdentityVerificationStatus,
            text: intl.t(
              'dashboard_index.table.cells.guest_identity_verification_status',
            ),
          },
          {
            id: TableSectionFields.depositStatus,
            text: intl.t('dashboard_index.table.cells.security_deposit_status'),
          },
        ],
      },
    ];
  }

  @cached
  get dashboardColumns(): ISectionItem[] {
    const { intl } = this;

    return [
      {
        title: intl.t('dashboard_index.table.rows.dates'),
        items: [
          {
            id: OccasionAttribute.creationDate,
            text: intl.t('dashboard_index.table.cells.creation_date'),
          },
          {
            id: OccasionAttribute.cancellationDate,
            text: intl.t('dashboard_index.table.cells.cancellation_date'),
          },
          {
            id: OccasionAttribute.checkIn,
            text: intl.t('dashboard_index.table.cells.check_in'),
          },
          {
            id: OccasionAttribute.checkOut,
            text: intl.t('dashboard_index.table.cells.check_out'),
          },
          {
            id: OccasionAttribute.arrivalTime,
            text: intl.t('dashboard_index.table.cells.arrival_time'),
          },
          {
            id: OccasionAttribute.departureTime,
            text: intl.t('dashboard_index.table.cells.departure_time'),
          },
        ],
      },
      {
        title: intl.t('dashboard_index.table.rows.features'),
        items: [
          {
            id: OccasionAttribute.duration,
            text: intl.t('dashboard_index.table.cells.duration'),
          },
          {
            id: OccasionAttribute.numberOfGuests,
            text: intl.t('dashboard_index.table.cells.number_of_guests'),
          },
          {
            id: OccasionAttribute.remainingNights,
            text: intl.t('dashboard_index.table.cells.remaining_nights'),
          },
          {
            id: OccasionAttribute.channel,
            text: intl.t('dashboard_index.table.cells.channel'),
          },
          {
            id: OccasionAttribute.id,
            text: intl.t('dashboard_index.table.cells.id'),
          },
          {
            id: OccasionAttribute.status,
            text: intl.t('dashboard_index.table.cells.status'),
          },
          {
            id: OccasionAttribute.paymentStatus,
            text: intl.t('dashboard_index.table.cells.payment_status'),
          },
          {
            id: OccasionAttribute.rentalAgreementStatus,
            text: intl.t('dashboard_index.table.cells.rental_agreement_status'),
          },
          {
            id: OccasionAttribute.guestIdentityVerificationStatus,
            text: intl.t(
              'dashboard_index.table.cells.guest_identity_verification_status',
            ),
          },
          {
            id: OccasionAttribute.securityDepositStatus,
            text: intl.t('dashboard_index.table.cells.security_deposit_status'),
          },
          {
            id: OccasionAttribute.source,
            text: intl.t('dashboard_index.table.cells.source'),
          },
          {
            id: OccasionAttribute.confirmationCode,
            text: intl.t('dashboard_index.table.cells.confirmation_code'),
          },
          {
            id: OccasionAttribute.note,
            text: intl.t('dashboard_index.table.cells.note'),
          },
        ],
      },
      {
        title: intl.t('dashboard_index.table.rows.guest'),
        items: [
          {
            id: OccasionAttribute.guestName,
            text: intl.t('dashboard_index.table.cells.guest_name'),
          },
          {
            id: OccasionAttribute.guestEmail,
            text: intl.t('dashboard_index.table.cells.guest_email'),
          },
          {
            id: OccasionAttribute.guestPhone,
            text: intl.t('dashboard_index.table.cells.guest_phone'),
          },
        ],
      },
      {
        title: intl.t('dashboard_index.table.rows.property'),
        items: [
          {
            id: OccasionAttribute.propertyNickname,
            text: intl.t('dashboard_index.table.cells.property_name'),
          },
          {
            id: OccasionAttribute.multiUnitName,
            text: intl.t('dashboard_index.table.cells.multi_unit_name'),
          },
          {
            id: OccasionAttribute.propertyId,
            text: intl.t('dashboard_index.table.cells.property_id'),
          },
          {
            id: OccasionAttribute.propertyTimeZone,
            text: intl.t('dashboard_index.table.cells.property_time_zone'),
          },
        ],
      },
      {
        title: intl.t('dashboard_index.table.rows.financial'),
        items: [
          {
            id: OccasionAttribute.currency,
            text: intl.t('dashboard_index.table.cells.currency'),
          },
          {
            id: OccasionAttribute.paymentDueDate,
            text: intl.t('dashboard_index.table.cells.payment_due_date'),
          },
          {
            id: OccasionAttribute.outstandingAmount,
            text: intl.t('dashboard_index.table.cells.outstanding_amount'),
          },
          {
            id: OccasionAttribute.totalPayout,
            text: intl.t('dashboard_index.table.cells.total_payout'),
          },
          {
            id: OccasionAttribute.securityDepositAmount,
            text: intl.t('dashboard_index.table.cells.security_deposit_amount'),
          },
          {
            id: OccasionAttribute.securityDepositDueDate,
            text: intl.t(
              'dashboard_index.table.cells.security_deposit_due_date',
            ),
          },
          {
            id: OccasionAttribute.accommodationTotal,
            text: intl.t('dashboard_index.table.cells.accommodation_total'),
          },
          {
            id: OccasionAttribute.grossRevenue,
            text: intl.t('dashboard_index.table.cells.gross_revenue'),
          },
          {
            id: OccasionAttribute.netRevenue,
            text: intl.t('dashboard_index.table.cells.net_revenue'),
          },
          {
            id: OccasionAttribute.balance,
            text: intl.t('dashboard_index.table.cells.balance'),
          },
          {
            id: OccasionAttribute.extraGuestCharges,
            text: intl.t('dashboard_index.table.cells.extra_guest_charges'),
          },
          {
            id: OccasionAttribute.extraCharges,
            text: intl.t('dashboard_index.table.cells.extra_charges'),
          },
          {
            id: OccasionAttribute.discounts,
            text: intl.t('dashboard_index.table.cells.discounts'),
          },
          {
            id: OccasionAttribute.taxes,
            text: intl.t('dashboard_index.table.cells.taxes'),
          },
          {
            id: OccasionAttribute.cleaningFee,
            text: intl.t('dashboard_index.table.cells.cleaning_fee'),
          },
          {
            id: OccasionAttribute.paymentProcessingFee,
            text: intl.t('dashboard_index.table.cells.payment_processing_fee'),
          },
          {
            id: OccasionAttribute.commission,
            text: intl.t('dashboard_index.table.cells.commission'),
          },
          {
            id: OccasionAttribute.commissionTax,
            text: intl.t('dashboard_index.table.cells.commission_tax'),
          },
          {
            id: OccasionAttribute.cancellationFee,
            text: intl.t('dashboard_index.table.cells.cancellation_fee'),
          },
          {
            id: OccasionAttribute.accommodationManagementFee,
            text: intl.t(
              'dashboard_index.table.cells.accommodation_management_fee',
            ),
          },
          {
            id: OccasionAttribute.cleaningManagementFee,
            text: intl.t('dashboard_index.table.cells.cleaning_management_fee'),
          },
          {
            id: OccasionAttribute.totalManagementFee,
            text: intl.t('dashboard_index.table.cells.total_management_fee'),
          },
          {
            id: OccasionAttribute.upsellsTotal,
            text: intl.t('dashboard_index.table.cells.upsells_total'),
          },
        ],
      },
    ];
  }

  @cached
  get fieldsInfo(): IFieldInfo[] {
    const { intl } = this;

    return [
      {
        title: intl.t('dashboard_index.table.cells.guest_name'),
        property: OccasionAttribute.guestName,
        class: 'width-180',
        availableTables: [
          SectionIds.checkIn,
          SectionIds.checkOut,
          SectionIds.stays,
          SectionIds.newBookings,
          SectionIds.enquiries,
          SectionIds.bookingRequests,
          SectionIds.pendingBookings,
          SectionIds.cancelledBookings,
          SectionIds.bookingPayment,
          SectionIds.securityDeposits,
          SectionIds.bookingGuestIdentityVerifications,
          SectionIds.bookingRentalAgreements,
        ],
      },
      {
        title: intl.t('dashboard_index.table.cells.property_name'),
        property: OccasionAttribute.propertyNickname,
        class: 'width-320',
        availableTables: [
          SectionIds.checkIn,
          SectionIds.checkOut,
          SectionIds.stays,
          SectionIds.newBookings,
          SectionIds.enquiries,
          SectionIds.bookingRequests,
          SectionIds.pendingBookings,
          SectionIds.cancelledBookings,
          SectionIds.bookingPayment,
          SectionIds.securityDeposits,
          SectionIds.bookingGuestIdentityVerifications,
          SectionIds.bookingRentalAgreements,
        ],
      },
      {
        title: intl.t('dashboard_index.table.cells.multi_unit_name'),
        property: OccasionAttribute.multiUnitName,
        class: 'width-150',
        availableTables: [
          SectionIds.checkIn,
          SectionIds.checkOut,
          SectionIds.stays,
          SectionIds.newBookings,
          SectionIds.enquiries,
          SectionIds.bookingRequests,
          SectionIds.pendingBookings,
          SectionIds.cancelledBookings,
          SectionIds.bookingPayment,
          SectionIds.securityDeposits,
          SectionIds.bookingGuestIdentityVerifications,
          SectionIds.bookingRentalAgreements,
        ],
      },
      {
        title: intl.t('dashboard_index.table.cells.property_time_zone'),
        property: OccasionAttribute.propertyTimeZone,
        class: 'width-150',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.check_in'),
        property: OccasionAttribute.checkIn,
        class: 'width-150',
        availableTables: [
          SectionIds.checkIn,
          SectionIds.checkOut,
          SectionIds.stays,
          SectionIds.newBookings,
          SectionIds.enquiries,
          SectionIds.bookingRequests,
          SectionIds.pendingBookings,
          SectionIds.cancelledBookings,
          SectionIds.bookingPayment,
          SectionIds.securityDeposits,
          SectionIds.bookingGuestIdentityVerifications,
          SectionIds.bookingRentalAgreements,
        ],
      },
      {
        title: intl.t('dashboard_index.table.cells.arrival_time'),
        property: OccasionAttribute.arrivalTime,
        class: 'width-120',
        availableTables: [
          SectionIds.checkIn,
          SectionIds.checkOut,
          SectionIds.stays,
          SectionIds.newBookings,
          SectionIds.bookingRequests,
          SectionIds.pendingBookings,
          SectionIds.bookingPayment,
          SectionIds.securityDeposits,
          SectionIds.bookingGuestIdentityVerifications,
          SectionIds.bookingRentalAgreements,
        ],
      },
      {
        title: intl.t('dashboard_index.table.cells.check_out'),
        property: OccasionAttribute.checkOut,
        class: 'width-150',
        availableTables: [
          SectionIds.checkIn,
          SectionIds.checkOut,
          SectionIds.stays,
          SectionIds.newBookings,
          SectionIds.enquiries,
          SectionIds.bookingRequests,
          SectionIds.pendingBookings,
          SectionIds.cancelledBookings,
          SectionIds.bookingPayment,
          SectionIds.securityDeposits,
          SectionIds.bookingGuestIdentityVerifications,
          SectionIds.bookingRentalAgreements,
        ],
      },
      {
        title: intl.t('dashboard_index.table.cells.departure_time'),
        property: OccasionAttribute.departureTime,
        class: 'width-120',
        availableTables: [
          SectionIds.checkIn,
          SectionIds.checkOut,
          SectionIds.stays,
          SectionIds.newBookings,
          SectionIds.bookingRequests,
          SectionIds.pendingBookings,
          SectionIds.bookingPayment,
          SectionIds.securityDeposits,
          SectionIds.bookingGuestIdentityVerifications,
          SectionIds.bookingRentalAgreements,
        ],
      },
      {
        title: intl.t('dashboard_index.table.cells.number_of_guests'),
        property: OccasionAttribute.numberOfGuests,
        class: 'width-100',
        availableTables: [
          SectionIds.checkIn,
          SectionIds.checkOut,
          SectionIds.stays,
          SectionIds.newBookings,
          SectionIds.enquiries,
          SectionIds.bookingRequests,
          SectionIds.pendingBookings,
          SectionIds.cancelledBookings,
          SectionIds.bookingPayment,
          SectionIds.securityDeposits,
          SectionIds.bookingGuestIdentityVerifications,
          SectionIds.bookingRentalAgreements,
        ],
      },
      {
        title: intl.t('dashboard_index.table.cells.duration'),
        property: OccasionAttribute.duration,
        class: 'width-100',
        availableTables: [
          SectionIds.checkIn,
          SectionIds.checkOut,
          SectionIds.stays,
          SectionIds.newBookings,
          SectionIds.enquiries,
          SectionIds.bookingRequests,
          SectionIds.pendingBookings,
          SectionIds.cancelledBookings,
          SectionIds.bookingPayment,
          SectionIds.securityDeposits,
          SectionIds.bookingGuestIdentityVerifications,
          SectionIds.bookingRentalAgreements,
        ],
      },
      {
        title: intl.t('dashboard_index.table.cells.remaining_nights'),
        property: OccasionAttribute.remainingNights,
        class: 'width-150',
        availableTables: [SectionIds.checkIn, SectionIds.stays],
      },
      {
        title: intl.t('dashboard_index.table.cells.total_payout'),
        property: OccasionAttribute.totalPayout,
        class: 'width-120',
        availableTables: [
          SectionIds.checkIn,
          SectionIds.checkOut,
          SectionIds.stays,
          SectionIds.newBookings,
          SectionIds.enquiries,
          SectionIds.bookingRequests,
          SectionIds.pendingBookings,
          SectionIds.cancelledBookings,
          SectionIds.bookingPayment,
          SectionIds.securityDeposits,
          SectionIds.bookingGuestIdentityVerifications,
          SectionIds.bookingRentalAgreements,
        ],
      },
      {
        title: intl.t('dashboard_index.table.cells.channel'),
        property: OccasionAttribute.channel,
        class: 'width-120',
        availableTables: [
          SectionIds.checkIn,
          SectionIds.checkOut,
          SectionIds.stays,
          SectionIds.newBookings,
          SectionIds.enquiries,
          SectionIds.bookingRequests,
          SectionIds.pendingBookings,
          SectionIds.cancelledBookings,
          SectionIds.bookingPayment,
          SectionIds.securityDeposits,
          SectionIds.bookingGuestIdentityVerifications,
          SectionIds.bookingRentalAgreements,
        ],
      },
      {
        title: intl.t('dashboard_index.table.cells.status'),
        property: OccasionAttribute.status,
        class: 'width-150',
        availableTables: [
          SectionIds.checkIn,
          SectionIds.checkOut,
          SectionIds.stays,
          SectionIds.newBookings,
          SectionIds.enquiries,
          SectionIds.bookingRequests,
          SectionIds.pendingBookings,
          SectionIds.cancelledBookings,
          SectionIds.bookingPayment,
          SectionIds.securityDeposits,
          SectionIds.bookingGuestIdentityVerifications,
          SectionIds.bookingRentalAgreements,
        ],
      },
      {
        title: intl.t('dashboard_index.table.cells.creation_date'),
        property: OccasionAttribute.creationDate,
        class: 'width-150',
        availableTables: [
          SectionIds.checkIn,
          SectionIds.checkOut,
          SectionIds.stays,
          SectionIds.newBookings,
          SectionIds.enquiries,
          SectionIds.bookingRequests,
          SectionIds.pendingBookings,
          SectionIds.cancelledBookings,
          SectionIds.bookingPayment,
          SectionIds.securityDeposits,
          SectionIds.bookingGuestIdentityVerifications,
          SectionIds.bookingRentalAgreements,
        ],
      },
      {
        title: intl.t('dashboard_index.table.cells.cancellation_date'),
        property: OccasionAttribute.cancellationDate,
        class: 'width-150',
        availableTables: [SectionIds.cancelledBookings],
      },
      {
        title: intl.t('dashboard_index.table.cells.payment_due_date'),
        property: OccasionAttribute.paymentDueDate,
        class: 'width-150',
        availableTables: [
          SectionIds.checkIn,
          SectionIds.checkOut,
          SectionIds.stays,
          SectionIds.newBookings,
          SectionIds.pendingBookings,
          SectionIds.bookingPayment,
          SectionIds.securityDeposits,
          SectionIds.bookingGuestIdentityVerifications,
          SectionIds.bookingRentalAgreements,
        ],
      },
      {
        title: intl.t('dashboard_index.table.cells.outstanding_amount'),
        property: OccasionAttribute.outstandingAmount,
        class: 'width-150',
        availableTables: [
          SectionIds.checkIn,
          SectionIds.checkOut,
          SectionIds.stays,
          SectionIds.newBookings,
          SectionIds.cancelledBookings,
          SectionIds.bookingPayment,
          SectionIds.securityDeposits,
          SectionIds.bookingGuestIdentityVerifications,
          SectionIds.bookingRentalAgreements,
        ],
      },
      {
        title: intl.t('dashboard_index.table.cells.payment_status'),
        property: OccasionAttribute.paymentStatus,
        class: 'width-150',
        availableTables: [
          SectionIds.checkIn,
          SectionIds.checkOut,
          SectionIds.stays,
          SectionIds.newBookings,
          SectionIds.pendingBookings,
          SectionIds.cancelledBookings,
          SectionIds.bookingPayment,
          SectionIds.securityDeposits,
          SectionIds.bookingGuestIdentityVerifications,
          SectionIds.bookingRentalAgreements,
        ],
      },
      {
        title: intl.t('dashboard_index.table.cells.security_deposit_amount'),
        property: OccasionAttribute.securityDepositAmount,
        class: 'width-150',
        availableTables: [SectionIds.securityDeposits],
      },
      {
        title: intl.t('dashboard_index.table.cells.security_deposit_due_date'),
        property: OccasionAttribute.securityDepositDueDate,
        class: 'width-150',
        availableTables: [SectionIds.securityDeposits],
      },
      {
        title: intl.t('dashboard_index.table.cells.security_deposit_status'),
        property: OccasionAttribute.securityDepositStatus,
        class: 'width-150',
        availableTables: [SectionIds.securityDeposits],
      },
      {
        title: intl.t(
          'dashboard_index.table.cells.guest_identity_verification_status',
        ),
        property: OccasionAttribute.guestIdentityVerificationStatus,
        class: 'width-150',
        availableTables: [
          SectionIds.checkIn,
          SectionIds.checkOut,
          SectionIds.newBookings,
          SectionIds.bookingPayment,
          SectionIds.securityDeposits,
          SectionIds.bookingGuestIdentityVerifications,
          SectionIds.bookingRentalAgreements,
        ],
      },
      {
        title: intl.t('dashboard_index.table.cells.rental_agreement_status'),
        property: OccasionAttribute.rentalAgreementStatus,
        class: 'width-150',
        availableTables: [
          SectionIds.checkIn,
          SectionIds.checkOut,
          SectionIds.newBookings,
          SectionIds.bookingPayment,
          SectionIds.securityDeposits,
          SectionIds.bookingGuestIdentityVerifications,
          SectionIds.bookingRentalAgreements,
        ],
      },

      // new
      {
        title: intl.t('dashboard_index.table.cells.id'),
        property: OccasionAttribute.id,
        class: 'width-110',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.property_id'),
        property: OccasionAttribute.propertyId,
        class: 'width-110',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.currency'),
        property: OccasionAttribute.currency,
        class: 'width-110',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.source'),
        property: OccasionAttribute.source,
        class: 'width-120',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.guest_email'),
        property: OccasionAttribute.guestEmail,
        class: 'width-200',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.guest_phone'),
        property: OccasionAttribute.guestPhone,
        class: 'width-120',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.note'),
        property: OccasionAttribute.note,
        class: 'width-140',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.confirmation_code'),
        property: OccasionAttribute.confirmationCode,
        class: 'width-130',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.accommodation_total'),
        property: OccasionAttribute.accommodationTotal,
        class: 'width-110',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.cleaning_fee'),
        property: OccasionAttribute.cleaningFee,
        class: 'width-110',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.extra_guest_charges'),
        property: OccasionAttribute.extraGuestCharges,
        class: 'width-110',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.extra_charges'),
        property: OccasionAttribute.extraCharges,
        class: 'width-110',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.discounts'),
        property: OccasionAttribute.discounts,
        class: 'width-110',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.taxes'),
        property: OccasionAttribute.taxes,
        class: 'width-110',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.payment_processing_fee'),
        property: OccasionAttribute.paymentProcessingFee,
        class: 'width-110',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.commission'),
        property: OccasionAttribute.commission,
        class: 'width-110',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.commission_tax'),
        property: OccasionAttribute.commissionTax,
        class: 'width-110',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.cancellation_fee'),
        property: OccasionAttribute.cancellationFee,
        class: 'width-110',
        availableTables: [],
      },
      {
        title: intl.t(
          'dashboard_index.table.cells.accommodation_management_fee',
        ),
        property: OccasionAttribute.accommodationManagementFee,
        class: 'width-110',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.cleaning_management_fee'),
        property: OccasionAttribute.cleaningManagementFee,
        class: 'width-110',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.total_management_fee'),
        property: OccasionAttribute.totalManagementFee,
        class: 'width-110',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.gross_revenue'),
        property: OccasionAttribute.grossRevenue,
        class: 'width-110',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.net_revenue'),
        property: OccasionAttribute.netRevenue,
        class: 'width-110',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.balance'),
        property: OccasionAttribute.balance,
        class: 'width-110',
        availableTables: [],
      },
      {
        title: intl.t('dashboard_index.table.cells.upsells_total'),
        property: OccasionAttribute.upsellsTotal,
        class: 'width-110',
        availableTables: [],
      },
    ];
  }

  public createGroupFromFilters(filters: OccasionFilters): OccasionFilterGroup {
    const isSingleGroup =
      filters.length === 1 && !(filters[0] as IOccasionFilter).field;

    if (isSingleGroup) {
      const group = filters[0] as IOccasionFilterGroup;

      return this.createFilterGroup(group.values, group.type);
    }

    return this.createFilterGroup(filters);
  }

  public createFilterGroup(
    filters: OccasionFilters,
    type: OccasionFilterType = OccasionFilterType.and,
  ): OccasionFilterGroup {
    return new OccasionFilterGroup({
      type,
      values: filters.map((item) => {
        if ((item as IOccasionFilter).field) {
          return this.createFilterFor(item as IOccasionFilter);
        }

        return this.createFilterGroup(
          item.values as IOccasionFilter[],
          item.type,
        );
      }),
    });
  }

  public createFilterFor(
    filterId: TableSectionFields | IOccasionFilter,
  ): OccasionFilter {
    const filter =
      typeof filterId === 'string'
        ? this.getFilterFor(filterId)
        : this.getFilterFor(
            filterId.field,
            filterId.values as string[],
            filterId.type,
          );

    this.attachServicesToFilter(filter);

    return filter;
  }

  public clone(filter: OccasionFilter): OccasionFilter {
    const newFilter = filter.clone();

    this.attachServicesToFilter(newFilter);

    return newFilter;
  }

  private attachServicesToFilter(filter: OccasionFilter): void {
    filter.intl = this.intl;
    filter.store = this.store;
  }

  private getFilterFor(
    id: TableSectionFields,
    values?: string[],
    type?: OccasionFilterType,
  ): OccasionFilter {
    switch (id) {
      case TableSectionFields.bookingStatus:
        return new BookingStatusFilter(values, type);
      case TableSectionFields.depositStatus:
        return new BookingSecurityDepositStatusFilter(values, type);
      case TableSectionFields.bookingRentalAgreementStatus:
        return new BookingRentalAgreementStatusFilter(values, type);
      case TableSectionFields.bookingGuestIdentityVerificationStatus:
        return new BookingGuestIdentityVerificationStatusFilter(values, type);
      case TableSectionFields.paid:
        return new BookingPaymentStatusFilter(values, type);
      case TableSectionFields.channel:
        return new BookingChannelFilter(values, type);
      case TableSectionFields.propertyId:
        return new BookingPropertyIdFilter(values, type);
      case TableSectionFields.propertyTagId:
        return new BookingPropertyTagIdFilter(values, type);
      case TableSectionFields.multiUnitId:
        return new BookingMultiUnitFilter(values, type);
      case TableSectionFields.duration:
        return new BookingDurationFilter(values, type);
      case TableSectionFields.newBookings:
        return new BookingCreationDateFilter(values, type);
      case TableSectionFields.cancelledBookings:
        return new BookingCancellationDateFilter(values, type);
      case TableSectionFields.checkIn:
        return new BookingCheckInDateFilter(values, type);
      case TableSectionFields.checkOut:
        return new BookingCheckOutDateFilter(values, type);
      case TableSectionFields.stays:
        return new BookingStaysFilter(values, type);
      default:
        throw new Error(`unknown occasion filter id - ${id}`);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    occasions: OccasionsService;
  }
}
