import {
  validateNumber,
  validatePresence,
} from 'ember-changeset-validations/validators';

export const OnboardValidation = {
  numberOfProperties: validateNumber({
    gte: 0,
    positive: true,
    allowBlank: false,
    integer: true,
  }),
  countryCallingCode: validateNumber({
    gte: 0,
    positive: true,
    allowBlank: false,
    integer: true,
  }),
  phoneNumber: validateNumber({
    gte: 0,
    integer: true,
    allowBlank: false,
    positive: true,
  }),
  country: validatePresence({
    presence: true,
    ignoreBlank: true,
  }),
};
