import type Transition from '@ember/routing/transition';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';
import ClientsClientAccessController from 'uplisting-frontend/pods/clients/client/access/controller';

export default class ClientsClientAccessRoute extends ProtectedRoute {
  model() {
    return this.modelFor('clients.client');
  }

  setupController(
    controller: ClientsClientAccessController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, { client: model });
  }
}
