import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"validated-input\" data-test-validated-input-wrapper>\n  <label class=\"form-label\" for={{this.id}}>{{@label}}</label>\n\n  <input\n    id={{this.id}}\n    type=\"text\"\n    autocomplete=\"off\"\n    spellcheck=\"false\"\n    value={{this.value}}\n    class=\"form-control {{if this.error \"is-invalid\"}}\"\n    {{on \"input\" this.handleInput}}\n    data-test-validated-input\n    ...attributes\n  >\n\n  {{#if this.error}}\n    <div class=\"invalid-feedback d-block\" data-test-validated-input-error>\n      {{this.errorMessage}}\n    </div>\n  {{/if}}\n</div>\n", {"contents":"<div class=\"validated-input\" data-test-validated-input-wrapper>\n  <label class=\"form-label\" for={{this.id}}>{{@label}}</label>\n\n  <input\n    id={{this.id}}\n    type=\"text\"\n    autocomplete=\"off\"\n    spellcheck=\"false\"\n    value={{this.value}}\n    class=\"form-control {{if this.error \"is-invalid\"}}\"\n    {{on \"input\" this.handleInput}}\n    data-test-validated-input\n    ...attributes\n  >\n\n  {{#if this.error}}\n    <div class=\"invalid-feedback d-block\" data-test-validated-input-error>\n      {{this.errorMessage}}\n    </div>\n  {{/if}}\n</div>\n","moduleName":"uplisting-frontend/components/ui/validated-input/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/validated-input/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { BufferedChangeset } from 'validated-changeset';
import { type IErr } from 'validated-changeset/dist/types';

interface IArgs {
  key: string;
  label: string;
  changeset: BufferedChangeset;
  onChange(value: string): void;
}

interface ValidatedInputSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiValidatedInputComponent extends Component<ValidatedInputSignature> {
  @cached
  get id(): string {
    return guidFor(this);
  }

  @cached
  get key(): string {
    return this.args.key;
  }

  @cached
  get changeset(): BufferedChangeset {
    return this.args.changeset;
  }

  @cached
  get value(): string {
    return this.changeset[this.key] as string;
  }

  @cached
  get error(): IErr<string> {
    return this.changeset.error[this.key] as IErr<string>;
  }

  @cached
  get errorMessage(): string {
    return this.error.validation as string;
  }

  @action
  async handleInput(event: Event): Promise<void> {
    this.args.onChange((event.target as HTMLInputElement).value);

    await this.changeset.validate();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::ValidatedInput': typeof UiValidatedInputComponent;
  }
}
