import type Transition from '@ember/routing/transition';
import config from 'ember-get-config';
import BaseRoute from 'uplisting-frontend/pods/base/route';

export default class SignupRoute extends BaseRoute {
  queryParams = {
    i: {},
  };

  async beforeModel(transition: Transition): Promise<void> {
    if (this.session.isAuthenticated) {
      await this.router.transitionTo('index');
    } else {
      const url = URL.parse(config.auth.registerUrl);
      if (!url) {
        return;
      }

      const invitationId = transition.to?.queryParams['i'];
      if (invitationId) {
        url.searchParams.append('state', invitationId);
      }

      window.open(url.href, '_self');
    }
  }
}
