import { cached, tracked } from '@glimmer/tracking';
import BaseController from 'uplisting-frontend/pods/base/controller';
import { type ILink } from 'uplisting-frontend/components/ui/nav-links';
import {
  OccasionEvent,
  OccasionPreposition,
} from 'uplisting-frontend/models/schemas';

export default class OutboundMessagesController extends BaseController {
  queryParams = ['event', 'preposition'];

  @cached @tracked event: OccasionEvent | '' = '';
  @cached @tracked preposition: OccasionPreposition | '' = '';

  @cached
  get title(): string {
    const { event, preposition, navLinks } = this;

    const activeItem = navLinks.find(
      (navLink) =>
        navLink.queryParams?.[0]?.value === event &&
        navLink.queryParams?.[1]?.value === preposition,
    ) as ILink;

    return activeItem.name;
  }

  @cached
  get navLinks(): ILink[] {
    const { intl } = this;

    const route = 'outbound.messages';
    const permitted = true;

    return [
      {
        name: intl.t('outbound_messages.nav_items.all'),
        route,
        permitted,
        queryParams: [
          {
            key: 'event',
            value: '',
          },
          {
            key: 'preposition',
            value: '',
          },
        ],
        default: true,
      },
      {
        name: intl.t('outbound_messages.nav_items.after_booking'),
        route,
        permitted,
        queryParams: [
          {
            key: 'event',
            value: OccasionEvent.booked,
          },
          {
            key: 'preposition',
            value: OccasionPreposition.after,
          },
        ],
      },
      {
        name: intl.t('outbound_messages.nav_items.before_arrival'),
        route,
        permitted,
        queryParams: [
          {
            key: 'event',
            value: OccasionEvent.arrived,
          },
          {
            key: 'preposition',
            value: OccasionPreposition.before,
          },
        ],
      },
      {
        name: intl.t('outbound_messages.nav_items.after_arrival'),
        route,
        permitted,
        queryParams: [
          {
            key: 'event',
            value: OccasionEvent.arrived,
          },
          {
            key: 'preposition',
            value: OccasionPreposition.after,
          },
        ],
      },
      {
        name: intl.t('outbound_messages.nav_items.before_departure'),
        route,
        permitted,
        queryParams: [
          {
            key: 'event',
            value: OccasionEvent.departed,
          },
          {
            key: 'preposition',
            value: OccasionPreposition.before,
          },
        ],
      },
      {
        name: intl.t('outbound_messages.nav_items.after_departure'),
        route,
        permitted,
        queryParams: [
          {
            key: 'event',
            value: OccasionEvent.departed,
          },
          {
            key: 'preposition',
            value: OccasionPreposition.after,
          },
        ],
      },
    ];
  }
}
