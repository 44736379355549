import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import BaseController from 'uplisting-frontend/pods/base/controller';
import AirbnbOfficialNotificationModel from 'uplisting-frontend/models/airbnb-official-notification';

export default class NotificationsAirbnbController extends BaseController {
  @cached
  get airbnbNotifications(): AirbnbOfficialNotificationModel[] {
    return this.store.peekAll('airbnb-official-notification').slice();
  }

  @action
  handleActionClick(link: string): void {
    window.open(link, '_blank');
  }
}
