import { type Registry as Services, service } from '@ember/service';
import { hash } from 'rsvp';
import type Transition from '@ember/routing/transition';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';
import ClientsClientPropertiesController from 'uplisting-frontend/pods/clients/client/properties/controller';

export default class ClientsClientPropertiesRoute extends ProtectedRoute {
  @service('repositories/client-property')
  clientPropertyRepository!: Services['repositories/client-property'];

  model() {
    const client = this.modelFor('clients.client');

    return hash({
      client,
      clientProperties: this.clientPropertyRepository.findAll({ reload: true }),
    });
  }

  setupController(
    controller: ClientsClientPropertiesController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, model);

    controller.orderClientProperties();
    controller.setSelectedClientProperties();
  }

  resetController(): void {
    this.clientPropertyRepository.unloadAll();
  }
}
