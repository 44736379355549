import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import sum from 'lodash-es/sum';
import { cached } from '@glimmer/tracking';
import RefundModel from 'uplisting-frontend/models/refund';
import GuestInvoiceModel from 'uplisting-frontend/models/guest-invoice';
import BookingPaymentModel from 'uplisting-frontend/models/booking-payment';
import {
  type IPaymentSchema,
  PaymentStatus,
} from 'uplisting-frontend/models/schemas';

const PAID_STATUSES = [PaymentStatus.charged, PaymentStatus.markedAsPaid];

export default class PaymentModel extends Model implements IPaymentSchema {
  @attr('date') dueAt!: Date;
  @attr('string') currency!: string;
  @attr('number') amount!: number;
  @attr('string') status!: PaymentStatus;
  @attr('string') lastErrorMessage!: string;

  @belongsTo('booking-payment', { async: false, inverse: 'payments' })
  bookingPayment!: BookingPaymentModel;

  @belongsTo('guest-invoice', { async: false, inverse: 'payment' })
  guestInvoice!: GuestInvoiceModel;

  @hasMany('refund', { async: false, inverse: null })
  refunds!: RefundModel[];

  @cached
  get isPaid(): boolean {
    return PAID_STATUSES.includes(this.status);
  }

  @cached
  get isPending(): boolean {
    return this.status === PaymentStatus.pending;
  }

  @cached
  get isRefundable(): boolean {
    if (this.status !== PaymentStatus.charged) {
      return false;
    }

    return this.amount > this.refundSum;
  }

  @cached
  get refundSum(): number {
    return sum(this.refunds.map((item) => item.amount)) || 0;
  }

  @cached
  get maxRefundAmount(): number {
    return this.amount - this.refundSum;
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    payment: PaymentModel;
  }
}
