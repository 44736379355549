import { cached, tracked } from '@glimmer/tracking';
import BaseController from 'uplisting-frontend/pods/base/controller';
import BookingModel from 'uplisting-frontend/models/booking';
import ContextualReplyModel from 'uplisting-frontend/models/contextual-reply';

export default class ActionBookingsMessagesController extends BaseController {
  queryParams = [
    {
      replyId: 'reply',
    },
  ];

  @cached @tracked replyId?: string;

  @cached @tracked booking!: BookingModel;
  @cached @tracked reply?: ContextualReplyModel;
}
