import Helper from '@ember/component/helper';
import { type Registry as Services, service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { SafeString } from 'handlebars';
import { htmlSafe } from '@ember/template';
import { priceFormat } from 'uplisting-frontend/helpers/price-format';

export default class BookingPriceDetailHelper extends Helper {
  @service intl!: Services['intl'];

  compute([price, currency, prefix = '']: [string, string, string]):
    | string
    | SafeString {
    if (isEmpty(price)) {
      return this.intl.t('words.n_a');
    }

    const formatted = priceFormat([price, currency, true]);

    return htmlSafe(`${prefix}${formatted}`);
  }
}
