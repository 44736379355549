import { helper } from '@ember/component/helper';

const CURRENCIES_WITH_SYMBOL = [
  'USD',
  'CAD',
  'AUD',
  'NZD',
  'HKD',
  'MXN',
  'SGD',
  'COP',
  'GBP',
  'EUR',
  'SEK',
  'NOK',
  'DKK',
  'YEN',
  'AED',
  'CHF',
  'ZAR',
  'INR',
  'KRW',
  'TRY',
  'PLN',
  'MYR',
  'CZK',
  'BRL',
  'ILS',
  'GEL',
];

export function hasCurrencySymbolFor([currency]: [string]): boolean {
  return CURRENCIES_WITH_SYMBOL.includes(currency);
}

export default helper(hasCurrencySymbolFor);
