import Service, { type Registry as Services, service } from '@ember/service';
import config from 'ember-get-config';
import { cached } from '@glimmer/tracking';
import UserModel from 'uplisting-frontend/models/user';

export default class SubscriptionService extends Service {
  @service sentry!: Services['sentry'];
  @service router!: Services['router'];
  @service session!: Services['session'];

  @cached
  get currentUser(): UserModel | undefined {
    return this.session.currentUser;
  }

  public checkPaused(): void {
    const { currentUser } = this;

    if (!currentUser) {
      return;
    }

    const { customer, isAccountOwner } = currentUser;

    if (!customer || !window.churnkey) {
      return;
    }

    const attributes = {
      customerId: customer.externalReference,
      authHash: customer.hmac,
      appId: config.churnkey.appId,
      mode: config.churnkey.mode,
      forceCheck: false,
      softWall: false,
      provider: 'stripe',
      ignoreInvoicesWithoutAttempt: true,
    };

    window.churnkey.check('pause', attributes);

    if (isAccountOwner) {
      const { sentry } = this;

      window.churnkey.check('failed-payment', {
        ...attributes,
        gracePeriodDays: 5,
        onError(error, type) {
          if (type === 'FAILED_PAYMENT_WALL_UPDATE_CARD_ERROR') {
            sentry.captureMessage(`${type}: ${error}`);
          }
        },
      });
    }
  }

  public checkLapsed(): void {
    if (this.currentUser?.lapsed) {
      this.router.replaceWith('account.lapsed');
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    subscription: SubscriptionService;
  }
}
