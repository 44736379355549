import * as Sentry from '@sentry/ember';
import config from 'ember-get-config';
import type { ErrorEvent, EventHint } from '@sentry/ember';

export function startSentry(): void {
  Sentry.init({
    dsn: config.IS_PROD ? config.sentry.dsn : undefined,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
      Sentry.browserTracingIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.01, // Capture 1% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/app\.staging-uplisting\.io/,
      /^https:\/\/app\.uplisting\.io/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    environment: config.environment,
    release: config.sentry.release,
    allowUrls: [
      config.assetsHost,
      /^https:\/\/app\.staging-uplisting\.io/,
      /^https:\/\/app\.uplisting\.io/,
    ],

    denyUrls: [
      /https?:\/\/((cdn|www)\.)?segment\.com/,
      /https?:\/\/((cdn|www)\.)?google\.com/,
      /https?:\/\/((cdn|maps)\.)?googleapis\.com/,
    ],

    beforeSend(event: ErrorEvent, hint: EventHint) {
      const error = hint.originalException;

      const ignoreErrors = [
        /NetworkError when attempting to fetch resource/,
        /Failed to fetch/,
        /Load failed/,
        /Refused to evaluate/,
        /Refused to load/,
        /Failed to load resource/,
        /Non-Error exception captured/,
        /Non-Error promise rejection captured/,
        /Object captured as promise rejection with key/,
      ];

      // Ignoring these errors due to https://github.com/emberjs/ember.js/issues/12505
      // and https://github.com/emberjs/ember.js/issues/18416
      // @ts-expect-error - ignoring unknown TS typings
      const isTransitionAbort = error?.name === 'TransitionAborted';

      // @ts-expect-error - ignoring unknown TS typings
      const isIgnorable = ignoreErrors.some((re) => re.test(error?.message));

      if (isTransitionAbort || isIgnorable) {
        return null;
      }

      return event;
    },
  });
}
