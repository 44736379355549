import Service, { type Registry as Services, service } from '@ember/service';
import {
  type Filter,
  ClientNameFilter,
  PropertyFilter,
  ClientStatementStatusFilter,
  MonthFilter,
  UpsellOrderStatusFilter,
} from 'uplisting-frontend/utils/filters/types';
import { FilterId } from 'uplisting-frontend/utils/filters/abstract';

export default class FiltersService extends Service {
  @service store!: Services['store'];

  public createFilterFor(id: FilterId, value?: string | string[]): Filter {
    const filter = this.createFilterById(id, value);

    this.attachStore(filter);

    return filter;
  }

  private createFilterById(id: FilterId, value?: string | string[]): Filter {
    switch (id) {
      case FilterId.clientName:
        return new ClientNameFilter(value as string[]);
      case FilterId.property:
        return new PropertyFilter(value as string[]);
      case FilterId.clientStatementStatus:
        return new ClientStatementStatusFilter(value as string);
      case FilterId.period:
        return new MonthFilter(value as string);
      case FilterId.upsellOrderStatus:
        return new UpsellOrderStatusFilter(value as string);
      default:
        throw new Error(
          `unknown filter id, expected to be 'FilterId', got ${id}`,
        );
    }
  }

  private attachStore(filter: Filter): void {
    filter.store = this.store;
  }
}

declare module '@ember/service' {
  interface Registry {
    filters: FiltersService;
  }
}
