import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label\n  ...attributes\n  local-class=\"form-checkbox {{if this.isDisabled \"disabled\"}} {{if this.isLabeledCheckbox \"labeled-checkbox\"}} {{this.position}}\"\n  class=\"up-checkbox {{if @text \"text-truncate\"}} {{if @isChecked \"selected\"}}\"\n  {{on \"click\" this.handleClick}}\n  data-test-checkbox\n>\n  {{#if @onLabel}}\n    <span>{{@onLabel}}</span>\n  {{/if}}\n\n  <input local-class=\"form-checkbox__input\" type={{this.type}} checked={{@isChecked}} disabled={{this.isDisabled}} data-test-input>\n  <span local-class=\"form-checkbox__btn\"></span>\n\n  {{#if @offLabel}}\n    <span>{{@offLabel}}</span>\n  {{/if}}\n\n  {{#if @text}}\n    <span local-class=\"form-checkbox__value\" data-test-text>\n      {{html-safe @text}}\n    </span>\n  {{/if}}\n</label>\n", {"contents":"<label\n  ...attributes\n  local-class=\"form-checkbox {{if this.isDisabled \"disabled\"}} {{if this.isLabeledCheckbox \"labeled-checkbox\"}} {{this.position}}\"\n  class=\"up-checkbox {{if @text \"text-truncate\"}} {{if @isChecked \"selected\"}}\"\n  {{on \"click\" this.handleClick}}\n  data-test-checkbox\n>\n  {{#if @onLabel}}\n    <span>{{@onLabel}}</span>\n  {{/if}}\n\n  <input local-class=\"form-checkbox__input\" type={{this.type}} checked={{@isChecked}} disabled={{this.isDisabled}} data-test-input>\n  <span local-class=\"form-checkbox__btn\"></span>\n\n  {{#if @offLabel}}\n    <span>{{@offLabel}}</span>\n  {{/if}}\n\n  {{#if @text}}\n    <span local-class=\"form-checkbox__value\" data-test-text>\n      {{html-safe @text}}\n    </span>\n  {{/if}}\n</label>\n","moduleName":"uplisting-frontend/components/ui/checkbox/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/checkbox/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';

type CheckboxType = 'checkbox' | 'radio';

interface IArgs {
  isChecked: boolean;
  text?: string;
  type?: CheckboxType;
  isDisabled?: boolean;
  position?: string;
  onLabel?: string;
  offLabel?: string;
  onClick?(): void;
}

interface CheckBoxSignature {
  Element: HTMLLabelElement;

  Args: IArgs;
}

export default class UiCheckboxComponent extends Component<CheckBoxSignature> {
  @cached
  get isDisabled(): boolean {
    return this.args.isDisabled ?? false;
  }

  @cached
  get type(): CheckboxType {
    return this.args.type ?? 'checkbox';
  }

  @cached
  get position(): string {
    return `position-${this.args.position ?? 'left'}`;
  }

  @cached
  get isLabeledCheckbox(): boolean {
    return !!(this.args.onLabel && this.args.offLabel);
  }

  @action
  handleClick(event: MouseEvent): void {
    if (this.args.onClick) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (this.isDisabled) {
      return;
    }

    this.args.onClick?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Checkbox': typeof UiCheckboxComponent;
  }
}
