import ClientModel from 'uplisting-frontend/models/client';
import PropertyModel from 'uplisting-frontend/models/property';
import MultiUnitModel from 'uplisting-frontend/models/multi-unit';
import { UserRoles } from 'uplisting-frontend/models/schemas';

export type InvitationRole = Exclude<UserRoles, UserRoles.accountOwner>;
export enum InvitationStatus {
  accepted = 'accepted',
  pending = 'pending',
}

export interface IInvitationSchema {
  email: string;
  role: InvitationRole;
  status: InvitationStatus;
  createdAt: Date;

  client?: ClientModel;

  properties: PropertyModel[];
  multiUnits: MultiUnitModel[];
}
