import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<td data-scrollable-table-cell data-test-table-cell ...attributes>\n  <span>\n    {{#let (i18n this.field this.value) as |value|}}\n      {{#if this.isBadgeField}}\n        <span class=\"uf-badge {{this.statusBadge}}\" data-test-badge>\n          {{value}}\n        </span>\n      {{else if this.isLinkField}}\n        <a target=\"_blank\" rel=\"noopener noreferrer\" href={{this.occasion.bookingLink}} data-test-link>\n          {{value}}\n        </a>\n      {{else if this.isDateField}}\n        {{date-format value this.dateFormat}}\n      {{else if this.isTimeField}}\n        {{time-format value}}\n      {{else if this.isCurrencyField}}\n        {{price-format value this.occasion.bookingCurrency}}\n      {{else if (eq this.field \"id\")}}\n        {{this.idFormatted}}\n      {{else}}\n        {{value}}\n      {{/if}}\n    {{/let}}\n  </span>\n</td>\n", {"contents":"<td data-scrollable-table-cell data-test-table-cell ...attributes>\n  <span>\n    {{#let (i18n this.field this.value) as |value|}}\n      {{#if this.isBadgeField}}\n        <span class=\"uf-badge {{this.statusBadge}}\" data-test-badge>\n          {{value}}\n        </span>\n      {{else if this.isLinkField}}\n        <a target=\"_blank\" rel=\"noopener noreferrer\" href={{this.occasion.bookingLink}} data-test-link>\n          {{value}}\n        </a>\n      {{else if this.isDateField}}\n        {{date-format value this.dateFormat}}\n      {{else if this.isTimeField}}\n        {{time-format value}}\n      {{else if this.isCurrencyField}}\n        {{price-format value this.occasion.bookingCurrency}}\n      {{else if (eq this.field \"id\")}}\n        {{this.idFormatted}}\n      {{else}}\n        {{value}}\n      {{/if}}\n    {{/let}}\n  </span>\n</td>\n","moduleName":"uplisting-frontend/components/ui/dashboard/table-cell/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/dashboard/table-cell/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { isNone } from '@ember/utils';
import OccasionModel, {
  OccasionAttribute,
} from 'uplisting-frontend/models/occasion';
import {
  BookingRentalAgreementStatus,
  GuestIdentityVerificationStatus,
  BookingStatus,
  OccasionPaymentStatus,
  SecurityDepositStatus,
} from 'uplisting-frontend/models/schemas';

interface IArgs {
  field: OccasionAttribute;
  occasion: OccasionModel;
  showYear?: boolean;
}

interface DashboardTableCellSignature {
  Element: HTMLTableCellElement;

  Args: IArgs;
}
const badgeFields = [
  'status',
  'paymentStatus',
  'guestIdentityVerificationStatus',
  'rentalAgreementStatus',
  'securityDepositStatus',
];

const linkFields = ['guestName'];

const dateFields = [
  'checkIn',
  'checkOut',
  'creationDate',
  'cancellationDate',
  'paymentDueDate',
  'securityDepositDueDate',
];

const timeFields = ['arrivalTime', 'departureTime'];

const currencyFields = [
  'totalPayout',
  'outstandingAmount',
  'securityDepositAmount',
  'accommodationTotal',
  'cleaningFee',
  'extraGuestCharges',
  'extraCharges',
  'discounts',
  'taxes',
  'paymentProcessingFee',
  'commission',
  'commissionTax',
  'cancellationFee',
  'accommodationManagementFee',
  'cleaningManagementFee',
  'totalManagementFee',
  'grossRevenue',
  'netRevenue',
  'balance',
  'upsellsTotal',
];

/**
 * @description table cell component. does formatting for the date, time, badge and link fields
 */
export default class UiDashboardTableCellComponent extends Component<DashboardTableCellSignature> {
  @cached
  get field(): string {
    return this.args.field;
  }

  @cached
  get value(): unknown {
    return this.occasion[this.field];
  }

  @cached
  get occasion(): OccasionModel {
    return this.args.occasion;
  }

  @cached
  get statusBadge(): string {
    switch (this.value) {
      case BookingStatus.needsCheckOut:
      case BookingStatus.needsCheckIn:
      case BookingStatus.cancelled:
      case BookingStatus.pending:
      case BookingStatus.timedout:
      case BookingStatus.externallyRemoved:
      case BookingStatus.expired:
      case BookingStatus.notPossible:
      case BookingStatus.declined:
      case BookingStatus.active:
      case OccasionPaymentStatus.pending:
      case OccasionPaymentStatus.failing:
      case SecurityDepositStatus.failing:
      case SecurityDepositStatus.needsPaymentInformation:
      case GuestIdentityVerificationStatus.processing:
      case BookingRentalAgreementStatus.pending:
      case BookingRentalAgreementStatus.processing:
        return 'badge-warning';

      case BookingStatus.denied:
      case BookingStatus.rejected:
      case OccasionPaymentStatus.notPaid:
      case OccasionPaymentStatus.failed:
      case SecurityDepositStatus.failed:
      case GuestIdentityVerificationStatus.requiresAction:
      case GuestIdentityVerificationStatus.requiresInput:
        return 'badge-error';
      default:
        return 'badge-success';
    }
  }

  @cached
  get idFormatted(): string {
    return (this.value as string).replace('booking-', '');
  }

  @cached
  get isValuePresent(): boolean {
    return !isNone(this.value);
  }

  @cached
  get isBadgeField(): boolean {
    return badgeFields.includes(this.field) && this.isValuePresent;
  }

  @cached
  get isLinkField(): boolean {
    return linkFields.includes(this.field) && this.isValuePresent;
  }

  @cached
  get isDateField(): boolean {
    return dateFields.includes(this.field) && this.isValuePresent;
  }

  @cached
  get isTimeField(): boolean {
    return timeFields.includes(this.field) && this.isValuePresent;
  }

  @cached
  get isCurrencyField(): boolean {
    return currencyFields.includes(this.field) && this.isValuePresent;
  }

  @cached
  get dateFormat(): string | undefined {
    if (!this.args.showYear) {
      return;
    }

    return 'EEE, MMM do yyyy';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Dashboard::TableCell': typeof UiDashboardTableCellComponent;
  }
}
