import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import sortBy from 'lodash-es/sortBy';
import { type Registry as Services, service } from '@ember/service';
import BaseController from 'uplisting-frontend/pods/base/controller';
import ClientModel from 'uplisting-frontend/models/client';
import { searchOptions } from 'uplisting-frontend/utils';
import { ClientStatus } from 'uplisting-frontend/models/schemas';
import { type GenericChangeset } from 'uplisting-frontend/services/repositories/base';

export default class ClientsIndexController extends BaseController {
  @service('repositories/client')
  clientRepository!: Services['repositories/client'];

  @service('repositories/client-property')
  clientPropertyRepository!: Services['repositories/client-property'];

  @cached @tracked inputValue = '';
  @cached @tracked showCreateClientModal = false;
  @cached @tracked showArchiveClientModal = false;
  @cached @tracked clients: ClientModel[] = [];
  @cached @tracked clientToArchive?: ClientModel;
  @cached @tracked clientChangeset!: GenericChangeset<ClientModel>;

  @cached
  get existingClients(): ClientModel[] {
    return this.clients.filter((record) => record.id);
  }

  @cached
  get activeClientsSorted(): ClientModel[] {
    return this.filterClientsBy('isActive');
  }

  @cached
  get inactiveClientsSorted(): ClientModel[] {
    return this.filterClientsBy('isArchived');
  }

  @cached
  get clientsSorted(): ClientModel[] {
    return [...this.activeClientsSorted, ...this.inactiveClientsSorted];
  }

  @cached
  get filteredItems(): ClientModel[] {
    return searchOptions<ClientModel>(
      this.clientsSorted,
      this.inputValue,
      'name',
    );
  }

  @action
  handleInputSearch(value: string): void {
    this.inputValue = value;
  }

  @action
  async handleOpenClient(client: ClientModel): Promise<void> {
    await this.router.transitionTo('clients.client.profile', client.id);
  }

  @action
  async handleAddNewClient(): Promise<void> {
    this.clientChangeset = this.clientRepository.buildChangeset();
    this.showCreateClientModal = true;

    await this.clientPropertyRepository.findAll({ reload: true });
  }

  @action
  handleCloseCreateClientModal(): void {
    this.showCreateClientModal = false;
  }

  @action
  handleShowArchiveClientModal(client: ClientModel): void {
    this.showArchiveClientModal = true;

    this.clientToArchive = client;
  }

  @action
  handleCloseArchiveClientModal(): void {
    this.showArchiveClientModal = false;
  }

  @action
  async handleClientArchive(): Promise<void> {
    try {
      const client = this.clientToArchive as ClientModel;

      client.status = ClientStatus.archived;

      await client.save();

      this.clientToArchive = undefined;

      this.notifications.info();
    } catch {
      this.notifications.error();
    }
  }

  private filterClientsBy(field: keyof ClientModel): ClientModel[] {
    return sortBy(
      this.existingClients.filter((record) => record[field]),
      'name',
    );
  }
}
