import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isUpgradeNeeded}}\n  <div class=\"alert alert-info col-md-12 d-flex\">\n    <div class=\"col-md-1\">\n      <div class=\"alert-icon icon-upgrade bg-green\"></div>\n    </div>\n\n    <div class=\"col-md-11\">\n      <h4 class=\"text-bold mb-2\">{{@title}}</h4>\n\n      <p class=\"mb-2\">{{@description}}</p>\n\n      <Ui::InlineAlert\n        class=\"mb-2\"\n        @type=\"success\"\n        @description={{t \"automate_identity.upgrade_subscription_alert.alert\"}}\n      />\n\n      <BsButton\n        @type=\"primary\"\n        @onClick={{this.pricing.handleUpgradePlan}}\n        class=\"mt-1 btn-sm\"\n        disabled={{this.pricing.isUpgradingPlan}}\n        data-test-upgrade-subscription\n      >\n        {{@buttonText}}\n      </BsButton>\n    </div>\n  </div>\n{{else}}\n  {{yield}}\n{{/if}}\n", {"contents":"{{#if this.isUpgradeNeeded}}\n  <div class=\"alert alert-info col-md-12 d-flex\">\n    <div class=\"col-md-1\">\n      <div class=\"alert-icon icon-upgrade bg-green\"></div>\n    </div>\n\n    <div class=\"col-md-11\">\n      <h4 class=\"text-bold mb-2\">{{@title}}</h4>\n\n      <p class=\"mb-2\">{{@description}}</p>\n\n      <Ui::InlineAlert\n        class=\"mb-2\"\n        @type=\"success\"\n        @description={{t \"automate_identity.upgrade_subscription_alert.alert\"}}\n      />\n\n      <BsButton\n        @type=\"primary\"\n        @onClick={{this.pricing.handleUpgradePlan}}\n        class=\"mt-1 btn-sm\"\n        disabled={{this.pricing.isUpgradingPlan}}\n        data-test-upgrade-subscription\n      >\n        {{@buttonText}}\n      </BsButton>\n    </div>\n  </div>\n{{else}}\n  {{yield}}\n{{/if}}\n","moduleName":"uplisting-frontend/components/ui/upgrade-subscription/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/upgrade-subscription/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, service } from '@ember/service';

interface IArgs {
  title: string;
  description: string;
  buttonText: string;
}

interface UpgradeSubscriptionSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    default: [];
  };
}

export default class UiUpgradeSubscriptionComponent extends Component<UpgradeSubscriptionSignature> {
  @service pricing!: Services['pricing'];

  @cached
  get isUpgradeNeeded(): boolean {
    return (
      this.pricing.customer?.isLegacyPlan || !!this.pricing.customer?.isSaving
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::UpgradeSubscription': typeof UiUpgradeSubscriptionComponent;
  }
}
