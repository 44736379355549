import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import BookingRefundActionModel from 'uplisting-frontend/models/booking-refund-action';
import { BookingRefundActionValidation } from 'uplisting-frontend/validations';

export default class BookingRefundActionRepositoryService extends BaseRepositoryService<BookingRefundActionModel> {
  recordName = 'booking-refund-action';
  implementMethods = ['createRecord', 'buildChangeset'];

  validation = BookingRefundActionValidation;
}

declare module '@ember/service' {
  interface Registry {
    'repositories/booking-refund-action': BookingRefundActionRepositoryService;
  }
}
