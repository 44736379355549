export default {
  "menu-wrapper": "_menu-wrapper_pkfwdc",
  "sections-wrapper": "_sections-wrapper_pkfwdc",
  "section": "_section_pkfwdc",
  "section-header": "_section-header_pkfwdc",
  "section-body": "_section-body_pkfwdc",
  "search-input": "_search-input_pkfwdc",
  "disabled": "_disabled_pkfwdc",
  "footer": "_footer_pkfwdc"
};
