import Service, { type Registry as Services, service } from '@ember/service';
import { timeout, restartableTask } from 'ember-concurrency';
import config from 'ember-get-config';

export default class NewVersionNotifierService extends Service {
  @service notifications!: Services['notifications'];

  UPDATE_INTERVAL = 30_000;
  currentVersion?: number;

  isUserNotified = false;

  public listenForNewRelease = restartableTask(async () => {
    if (this.isUserNotified || !config.IS_PROD) {
      return;
    }

    try {
      const response = await fetch(`/${config.newVersionNotifier.fileName}`, {
        cache: 'no-cache',
      });

      if (!response.ok) {
        return;
      }

      const text = await response.text();
      const newVersion = Number(text.trim());

      if (!this.currentVersion) {
        this.currentVersion = newVersion;
      }

      if (this.currentVersion !== newVersion) {
        this.notifications.info(
          'new_version_notifier.message',
          {},
          {
            closeAfter: null,
          },
        );

        this.isUserNotified = true;
      }
    } finally {
      await timeout(this.UPDATE_INTERVAL);

      await this.listenForNewRelease.perform();
    }
  });
}

declare module '@ember/service' {
  interface Registry {
    'new-version-notifier': NewVersionNotifierService;
  }
}
