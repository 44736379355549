import { type Registry as Services, service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import BaseController from 'uplisting-frontend/pods/base/controller';
import { type ILink } from 'uplisting-frontend/components/ui/nav-links';

export enum TabOption {
  insights,
  reports,
}

export default class InsightsController extends BaseController {
  @service features!: Services['features'];

  @service('repositories/advanced-report-configuration')
  advancedReportConfigurationRepository!: Services['repositories/advanced-report-configuration'];

  tabOptions = TabOption;

  @cached @tracked activeTab!: TabOption;

  @cached
  get isReportsRoute(): boolean {
    return this.router.currentRouteName.includes('reports');
  }

  @cached
  get pageTitleKey(): string {
    return this.isReportsRoute ? 'title.reports' : 'title.insights.index';
  }

  @cached
  get titleKey(): string {
    return this.isReportsRoute ? 'route_names.reports' : 'route_names.insights';
  }

  @cached
  get descriptionKey(): string {
    return this.isReportsRoute
      ? 'route_descriptions.reports'
      : 'route_descriptions.insights';
  }

  @cached
  get navLinks(): ILink[] {
    if (this.isReportsRoute) {
      return this.advancedReportConfigurationRepository
        .peekAll()
        .filter((report) => report.id)
        .map((report) => ({
          id: report.id,
          name: report.name,
          route: 'insights.reports.show',
          permitted: true,
        }));
    }

    return [
      {
        name: this.intl.t('nav_links.insights.all'),
        route: 'insights.all',
        permitted: true,
      },
      {
        name: this.intl.t('nav_links.insights.airbnb'),
        route: 'insights.airbnb',
        permitted: this.currentUser.hasAccessTo('insights.airbnb'),
      },
      {
        name: this.intl.t('nav_links.insights.settings'),
        route: 'insights.settings',
        permitted: this.currentUser.hasAccessTo('insights.settings'),
      },
    ];
  }

  @cached
  get showButtonGroup(): boolean {
    const { currentUser } = this;

    const insightsPermitted = currentUser.hasAccessTo('insights');
    const reportsPermitted =
      currentUser.hasAccessTo('advanced_report_configurations.index') ||
      currentUser.hasAccessTo('calendar.reports');

    return insightsPermitted && reportsPermitted;
  }

  @action
  handleChangeTab(tabOption: TabOption): void {
    this.activeTab = tabOption;

    const route =
      this.activeTab === TabOption.insights
        ? 'insights.all'
        : 'insights.reports';

    if (
      route === 'insights.reports' &&
      !this.currentUser.hasAccessTo('advanced_report_configurations.index')
    ) {
      const url = this.router.urlFor('calendar.reports');

      window.open(url, '_self');

      return;
    }

    this.router.transitionTo(route);
  }
}
