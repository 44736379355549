import { type Registry as Services, service } from '@ember/service';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';

export default class DirectRoute extends PermittedRoute {
  @service('repositories/direct-booking-configuration')
  directBookingConfigurationRepository!: Services['repositories/direct-booking-configuration'];

  permission = 'direct';

  model() {
    return this.directBookingConfigurationRepository.getDefaultRecord();
  }
}
