import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.hasManagementFee}}\n  <table class=\"table-small table-striped\">\n    <tbody>\n      <tr class=\"bold\">\n        <td colspan=\"10\">{{t \"action_bookings_price.price_summary.management_fee\"}}</td>\n      </tr>\n\n      <tr>\n        <td colspan=\"8\">{{this.managementFeeText}}</td>\n        <td class=\"bold\" colspan=\"2\">{{booking-price-format this.price.managementFee this.currency}}</td>\n      </tr>\n    </tbody>\n  </table>\n{{/if}}\n", {"contents":"{{#if this.hasManagementFee}}\n  <table class=\"table-small table-striped\">\n    <tbody>\n      <tr class=\"bold\">\n        <td colspan=\"10\">{{t \"action_bookings_price.price_summary.management_fee\"}}</td>\n      </tr>\n\n      <tr>\n        <td colspan=\"8\">{{this.managementFeeText}}</td>\n        <td class=\"bold\" colspan=\"2\">{{booking-price-format this.price.managementFee this.currency}}</td>\n      </tr>\n    </tbody>\n  </table>\n{{/if}}\n","moduleName":"uplisting-frontend/components/ui/booking/price/tables/management-fee/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/booking/price/tables/management-fee/index.hbs"}});
import { cached } from '@glimmer/tracking';
import { type Registry as Services, service } from '@ember/service';
import UiBookingPriceTablesBaseComponent from 'uplisting-frontend/components/ui/booking/price/tables/base';

export default class UiBookingPriceTablesManagementFeeComponent extends UiBookingPriceTablesBaseComponent {
  @service intl!: Services['intl'];

  @cached
  get hasManagementFee(): boolean {
    const { managementFeeRule, bookingManagementFeeSetting } = this.booking;

    return !!(managementFeeRule?.id || bookingManagementFeeSetting?.id);
  }

  @cached
  get managementFeeText(): string {
    const { intl } = this;
    const { managementFeeRule } = this.booking;

    if (managementFeeRule?.name) {
      return intl.t(
        'action_bookings_price.management_fee_table.custom_formula',
        { name: managementFeeRule.name },
      );
    }

    const { bookingManagementFeeSetting } = this.booking;

    const feeParts: string[] = [];

    if (bookingManagementFeeSetting?.accommodationPercentage) {
      feeParts.push(
        intl.t(
          'action_bookings_price.management_fee_table.accommodation_total',
          { percentage: bookingManagementFeeSetting.accommodationPercentage },
        ),
      );
    }

    if (bookingManagementFeeSetting?.cleaningFeePercentage) {
      feeParts.push(
        intl.t('action_bookings_price.management_fee_table.cleaning_fee', {
          percentage: bookingManagementFeeSetting.cleaningFeePercentage,
        }),
      );
    }

    const plus = intl.t('action_bookings_price.management_fee_table.plus');

    return feeParts.join(` ${plus} `);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Price::Tables::ManagementFee': typeof UiBookingPriceTablesManagementFeeComponent;
  }
}
