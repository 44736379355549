import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<FaIcon @icon=\"magnifying-glass\" />\n\n&nbsp;\n\n{{t \"action.occasion_search.placeholder\"}}\n", {"contents":"<FaIcon @icon=\"magnifying-glass\" />\n\n&nbsp;\n\n{{t \"action.occasion_search.placeholder\"}}\n","moduleName":"uplisting-frontend/components/ui/occasion-search-bar-placeholder/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/occasion-search-bar-placeholder/index.hbs"}});
import templateOnly from '@ember/component/template-only';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface IArgs {}

interface OccasionSearchBarPlaceholderComponentSignature {
  Element: null;

  Args: IArgs;

  Blocks: {
    default: [];
  };
}

const UiOccasionSearchBarPlaceholderComponent =
  templateOnly<OccasionSearchBarPlaceholderComponentSignature>();

export default UiOccasionSearchBarPlaceholderComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::OccasionSearchBarPlaceholder': typeof UiOccasionSearchBarPlaceholderComponent;
    'ui/occasion-search-bar-placeholder': typeof UiOccasionSearchBarPlaceholderComponent;
  }
}
