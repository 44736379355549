import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"action-bookings-wrapper\">\n  <h4 class=\"mb-2\">{{t \"action_bookings_identity.title\"}}</h4>\n\n  <Ui::InlineAlert class=\"mb-3\" @type=\"success\" @description={{t \"action_bookings_identity.alert\"}} />\n\n  <div local-class=\"identity-images-wrapper\">\n    {{#each @guestIdentityImages as |image|}}\n      <Ui::Booking::Identity::Image @image={{image}} />\n    {{/each}}\n  </div>\n</div>\n", {"contents":"<div class=\"action-bookings-wrapper\">\n  <h4 class=\"mb-2\">{{t \"action_bookings_identity.title\"}}</h4>\n\n  <Ui::InlineAlert class=\"mb-3\" @type=\"success\" @description={{t \"action_bookings_identity.alert\"}} />\n\n  <div local-class=\"identity-images-wrapper\">\n    {{#each @guestIdentityImages as |image|}}\n      <Ui::Booking::Identity::Image @image={{image}} />\n    {{/each}}\n  </div>\n</div>\n","moduleName":"uplisting-frontend/components/ui/booking/identity/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/booking/identity/index.hbs"}});
import templateOnly from '@ember/component/template-only';
import GuestIdentityImageModel from 'uplisting-frontend/models/guest-identity-image';

interface IArgs {
  guestIdentityImages: GuestIdentityImageModel[];
}

export interface BookingIdentitySignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

const UiBookingIdentityComponent = templateOnly<BookingIdentitySignature>();

export default UiBookingIdentityComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::Identity': typeof UiBookingIdentityComponent;
  }
}
