import { typeOf } from '@ember/utils';

export enum StorageKey {
  DASHBOARD_CHECK_IN = 'dashboard-config-v2:check-in',
  DASHBOARD_CHECK_OUT = 'dashboard-config-v2:check-out',
  DASHBOARD_STAYS = 'dashboard-config-v2:stays',
  DASHBOARD_NEW_BOOKINGS = 'dashboard-config-v2:new-bookings',
  DASHBOARD_ENQUIRIES = 'dashboard-config-v2:enquiries',
  DASHBOARD_BOOKING_REQUESTS = 'dashboard-config-v2:booking-requests',
  DASHBOARD_PENDING = 'dashboard-config-v2:pending',
  DASHBOARD_CANCELLED = 'dashboard-config-v2:cancelled',
  DASHBOARD_BOOKING_PAYMENT = 'dashboard-config-v2:booking-payment',
  DASHBOARD_SECURITY_DEPOSITS = 'dashboard-config-v2:security-deposits',
  DASHBOARD_BOOKING_GUEST_IDENTITY_VERIFICATION = 'dashboard-config-v2:booking-guest-identity-verification',
  DASHBOARD_BOOKING_RENTAL_AGREEMENT = 'dashboard-config-v2:booking-rental-agreement',

  SMART_LOCKS_PRICING_CONFIRMATION = 'smart-locks-config:pricing-confirmed',
}

export enum StorageType {
  local = 'local',
}

interface IStorageApi {
  get<T>(key: string): T | null;
  set<T>(key: string, value: T): void;
  remove(key: string): void;
}

class StoragesHandler {
  get [StorageType.local](): IStorageApi {
    return this.withStorage(window.localStorage);
  }

  withStorage(storage: Storage): IStorageApi {
    return {
      // @ts-expect-error - need to improve typings to make it work properly
      get: this.getSync.bind(storage),
      set: this.setSync.bind(storage),
      remove: this.removeSync.bind(storage),
    };
  }

  private getSync<T>(this: Storage, key: string): T | null {
    const item = this.getItem(key);

    if (item === null) {
      return null;
    }

    try {
      return JSON.parse(item) as T;
    } catch {
      return item as unknown as T;
    }
  }

  private setSync(this: Storage, key: string, _value: unknown): void {
    let value = _value;

    if (['array', 'object'].includes(typeOf(value))) {
      value = JSON.stringify(value);
    }

    return this.setItem(key, value as string);
  }

  private removeSync(this: Storage, key: string): void {
    return this.removeItem(key);
  }
}

export const storage = new StoragesHandler();
