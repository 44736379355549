import { action } from '@ember/object';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, service } from '@ember/service';
import sortBy from 'lodash-es/sortBy';
import BaseController from 'uplisting-frontend/pods/base/controller';
import PaymentRuleModel from 'uplisting-frontend/models/payment-rule';

export interface IAutomatePaymentRuleTableColSizes {
  channelName: number;
  numberOfProperties: number;
  edit: number;
}

export default class AutomatePaymentRulesController extends BaseController {
  @service('repositories/payment-rule')
  paymentRuleRepository!: Services['repositories/payment-rule'];

  colSizes: IAutomatePaymentRuleTableColSizes = {
    channelName: 7,
    numberOfProperties: 2,
    edit: 2,
  };

  @cached
  get paymentRulesSorted(): PaymentRuleModel[] {
    return sortBy(
      this.paymentRuleRepository.peekAll(),
      (rule) => rule.default,
    ).reverse();
  }

  @cached
  get isCreateNewDisabled(): boolean {
    return this.paymentRulesSorted.some((model) => !model.isPersisted);
  }

  @action
  handleCreatePaymentRule(): void {
    this.paymentRuleRepository.createRecord();
  }
}
