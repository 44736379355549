import { hash } from 'rsvp';
import { type Registry as Services, service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import ConnectEdgestateController from 'uplisting-frontend/pods/connect/edgestate/controller';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';

export default class ConnectEdgestateRoute extends ProtectedRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/edgestate-connection')
  edgestateConnectionRepositoryService!: Services['repositories/edgestate-connection'];

  model() {
    return hash({
      properties: this.propertyRepository.findAll(),
      edgestateConnections: this.edgestateConnectionRepositoryService.findAll(),
    });
  }

  setupController(
    controller: ConnectEdgestateController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    Object.assign(controller, model);

    controller.handleCallbackRedirect();
  }
}
