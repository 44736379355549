import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  local-class=\"section-item {{if @isDisabled \"disabled\"}}\"\n  class=\"level-{{this.level}}\"\n  data-test-permission\n>\n  <p local-class=\"section-item-name\" data-test-permission-title>{{this.item.title}}</p>\n\n  <Ui::Checkbox\n    @type=\"radio\"\n    @isChecked={{this.item.permitted}}\n    @isDisabled={{or this.item.isSaving @isDisabled}}\n    @onClick={{fn @onUpdate this.item}}\n  />\n</div>\n\n{{#each this.childrenSorted as |child|}}\n  <Ui::Permissions::Row @item={{child}} @onUpdate={{@onUpdate}} @level={{inc this.level}} @isDisabled={{this.isChildDisabled}} />\n{{/each}}\n", {"contents":"<div\n  local-class=\"section-item {{if @isDisabled \"disabled\"}}\"\n  class=\"level-{{this.level}}\"\n  data-test-permission\n>\n  <p local-class=\"section-item-name\" data-test-permission-title>{{this.item.title}}</p>\n\n  <Ui::Checkbox\n    @type=\"radio\"\n    @isChecked={{this.item.permitted}}\n    @isDisabled={{or this.item.isSaving @isDisabled}}\n    @onClick={{fn @onUpdate this.item}}\n  />\n</div>\n\n{{#each this.childrenSorted as |child|}}\n  <Ui::Permissions::Row @item={{child}} @onUpdate={{@onUpdate}} @level={{inc this.level}} @isDisabled={{this.isChildDisabled}} />\n{{/each}}\n","moduleName":"uplisting-frontend/components/ui/permissions/row/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/permissions/row/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import sortBy from 'lodash-es/sortBy';
import PermissionModel from 'uplisting-frontend/models/permission';

interface IArgs {
  level?: number;
  isDisabled?: boolean;
  item: PermissionModel;
  onUpdate(item: PermissionModel): void;
}

interface PermissionsRowSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiPermissionsRowComponent extends Component<PermissionsRowSignature> {
  @cached
  get item(): PermissionModel {
    return this.args.item;
  }

  @cached
  get level(): number {
    return this.args.level ?? 0;
  }

  @cached
  get isChildDisabled(): boolean {
    return this.args.isDisabled || !this.item.permitted;
  }

  @cached
  get childrenSorted(): PermissionModel[] {
    return sortBy((this.item.children as PermissionModel[]).slice(), 'title');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Permissions::Row': typeof UiPermissionsRowComponent;
  }
}
