import BaseRepositoryService from 'uplisting-frontend/services/repositories/base';
import PromotionRedemptionModel from 'uplisting-frontend/models/promotion-redemption';
import { PromotionValidation } from 'uplisting-frontend/validations';

export default class PromotionRedemptionRepositoryService extends BaseRepositoryService<
  PromotionRedemptionModel,
  PromotionRedemptionModel[]
> {
  recordName = 'promotion-redemption';
  implementMethods = ['query', 'unloadAll'];

  validation = PromotionValidation;

  public async fetchPromotionRedemptionById(
    id: string,
  ): Promise<PromotionRedemptionModel[]> {
    const cache = this.getDataFromCache(id);

    if (cache) {
      return cache;
    }

    const redemptions = await this.query({
      promotion_id: id,
    });

    const data = redemptions.slice();

    this.cache[id] = data;

    return data;
  }
}

declare module '@ember/service' {
  interface Registry {
    'repositories/promotion-redemption': PromotionRedemptionRepositoryService;
  }
}
