import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label\n  local-class=\"search-input {{if this.showSearchIcon \"with-icon\"}} {{if this.rounded \"rounded\"}} {{if this.isFocused \"is-focused\"}} {{if this.disabled \"disabled\"}}\"\n  data-test-search-input\n  ...attributes\n>\n  {{#if this.showSearchIcon}}\n    <FaIcon @icon=\"magnifying-glass\" @size=\"sm\" />\n  {{/if}}\n\n  <input\n    placeholder={{or @placeholder (t \"inputs.search\")}}\n    type=\"text\"\n    disabled={{this.disabled}}\n    autocomplete=\"nope\"\n    spellcheck=\"false\"\n    data-test-input\n\n    value={{@value}}\n    {{on \"blur\" this.handleBlur}}\n    {{on \"focus\" this.handleFocus}}\n    {{on \"keyup\" this.handleKeyPress}}\n    {{on \"input\" this.handleChangeInput}}\n    {{this.handleInsert}}\n  >\n</label>\n", {"contents":"<label\n  local-class=\"search-input {{if this.showSearchIcon \"with-icon\"}} {{if this.rounded \"rounded\"}} {{if this.isFocused \"is-focused\"}} {{if this.disabled \"disabled\"}}\"\n  data-test-search-input\n  ...attributes\n>\n  {{#if this.showSearchIcon}}\n    <FaIcon @icon=\"magnifying-glass\" @size=\"sm\" />\n  {{/if}}\n\n  <input\n    placeholder={{or @placeholder (t \"inputs.search\")}}\n    type=\"text\"\n    disabled={{this.disabled}}\n    autocomplete=\"nope\"\n    spellcheck=\"false\"\n    data-test-input\n\n    value={{@value}}\n    {{on \"blur\" this.handleBlur}}\n    {{on \"focus\" this.handleFocus}}\n    {{on \"keyup\" this.handleKeyPress}}\n    {{on \"input\" this.handleChangeInput}}\n    {{this.handleInsert}}\n  >\n</label>\n","moduleName":"uplisting-frontend/components/ui/search-input/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/search-input/index.hbs"}});
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { modifier } from 'ember-modifier';

interface IArgs {
  value: string;
  disabled?: boolean;
  placeholder?: string;
  rounded?: boolean;
  showSearchIcon?: boolean;
  focusOnInsert?: boolean;
  onChangeInput(value: string): void;
}

interface SearchInputSignature {
  Element: HTMLLabelElement;

  Args: IArgs;
}

export default class UiSearchInputComponent extends Component<SearchInputSignature> {
  @cached @tracked isFocused = false;

  @cached
  get showSearchIcon(): boolean {
    return this.args.showSearchIcon ?? true;
  }

  @cached
  get focusOnInsert(): boolean {
    return this.args.focusOnInsert ?? true;
  }

  @cached
  get rounded(): boolean {
    return this.args.rounded ?? false;
  }

  @cached
  get disabled(): boolean {
    return this.args.disabled ?? false;
  }

  @action
  handleBlur(): void {
    this.isFocused = false;
  }

  @action
  handleFocus(): void {
    this.isFocused = true;
  }

  @action
  handleKeyPress(): void {
    console.log('handleKeyPress');
  }

  @action
  handleChangeInput(event: Event): void {
    this.args.onChangeInput((event.target as HTMLInputElement).value);
  }

  handleInsert = modifier((input: HTMLInputElement) => {
    if (this.focusOnInsert) {
      input?.focus();
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::SearchInput': typeof UiSearchInputComponent;
  }
}
