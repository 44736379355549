import { type Registry as Services, service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import config from 'ember-get-config';
import { setGlobalTranslations } from 'uplisting-frontend/utils';
import BaseRoute from 'uplisting-frontend/pods/base/route';

export default class ApplicationRoute extends BaseRoute {
  @service intl!: Services['intl'];
  @service sentry!: Services['sentry'];
  @service analytics!: Services['analytics'];
  @service subscription!: Services['subscription'];
  @service newVersionNotifier!: Services['new-version-notifier'];

  @service('repositories/account-configuration')
  accountConfigurationRepository!: Services['repositories/account-configuration'];

  async beforeModel(transition: Transition): Promise<void> {
    await super.beforeModel(transition);

    this.setSentryTransaction(transition);

    this.router.on('routeWillChange', (transition) =>
      this.setSentryTransaction(transition),
    );

    this.router.on('routeDidChange', (transition) =>
      this.setSentryTransaction(transition),
    );

    await this.loadTranslation();

    this.intl.setLocale('en-gb');

    this.intl.setOnMissingTranslation((key: string, locales: string[]) => {
      const message = `Missing translation for key: "${key}" for locales: "${locales}"`;

      if (config.IS_PROD) {
        this.sentry.captureMessage(message);
      } else {
        console.error(message);
      }

      return '';
    });

    setGlobalTranslations(this.intl);

    await this.session.setup();
  }

  async afterModel() {
    if (!this.session.isAuthenticated) {
      return;
    }

    await this.session.fetchCurrentUser();

    this.subscription.checkPaused();
    this.newVersionNotifier.listenForNewRelease.perform();

    await this.accountConfigurationRepository.findRecord('me');
  }

  private async loadTranslation(): Promise<void> {
    const response = await fetch('/translations/en-gb.json');
    const translations = await response.json();

    this.intl.addTranslations('en-gb', translations);
  }

  // https://github.com/josemarluedke/ember-cli-segment?tab=readme-ov-file#identifying-the-user
  public identifyUser() {
    this.analytics.identifyUser();
  }

  private setSentryTransaction(transition: Transition): void {
    const name = transition.to?.name;

    if (name) {
      this.sentry.getCurrentScope().setTransactionName(name);
    }
  }
}
