import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes data-test-booking-confirmable-modal={{this.showYieldedBlock}}>\n  {{#if this.showYieldedBlock}}\n    {{yield}}\n  {{else}}\n    <BsButton @onClick={{toggle-action \"showConfirmModal\" this}} @type={{this.type}} class=\"btn-sm width-full mb-2\" disabled={{@isDisabled}}>\n      {{t (concat this.translationKey \".button\")}}\n    </BsButton>\n\n    <Ui::Modal\n      @open={{this.showConfirmModal}}\n      @title={{t (concat this.translationKey \".modal.title\")}}\n      @body={{t (concat this.translationKey \".modal.body\")}}\n      @cancelButtonText={{t \"buttons.close\"}}\n      @submitButtonText={{t (concat this.translationKey \".modal.apply\")}}\n      @onCancel={{toggle-action \"showConfirmModal\" this}}\n      @onSubmit={{@onApply}}\n    />\n  {{/if}}\n</div>\n", {"contents":"<div ...attributes data-test-booking-confirmable-modal={{this.showYieldedBlock}}>\n  {{#if this.showYieldedBlock}}\n    {{yield}}\n  {{else}}\n    <BsButton @onClick={{toggle-action \"showConfirmModal\" this}} @type={{this.type}} class=\"btn-sm width-full mb-2\" disabled={{@isDisabled}}>\n      {{t (concat this.translationKey \".button\")}}\n    </BsButton>\n\n    <Ui::Modal\n      @open={{this.showConfirmModal}}\n      @title={{t (concat this.translationKey \".modal.title\")}}\n      @body={{t (concat this.translationKey \".modal.body\")}}\n      @cancelButtonText={{t \"buttons.close\"}}\n      @submitButtonText={{t (concat this.translationKey \".modal.apply\")}}\n      @onCancel={{toggle-action \"showConfirmModal\" this}}\n      @onSubmit={{@onApply}}\n    />\n  {{/if}}\n</div>\n","moduleName":"uplisting-frontend/components/ui/booking/confirmable-modal/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/booking/confirmable-modal/index.hbs"}});
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

interface IArgs {
  translationKey: string;
  showYieldedBlock?: boolean;
  type?: 'primary';
  isDisabled?: boolean;
  onApply(): void;
}

export interface BookingConfirmableModalSignature {
  Element: HTMLDivElement;

  Args: IArgs;

  Blocks: {
    default: [];
  };
}

export default class UiBookingConfirmableModalComponent extends Component<BookingConfirmableModalSignature> {
  @cached @tracked showConfirmModal = false;

  @cached
  get translationKey(): string {
    return this.args.translationKey;
  }

  @cached
  get showYieldedBlock(): boolean {
    return this.args.showYieldedBlock ?? false;
  }

  @cached
  get type(): 'secondary' | 'primary' {
    return this.args.type ?? 'secondary';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Booking::ConfirmableModal': typeof UiBookingConfirmableModalComponent;
  }
}
