import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<tr class=\"action-table-row status-missing\">\n  <td class=\"column\">{{t \"action.rows.empty.time\"}}</td>\n\n  <td class=\"column\">\n    <p class=\"status-text\"></p>\n  </td>\n\n  <td class=\"column\" colspan=\"6\">{{t \"action.rows.empty.description\"}}</td>\n</tr>\n", {"contents":"<tr class=\"action-table-row status-missing\">\n  <td class=\"column\">{{t \"action.rows.empty.time\"}}</td>\n\n  <td class=\"column\">\n    <p class=\"status-text\"></p>\n  </td>\n\n  <td class=\"column\" colspan=\"6\">{{t \"action.rows.empty.description\"}}</td>\n</tr>\n","moduleName":"uplisting-frontend/components/ui/action/table/row/empty/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/action/table/row/empty/index.hbs"}});
import templateOnly from '@ember/component/template-only';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface IArgs {}

interface ActionTableRowEmptySignature {
  Element: HTMLTableRowElement;

  Args: IArgs;
}

const UiActionTableRowEmptyComponent =
  templateOnly<ActionTableRowEmptySignature>();

export default UiActionTableRowEmptyComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Action::Table::Row::Empty': typeof UiActionTableRowEmptyComponent;
  }
}
