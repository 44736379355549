import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<td>{{this.name}}</td>\n<td>\n  <PowerSelect\n    @options={{this.lockOptions}}\n    @selected={{this.selectedOption}}\n    @onChange={{this.handleChange}}\n    @searchEnabled={{true}}\n    @searchField=\"nickname\"\n    data-test-connection-lockable-property\n  as |option|>\n    {{option.forLockable}}\n  </PowerSelect>\n</td>\n", {"contents":"<td>{{this.name}}</td>\n<td>\n  <PowerSelect\n    @options={{this.lockOptions}}\n    @selected={{this.selectedOption}}\n    @onChange={{this.handleChange}}\n    @searchEnabled={{true}}\n    @searchField=\"nickname\"\n    data-test-connection-lockable-property\n  as |option|>\n    {{option.forLockable}}\n  </PowerSelect>\n</td>\n","moduleName":"uplisting-frontend/components/ui/connections/edgestate/lockable-row/index.hbs","parseOptions":{"srcName":"uplisting-frontend/components/ui/connections/edgestate/lockable-row/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { action } from '@ember/object';
import MultiUnitModel from 'uplisting-frontend/models/multi-unit';
import EdgestateLockableModel from 'uplisting-frontend/models/edgestate-lockable';
import { type LockOption } from 'uplisting-frontend/components/ui/connections/index';

interface IArgs {
  lockable: EdgestateLockableModel;
  lockOptions: LockOption[];
  onLockPropertySelect(option: LockOption): void;
}

interface ConnectionsEdgestateLockableRowSignature {
  Element: HTMLDivElement;

  Args: IArgs;
}

export default class UiConnectionsEdgestateLockableRowComponent extends Component<ConnectionsEdgestateLockableRowSignature> {
  @cached
  get lockable(): EdgestateLockableModel {
    return this.args.lockable;
  }

  @cached
  get name(): string {
    const { lockable } = this;

    return `${lockable.name} (${lockable.resourceType})`;
  }

  @cached
  get lockOptions(): LockOption[] {
    return this.args.lockOptions;
  }

  @cached
  get selectedOption(): LockOption | undefined {
    const { lockable, lockOptions } = this;

    const selectedOption = lockOptions.find((option) => {
      if (option instanceof MultiUnitModel) {
        return option.id === lockable.multiUnit?.get('id');
      }

      return option.id === lockable.property?.get('id');
    });

    return selectedOption || lockOptions[0];
  }

  @action
  handleChange(option: LockOption): void {
    this.args.onLockPropertySelect(option);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Connections::Edgestate::LockableRow': typeof UiConnectionsEdgestateLockableRowComponent;
  }
}
