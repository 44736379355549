import { type Registry as Services, service } from '@ember/service';
import { hash } from 'rsvp';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';

export default class ClientsManagementFeeRulesIndexRoute extends ProtectedRoute {
  @service('repositories/property')
  propertyRepository!: Services['repositories/property'];

  @service('repositories/management-fee-rule')
  managementFeeRuleRepository!: Services['repositories/management-fee-rule'];

  model() {
    return hash({
      managementFeeRules: this.managementFeeRuleRepository.findAll(),
      properties: this.propertyRepository.findAll(),
    });
  }
}
